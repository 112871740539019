<template>
  <div>
      <i class="fa fa-chevron-up"   v-if="def"></i>
      <i class="fa fa-chevron-down"   v-if="!def"></i>
  </div>
</template>

<script>
export default {
   props: {
     def: {
       type: Boolean,
       default: false,
     }
   }

}
</script>