<template>
  <div>
    <Preloader v-if="loading"></Preloader>
    <div v-else>
      <div class="row">
        <div class="col-mini col-7">{{ $t('enable_strict_ip') }}</div>
        <div class="col-mini col-5">
          <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
            <input type="checkbox" v-model="data.enabled">
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <transition name="slide">
        <div class="row" v-if="data.enabled">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12">
            <small>{{ $t('write_list_ip_address_with_cidr_format') }}</small>
            <table style="width: 100%; margin-bottom: 15px; margin-top: 10px;">
              <tr v-for="(net, id) in data.networks" :key="id">
                <td style="padding-top: 5px">
                  <input class="form-control" v-model="data.networks[id]" :placeholder="$t('allowed_ip_placeholder')">
                </td>
                <td style="padding-left: 10px">
                  <button class="btn btn-block btn-sm btn-danger" style="max-width: 150px"
                          @click="data.networks.splice(id, 1)"><i class="mdi mdi-trash-can"></i></button>
                </td>
              </tr>
              <tr>
                <td style="padding-top: 5px" v-if="data.networks.length > 0"></td>
                <td :style="data.networks.length > 0 ? `padding-left: 10px` : ''">
                  <button class="btn btn-sm btn-block btn-info" @click="data.networks.push('')"><i
                      class="fa fa-plus"></i></button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </transition>
      <hr>
      <div class="row">
        <div class="col-mini col-6">
          <button @click="update" class="btn btn-info">
            <i class="mdi mdi-content-save"></i> {{ $t('save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader.vue";

export default {
  components: {Preloader},
  props: {
    userId: {
      type: [Number, String],
      default: 0,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: {
        enabled: false,
        networks: [],
      },
      loading: true,
    }
  },
  mounted() {
    if (this.settings.strict_access_by_ip) {
      this.data = this.settings.strict_access_by_ip
    }
    this.loading = false
  },
  methods: {
    async update() {
      this.loading = true
      let settings = this.settings
      settings.strict_access_by_ip = this.data
      await this.$api.put('/user/' + this.userId, {
        settings: settings,
      }).then(data => {
        this.data = data.data.settings.strict_access_by_ip
        this.loading = false
        this.$noty.success(this.$t('account_settings_saved'))
      }).catch((e) => {
      })
      this.loading = false
    },
  }
}
</script>