<template>
  <div class="row">
    <ModalForm :modal-width="700" :title="item.key"  ref="modal" @save="save">
      <template v-slot:content>
        <Preloader style="margin: 30px" v-if="loading"></Preloader>
        <div v-else>
          <div class="row" style="margin: 10px">
            <div class="col-4">{{ $t('id') }}</div>
            <div class="col-8">
              <b>{{ item.id }}</b>
            </div>
            <div class="col-4">{{ $t('name') }}</div>
            <div class="col-8">
              <b>{{ item.key }}</b><br>
              {{ item.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr style="margin: 3px">
            </div>
          </div>
          <div class="row" style="margin: 10px">
            <div class="col-4">{{ $t('enabled') }}</div>
            <div class="col-8">
              <label class="switch-small" title="Enabled"
                     style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                <input type="checkbox" :checked="item.enabled" @click="item.enabled = !item.enabled">
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <div class="row" style="margin: 10px" v-if="item.configuration">
            <div class="col-4">{{ $t('component_configuration') }}</div>
            <div class="col-8">
              <button class="btn btn-sm btn-outline-info" @click="download(JSON.stringify(item.configuration), `${item.key}.json`, 'application/json')"><i class="mdi mdi-download"></i> {{$t('load_config')}}</button>
            </div>
          </div>
          <div class="row" style="margin: 10px" >
            <div class="col-12">
              <VJsoneditor class="" v-model="item.configuration" :plus="true" :options="paramEditorOptions"></VJsoneditor>
            </div>
          </div>
        </div>
      </template>
    </ModalForm>
    <Preloader v-if="loading"></Preloader>
    <div class="col-12 col-md-12 col-mini " v-if="!loading && !error">
      <table class="table table-bordered table-sm table-striped tbl">
        <thead>
        <tr>
          <th>{{ $t('id') }}</th>
          <th>{{ $t('key') }}</th>
          <th>{{ $t('name') }}</th>
          <th>{{ $t('enabled') }}</th>
          <th>{{ $t('has_configuration') }}</th>
          <th>{{ $t('view') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.key">
          <td>{{ item.id }}</td>
          <td><b>{{ item.key }}</b></td>
          <td>{{ item.name }}</td>
          <td>
            <label class="switch-small" title="Enabled"
                   style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
              <input type="checkbox" :readonly="true" :disabled="true" :checked="item.enabled" @click="item.enabled = !item.enabled">
              <span class="slider"></span>
            </label>
          </td>
          <td>{{ item.configuration ? $t('yes') : $t('no') }}</td>
          <td>
            <button class="btn btn-sm btn-outline-info" @click="edit(item)"><i class="mdi mdi-eye"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import Preloader from "../../../components/Preloader.vue";
import ModalForm from "../../../components/ModalForm.vue";
import VJsoneditor from "v-jsoneditor";
export default {
  components: {VJsoneditor, ModalForm, Preloader},
  data() {
    return {
      loading: false,
      items: [],
      error: null,
      item: {
          id: null,
          key: '',
          configuration: null,
          enabled: null,
          name: '',
      },
      paramEditorOptions: {
        modes: ['code', 'tree'],
        mode: 'tree',
      }
    }
  },
  methods: {
    download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], {type: contentType});
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },
    async save() {
      this.loading = true
      await this.$api.put(`/system/component/${this.item.key}`, this.item).then(r => {
        setTimeout(() => {
          this.load()}, 3000)
        this.$refs.modal.hide()
      })
    },
    edit(item) {
      this.item = item
      this.$refs.modal.show()

    },
    async load() {
      this.loading = true
      await this.$api.get('/system/component')
          .then(response => {
            this.items = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      this.loading = false
    },
  },
  async mounted() {
    await this.load()
  },

}
</script>

<style>
.tbl thead tr th {
  font-weight: bold;
}
</style>