<template>
    <div class="card" :style="(zeroPadding ? 'padding: 0' : '')">
        <div v-if="!disableHide" class="card-header waves-effect waves-dark" @click="changeShowState"
             style="height: 32px; padding-top: 6px; border-bottom: 1px solid #E8E8E8">{{ name }}
            <ArrowSlide style="float: right" :def="displayBlock"/>
        </div>
        <div v-else class="card-header waves-effect waves-dark"
             style="height: 32px; padding-top: 6px; border-bottom: 1px solid #E8E8E8">{{ name }}
        </div>

        <transition name="slide">
            <div class="card-body" v-show="displayBlock" :style="zeroPadding ? 'padding: 0' : ''">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import ArrowSlide from "@/components/ArrowSlide";

export default {
    components: {ArrowSlide},
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        disableHide: {
            type: Boolean,
            default: false,
        },
        zeroPadding: {
            type: Boolean,
            default: false
        },
        saveCardState: {
            type: Boolean,
            default: false,
        },
        cardId: {
            type: String,
            default: '',
        },
    },
    mounted() {
        this.displayBlock = this.getInitializedState()
    },
    data() {
        return {
            displayBlock: false
        }
    },
    methods: {
        changeShowState() {
            this.displayBlock = !this.displayBlock
            if(this.saveCardState && this.cardId !== '') {
                this.$localCfg.setPagesCfgParameter(`card`, this.cardId, this.displayBlock)
            }
        },
        getInitializedState() {
            if (this.saveCardState && this.cardId !== '') {
                let state = this.$localCfg.getPagesCfgParameter(`card`, this.cardId)
                if (state === null) {
                    return this.show
                }
                return state
            }
            return this.show
        }
    },

}
</script>

<style>
</style>