<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini">
        <table class="table table-sm" style="margin-top: -2px">
          <thead>
          <tr>
            <th>{{ $t('bind_key') }}</th>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('description') }}</th>
            <th>{{ $t('enable_polling') }}<br>
              <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                <input type="checkbox" :checked="allEnabled" :disabled="savingInProcess" @click="updateAllInterfaces(!allEnabled)">
                <span class="slider"></span>
              </label>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="iface in interfaces" :key="iface.id">
            <td><b>{{iface.bind_key}}</b> <i>({{iface.id}})</i></td>
            <td><b>{{iface.name}}</b></td>
            <td><b>{{iface.description}}</b></td>
            <td>
              <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                <input type="checkbox" :checked="iface.poll_enabled" :disabled="savingInProcess" @click="updateInterface(iface)">
                <span class="slider"></span>
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    interfacesList: {
      type: [Array, null],
      default: null,
    }
  },
  data() {
    return {
      savingInProcess: false,
      interfaces: [],
    }
  },
  mounted() {
    this.interfaces = this.interfacesList
  },
  computed: {
    allEnabled() {
       return this.interfaces.filter(e=>e.poll_enabled).length === this.interfaces.length
    },
  },
  methods: {
    async updateInterface(iface) {
      this.savingInProcess = true
      let pollState = !iface.poll_enabled
      this.$api.put(`/device-interface/${iface.id}`, {
        poll_enabled: pollState
      }).then(() => {
        iface.poll_enabled = pollState
        this.savingInProcess = false
      }).catch(() => {
        this.savingInProcess = false
      })
    },
    async updateAllInterfaces(state) {
      this.savingInProcess = true
      this.$api.enableSupportWaiting()
      this.interfaces.forEach(iface => {
        if(iface.poll_enabled === state) {
          return
        }
        this.$api.put(`/device-interface/${iface.id}`, {
          poll_enabled: state
        }).then(() => {
          iface.poll_enabled = state
        }).catch(() => {
        })
      })
      await this.$api.waitResponses()
      this.savingInProcess = false
    },
  }
}
</script>

<style>

</style>

