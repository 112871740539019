<template>
  <div class="custom-filter" >
    <div :class="`input-group `" style="margin-bottom: 20px; ">
      <input type="text" class="form-control"
             ref="filter"
             @keyup="e=>props.search(props.opts.debounce)(e)"
             :id="`VueTables__search_${props.id}`"
             autocomplete="off"
             :placeholder="$t('search_placeholder')">
      <div class="input-group-append">
        <span class="input-group-text"><i class="fas fa-search"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['props'],
  methods: {
    focus() {
      this.$refs.filter.focus()
    },
    blur() {
      this.$refs.filter.blur()
    }
  }
}
</script>