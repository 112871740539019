<template>
  <div>
    <transition >
      <div class="background-preloader" v-show="show">
        <Preloader></Preloader>
      </div>
    </transition>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";

export default {
  components: {Preloader},
  props: {
    show: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
<style>
.background-preloader {
  z-index: 9999;
  background: rgba(248, 248, 248, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 200px;
}

</style>