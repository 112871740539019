// eslint-disable-next-line no-unused-vars
/*
 Здесь указываются необходимые модули и компоненты, которые необходимо отображать
 в зависимости от имени модели устройства
*/

export default [
    {
        types: ['ROUTER'],
        needModules: ['router_os'],
        components: {
            deviceInfo: import('./MikrotikRouterOs/DeviceInfo.vue'),
            interfaceInfo: import('./MikrotikRouterOs/DeviceInfo.vue'),
            interfacesInfo: null,
        }
    },
    {
        types: ['OLT'],
        needModules: ['olts'],
        components: {
            deviceInfo: import('./Olts/DeviceInfo.vue'),
            interfaceInfo: import('./Olts/OntInfo.vue'),
            interfacesInfo: [
                {component: import('./Olts/OntInfo.vue'), types: ['ONU']},
                {component: import('./Olts/InterfaceInfo.vue'), types: ['FE', 'GE', 'TGE', 'PON', '1G-SFP', '10G-SFP', 'ETH']},
            ],
        }
    },
    {
        types: ['SWITCH'],
        needModules: ['switches'],
        components: {
            deviceInfo: import('./Switches/DeviceInfo.vue'),
            interfaceInfo: import('./Switches/InterfaceInfo.vue'),
            interfacesInfo: null,
        }
    },
    {
        types: ['SENSOR'],
        needModules: ['sensor_devices'],
        components: {
            deviceInfo: import('./Sensors/DeviceInfo.vue'),
            interfaceInfo: import('./Sensors/DeviceInfo.vue'),
            interfacesInfo: null,
        }
    },
    {
        types: ['UNKNOWN'],
        needModules: [],
        components: {
            deviceInfo: import('./UnknownDevice/DeviceInfo.vue'),
            interfaceInfo: null,
            interfacesInfo: null,
        }
    },
]