import Chart from "chart.js";

export default {

    plugins: {
        tooltip: {
        },
    },
    interaction: {
        intersect: false,
        mode: 'index',
    },
    type: 'line',
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        display: true,
    },
    title: {
        display: false,
    },
    scales: {
        x: {
            gridLines: {
                display: true,
            },
        },
        y:
            {
                id: 'left',
                type: 'linear',
                display: true,
                position: 'left',
                gridLines: {
                    display: true,
                },
            },
    },
}
