<template>
  <div class="page-breadcrumb">
    <div class="row">
      <div class="d-none d-sm-none d-md-block d-xl-block d-lg-block col-lg-6 col-xl-6 col-md-6  align-items-center">
        <h4 class="page-title">{{title}}</h4>
      </div>
      <div class="col-12 col-mini col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex no-block align-items-center">
        <div class="ml-auto text-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" v-for="(bk, k) in breadcrumbs" :key="k"><router-link :to="{name: bk.route, params: bk.params}">{{bk.name}}</router-link></li>
              <li class="breadcrumb-item active" aria-current="page" style="font-size: 110%"><b>{{title}}</b></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
 export default {
      data() {
          return {
            breadcrumbs: [],
            title: "",
          }
      },
      watch: {
        '$route': {
          handler() {
            this.title = ''
            this.rebuildBreadCrumb()
          },
          deep: true,
        },
        '$routeMeta': {
          handler(n) {
            this.title = n.title
            this.$route.meta.title = n.title
          },
          deep: true,
        }
      },
      mounted() {
        this.title = this.$routeMeta.title === '' ? this.$route.meta.title : this.$routeMeta.title
        this.rebuildBreadCrumb()
      },
      methods: {
        rebuildBreadCrumb() {
          var routes = []
          var breadcrumbs = []
          var routerToList = function (route) {
            if(typeof route.name !== 'undefined' ) {
              routes.push({
                path: route.path,
                meta: route.meta,
                name: route.name,
              })
            }
             if(typeof route.children !== 'undefined') {
               route.children.forEach(child => {
                 routerToList(child)
               })
             }
           }
          this.$router.options.routes.forEach(route => {
            routerToList(route)
          })

          if(typeof this.$route.meta.breadcrumbs === 'undefined') {
            breadcrumbs.push({
              name: this.$t('dashboard'),
              route: 'dashboard',
              params: {},
            })
          } else {
            this.$route.meta.breadcrumbs.forEach(bread => {
               let route = routes.filter(e => {return e.name === bread}).shift()
               if(route.meta.title === '') return
               if(this.$te(route.meta.title)) {
                 route.meta.title = this.$t(route.meta.title)
               }
               breadcrumbs.push({
                 name: route.meta.title,
                 route: route.name,
                 params: route.meta.params,
               })
            })
          }
          if(this.$route.name === 'dashboard') {
            this.breadcrumbs = []
          } else {
            this.breadcrumbs = breadcrumbs
          }
        }
      }
 }
</script>