<template>
      <div class="col-12 col-sm-12 col-mini">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist"  >
          <li class="nav-item" v-for="(val, key) in tabs" :key="key">
            <a :class="'nav-link ' + (key === active_tab ? 'active' : '')" href="javascript:void(0)"
               @click="active_tab = key">
              <span class="hidden-sm-up"></span>
              <span class="hidden-xs-down">{{ $t(`sys_config.tabs.${key}`) }}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content  bg-tab-white" style="border-top: 0">
          <keep-alive>
            <component :is="tabs[active_tab]" ref="tab" style="padding: 15px"></component>
          </keep-alive>
        </div>
      </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import Card from "@/components/Card"

import SystemConfiguration from "./SysConfigTabs/SystemConfiguration.vue";
import ComponentsControl from "./SysConfigTabs/ComponentsControl.vue";
import ScheduleConfiguration from "./SysConfigTabs/ScheduleConfiguration.vue";
import SystemInfo from "@/views/Configuration/SysConfigTabs/SystemInfo.vue";

export default {
  components: {SystemInfo, ComponentsControl, SystemConfiguration, Preloader, SomethingWrong, Card,ScheduleConfiguration},
  data() {
    return {
      loading: true,
      error: '',
      active_tab: 'system_info',
      tabs: {
        'system_info': 'SystemInfo',
        'system_configuration': 'SystemConfiguration',
        'components': 'ComponentsControl',
        'schedule': 'ScheduleConfiguration',
      },
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('system_configuration'))
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
  }
}
</script>
<style>
</style>