<template>
  <div>
    <FullscreenPreloader v-if="variables === null && loadingVariables"></FullscreenPreloader>
    <div class="row">
      <div class="col-12 col-mini">
        <div class="row">
          <div class="col-12 col-mini" v-html="$t('macros.variable_block')" style="margin-bottom: 15px; "></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('onts_registration.device') }}</label>
              <v-select class="style-chooser" v-model="device" label="displayName" :options="formOptions.devices"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('onts_registration.unregisteredOnts') }}</label>
              <v-select class="style-chooser" v-model="selectedOnt"  label="_display_name" :options="unregisteredOnts"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <br>
              <button class="btn btn-info" @click="loadVariables">{{$t('select')}}</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="variables">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div class="row" v-if="variables !== null">
          <div class="col-sm-12">
            <div style="overflow-y: auto; max-height: 400px; background: #FAFAFA; border: 1px solid #DADADA">
              <json-tree :data="variables" :level="1"></json-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Parameters v-model="parameters" :variables="variables"></Parameters>
      </div>
    </div>
  </div>
</template>

<script>

import Preloader from "@/components/Preloader.vue";
import Parameters from "@/components/Macros/ParametersFormBuilder.vue";
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";

export default {
  components: {FullscreenPreloader, Parameters,  },
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    formOptions: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      variables: null,
      device: null,
      unregisteredOnts: [],
      selectedOnt: null,
      parameters: [],
      loadingVariables: false,
    }
  },
  watch: {
    parameters: {
      handler() {
        let order = 0
        this.parameters.forEach((val, key) => {
          this.parameters[key].order = order++
        })
        this.$emit('update', {
          'key': 'parameters',
          'value': this.parameters,
        })
      },
      deep: true,
    },
    selectedOnt: {
      handler() {
        this.variables = null
      },
      deep: true,
    },
    device: {
      async handler(n) {
        this.unregisteredOnts = []
        this.variables = null
        this.selectedOnt = null
        if (n !== null) {
          await this.loadUnregisteredOnts()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.parameters = JSON.parse(JSON.stringify(this.item.parameters))
    this.parameters.forEach((val, key) => {
      if(!this.parameters[key]['item_name']) {
        this.parameters[key]['item_name'] = ''
      }
      if(!this.parameters[key]['item_filter']) {
        this.parameters[key]['item_filter'] = ''
      }
    })

  },
  methods: {
    async loadUnregisteredOnts() {
      await this.$api.get( '/component/onts_registration/unregistered/'+this.device.id).then(r => {
        this.unregisteredOnts = []
        r.data.forEach(el => {
          el._display_name = `${el.interface.name} - ${el._ident}`
          this.unregisteredOnts.push(el)
        })
      }).catch(e => {
        console.log(e)
      })
    },
    async loadVariables() {
      console.log(this.selectedOnt)
      if(!this.selectedOnt) {
        this.$noty.warning(this.$t('onts_registration.please_choose_unregistered_ont'))
        return
      }
      this.loadingVariables = true
      const { data } =  await this.$api.post( '/component/onts_registration/variables', {
        device: {
          id: this.device.id
        },
        ont: this.selectedOnt._ident
      });
      this.variables = data

      this.loadingVariables = false
    },
  },
};
</script>
