<template>
  <div :class="'group' + getStyleFromStat " >
    <div class="group-header waves-effect waves-dark" >
      <div class="row">
        <div class="col-6 col-xl-7  col-lg-7 col-sm-6 col-md-6" style="font-size: 12pt" @click="$emit('change-show', !show)">
          {{ iface.name }}<br>
          <small>{{ description }}</small>
        </div>
        <div class="col-2 col-xl-1 col-lg-1 col-sm-2 col-md-2" style="font-size: 14pt; vertical-align: center; margin: auto; text-align: right;" @click="$emit('change-show', !show)">
          <div :title="$t('pon_boxes.name')">{{ stat.online }}/{{ stat.all }} <i class="mdi mdi-adjust"></i> </div>
        </div>
        <div class="col-4 col-lg-4 col-xl-4 col-sm-4  col-md-4" style="vertical-align: center; margin: auto">
          <div @click="$emit('change-show', !show)" style="float: right">
            <ArrowSlide style="float: right; font-size: 14pt; margin-top: 8px; margin-left: 20px" :def="show" />
          </div>
          <router-link v-if="$auth.isPermitted('pon_boxes_map_editing')" :to="{name: 'pon_boxes_edit_box', params: {'id': 'new',}, query: {iface_id: iface.id}}"  :title="$t('add_new_box')" style="float: right"><i style="font-size: 28px" class="mdi mdi-plus-box"></i></router-link>
          <router-link :to="{name: 'pon_boxes_map', query: {iface_id: iface.id}}"  :title="$t('view_on_map')" style="float: right"><i style="font-size: 28px" class="mdi mdi-map"></i></router-link>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="group-body" v-show="show">
        <slot>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import ArrowSlide from "@/components/ArrowSlide";

export default {
  components: {ArrowSlide},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    iface: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: 'Описание'
    },
    stat: {
      type: Object,
      default: () => {
        return {
          all: 0,
          online: 0,
        }
      }
    }
  },
  computed: {
    getStyleFromStat() {
      if (this.stat.all === 0) return '';
      if (this.stat.online === 0) return ' group-red ';
      if (this.stat.online === this.stat.all) return ' group-green ';
      if (this.stat.online < this.stat.all) return ' group-yellow ';
      return ''
    }
  },
}
</script>

<style scoped>
.group {
  width: 100%;
  border: 1px solid #DADADA;
  background: rgba(250, 250, 250, 1);
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.group-header {
  border: 0 !important;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.group-body {
  background: rgba(0, 0, 0, 0);
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.group-green .group-header {
  background: rgba(5, 100, 0, 0.8);
  color: #FAFAFA;
}

.group-unknown .group-header {
  background: rgba(200, 200, 200, 0.8);
  color: #0F0F0F;
}

.group-yellow .group-header {
  background: rgba(192, 154, 0, 1);
  color: #FFF;
}
.group .group-header a {
  color: #0F0F0F !important;
}
.group-green .group-header   a {
  color: #FAFAFA !important;
}
.group-yellow .group-header  a {
  color: #FFF !important;
}
.group-red .group-header  a {
  color: #FFF !important;
}

.group-red .group-header {
  background: rgba(130, 0, 0, 0.9);
  color: #FFF;
}
</style>
