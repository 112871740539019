<template>
    <div style="width: 100%; height: 100%;  text-align: center; margin-top: 10px; margin-bottom: 10px;">
        <h4 v-html="message" style="margin-bottom: 20px;"></h4>

        <button class="btn btn-light" @click="reload()" v-if="showReloadButton">
            <i class="mdi mdi-reload"></i> {{ $t('reload_page') }}
        </button>
    </div>
</template>


<script>
export default {
    props: {
        errorMessage: {
            type: String,
            default: '',
        },
        showReloadButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            message: this.$t('something_wrong'),
        }
    },
    methods: {
        reload() {
            location.reload();
        }
    },
    mounted() {
        if (this.errorMessage !== '') {
            if (this.errorMessage.length > 300) {
                this.message = this.errorMessage.substring(0, 300) + '...'
            }
            this.message = this.errorMessage
        }
    }
}
</script>