<template>
  <div class="container-fluid">
    <div class="row">
      <Preloader v-if="loading && error === ''"></Preloader>
      <SomethingWrong v-else-if="error !== ''" :error-message="error"></SomethingWrong>
      <div v-else class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12 col-md-12">
        <v-client-table :columns="table.columns" :options="table.options" v-model="data"
                        class="btn-add-margin-top-for-mobile">
          <template v-slot:name="{row}">
            <div style="min-width: 150px">
              {{row.name}}
            </div>
          </template>
          <template v-slot:key="{row}">
            <div style="min-width: 150px">
              <b>{{row.key}}</b>
            </div>
          </template>
          <template v-slot:icon="{row}">
            <div style="">
              <img :src="row.icon" style="max-height: 70px; max-width: 130px"/>
            </div>
          </template>
          <template v-slot:default_pollers="{row}">
            <div v-if="row.pollers !== null" style="min-width: 150px">
              <li v-for="(interval, poller) in row.pollers" :key="poller">{{poller}}</li>
            </div>
            <div v-else>{{$t('no_pollers')}}</div>
          </template>
          <template v-slot:access="{row}">
            {{ row.access.name }}
          </template>
          <template v-slot:actions="{row}">
            <router-link v-if="$auth.isPermitted('device_management')"
                         :to="{name: 'management_device_model_edit', params: {id:row.id}}" class="btn btn-default"
                         style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></router-link>

          </template>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";

export default {
  components: {SomethingWrong, Preloader},
  mounted() {
    this.$setRouteMeta(this.$t('device_models'))
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$api.get('/device-model').then(r => {
        this.data = r.data
      }).catch(e => {
        this.error = "Error loading models..."
      }).finally(() => {
        this.loading = false
      })
    },
  },
  data() {
    return {
      data: [],
      error: '',
      loading: true,
      table: {
        columns: ['key', 'name', 'type', 'default_pollers', 'icon', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            name: this.$t('name'),
            type: this.$t('type'),
            key: this.$t('key'),
            default_pollers: this.$t('default_pollers'),
            icon: this.$t('icon'),
            actions: '',
          },
          sortable: ['key', 'name'],
          filterable: ['key', 'name'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
}
</script>