<template>
    <div>
        <div style="padding-left: 25px" v-if="!hideOptions">
            <div class="row" style="margin-right: 0px; padding-top: 10px;">
                <div class="col-8 col-sm-9 col-lg-9 col-xl-10 ">
                  <date-picker v-model="time_range" type="datetime" range :showSecond="false" :second-step="60"
                               style="width: 100%"></date-picker>
                </div>
                <div class="col-4 col-sm-3 col-lg-3 col-xl-2">
                    <v-select class="style-chooser" :clearable="false"
                              v-model="form.step" label="label" :options="formOptions.step"></v-select>
                </div>
            </div>
        </div>
        <div :style="`height: ${height}px`">
            <Preloader v-if="!loaded"></Preloader>
            <line-chart
                    ref="chart"

                    v-if="loaded"
                    :draggable="true"
                    :data="chartdata"
                    :options="chartOptions"/>
        </div>
    </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import defaultOptions from './options/line/default'
import DatePicker from 'vue2-datepicker';
import {Line as LineChart} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js/auto'
import { getRelativePosition } from 'chart.js/helpers';

export default {
    name: 'LineChartContainer',
    components: {Preloader, LineChart, DatePicker},
    props: {
        options: {
            type: Object,
            default: () => defaultOptions,
        },
        callBack: {
            type: Object,
            default: null
        },
        height: {
            type: [Number, String],
            default: 300
        },
        hideOptions: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        time_range: {
          handler(n) {
            if(n.length === 0) return;
             this.form = {
               start: n[0].getTime() / 1000,
               stop: n[1].getTime() / 1000,
               step: this.form.step
             }
          }
        },
        form: {
            handler(n) {
               console.log("Form changed", n)
                let params = this.callBack.getParams()
                if (!this.hideOptions) params.step = n.step
                if (!this.hideOptions) params.start = n.start
                if (!this.hideOptions) params.stop = n.stop
                this.sendRequest(params)
            },
            deep: true,
        }
    },
    data: () => ({
        requestParams: {},
        chartOptions: null,
        loaded: false,
        chartdata: null,
        time_range: [],
        form: {
            start: 0,
            stop: 0,
            step: '10m',
        },
        chartObj: null,
        formOptions: {
          step: ['10m', '15m','30m','1h','3h','6h','12h','1d','7d',]
        },
    }),
    methods: {
        setChart(obj) {
          this.chartObj = obj
        },
        async load() {
          try {
            await this.callBack.sendRequest(null, null).then((r => {
              this.chartdata = r.data
              this.loaded = true
            }))
          } catch (e) {
            console.error(e)
          }
        },
        sendRequest(params) {
          this.loaded = false
            try {
                this.callBack.sendRequest(null, params).then((r => {
                    this.chartdata = r.data
                    this.loaded = true
                }))
            } catch (e) {
                console.error(e)
            }
        }
    },
    mounted() {
        this.loaded = false
        if(this.options) {
            this.chartOptions = this.options
        } else {
            this.chartOptions = defaultOptions
        }

        if(!this.hideOptions) {
           this.time_range = [
             new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
             new Date()
          ]
        }

        this.chartOptions.onClick = (e) => {
          let canvasPosition = getRelativePosition(e, e.chart);
          let index = e.chart.scales.x.getValueForPixel(canvasPosition.x);
          console.log("Index", index)
          console.log("Label", this.chartdata.labels[index])
          this.$emit('onClick', this.chartdata.labels[index])
        }
    }
}
</script>
