<template>
  <ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <!--
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="mdi mdi-bell font-24"></i>
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">Something else here</a>
      </div>
    </li>
    -->
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
<!--    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="font-24 mdi mdi-comment-processing"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" aria-labelledby="2">
        <ul class="list-style-none">
          <li>
            <div class="">
              &lt;!&ndash; Message &ndash;&gt;
              <a href="javascript:void(0)" class="link border-top">
                <div class="d-flex no-block align-items-center p-10">
                  <span class="btn btn-success btn-circle"><i class="ti-calendar"></i></span>
                  <div class="m-l-10">
                    <h5 class="m-b-0">Event today</h5>
                    <span class="mail-desc">Just a reminder that event</span>
                  </div>
                </div>
              </a>
              &lt;!&ndash; Message &ndash;&gt;
              <a href="javascript:void(0)" class="link border-top">
                <div class="d-flex no-block align-items-center p-10">
                  <span class="btn btn-info btn-circle"><i class="ti-settings"></i></span>
                  <div class="m-l-10">
                    <h5 class="m-b-0">Settings</h5>
                    <span class="mail-desc">You can customize this template</span>
                  </div>
                </div>
              </a>
              &lt;!&ndash; Message &ndash;&gt;
              <a href="javascript:void(0)" class="link border-top">
                <div class="d-flex no-block align-items-center p-10">
                  <span class="btn btn-primary btn-circle"><i class="ti-user"></i></span>
                  <div class="m-l-10">
                    <h5 class="m-b-0">Pavan kumar</h5>
                    <span class="mail-desc">Just see the my admin!</span>
                  </div>
                </div>
              </a>
              &lt;!&ndash; Message &ndash;&gt;
              <a href="javascript:void(0)" class="link border-top">
                <div class="d-flex no-block align-items-center p-10">
                  <span class="btn btn-danger btn-circle"><i class="fa fa-link"></i></span>
                  <div class="m-l-10">
                    <h5 class="m-b-0">Luanch Admin</h5>
                    <span class="mail-desc">Just see the my new admin!</span>
                  </div>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </li>-->
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-user-circle" style="font-size: 32px; position: relative; top: 7px" ></i></a>
      <div class="dropdown-menu dropdown-menu-right user-dd animated">

<!--        <a class="dropdown-item" href="javascript:void(0)"><i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
        <a class="dropdown-item" href="javascript:void(0)"><i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
        <a class="dropdown-item" href="javascript:void(0)"><i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
        <div class="dropdown-divider"></div>
  -->
        <a v-if="$auth.isPermitted('user_self_control')" class="dropdown-item" href="javascript:void(0)" @click="goToSettings()"><i class="ti-settings m-r-5 m-l-5"></i> {{$t('account_settings')}}</a>
<!--        <div class="dropdown-divider"></div>-->
        <a class="dropdown-item" @click="logout" href="javascript:void(0)"><i class="fa fa-power-off m-r-5 m-l-5" ></i> {{$t('logout')}}</a>
<!--        <div class="dropdown-divider"></div>
        <div class="p-l-30 p-10"><a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a></div>-->
      </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
  </ul>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout()
      window.location.href = '/login'
    },
    goToSettings() {
      this.$router.push({ name: 'account_settings' })
    },
  },
}
</script>
