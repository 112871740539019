import {defineStore} from "pinia";
import Vue from "vue";

export const deviceCallingSync = (tableId) => defineStore(`tables/${tableId}`,{
    state: () => ({
        meta: {},
        loading: false,
    }),
    getters: {
        isLoading: (state) => state.loading && (!state.device || !state.interfaces),
    },
    actions: {
        setMeta(meta) {
            for (const [moduleName, value] of Object.entries(meta)) {
                Vue.set(this.meta, moduleName, value)
            }
        },
        resetMeta() {
            this.meta = {}
        }
    },
})()
