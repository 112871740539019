<template>
  <div class="row" style="padding: 10px; min-height: 200px; ">
      <div v-if="loading" class="col-sm-12 col-xl-12"  >
        <Preloader></Preloader>
      </div>
      <div v-if="!loading  && data === null " class="col-12">
         <h4 style="text-align: center; font-weight: bold; margin: 20px">{{$t('info_not_available')}}</h4>
      </div>
      <div  style="margin-top: 10px" v-if="!loading  && data !== null " :class="logs.length > 0 ? 'col-12 col-md-5 col-sm-12 col-lg-5 col-xl-4' : 'col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'">
          <div class="row">
            <div class="col-12">
              <h4>{{$t('status')}}: <span :style="'color: ' + (data.status === 'Up' ? 'darkgreen' : 'darkred')">{{data.status}}</span></h4>
              <hr style="margin-top: 5px; margin-bottom: 5px;">
            </div>

            <div class="col-12" v-if="data.availability">
              <h4>{{$t('availability')}}</h4>
              <table style="width: 100%">
                <tr>
                  <td style="width: 33.3%; text-align: center">
                    <div>{{$t('periods.24h')}}</div>
                    <div style="font-size: 110%; font-weight: bold">{{data.availability['24h']}}%</div>
                  </td>
                  <td  style="width: 33.3%; text-align: center">
                    <div>{{$t('periods.7d')}}</div>
                    <div style="font-size: 110%; font-weight: bold">{{data.availability['7d']}}%</div>
                  </td>
                  <td  style="width: 33.3%; text-align: center">
                    <div>{{$t('periods.30d')}}</div>
                    <div style="font-size: 110%; font-weight: bold">{{data.availability['30d']}}%</div>
                  </td>
                </tr>
              </table>
              <hr style="margin-top: 5px; margin-bottom: 5px;">
            </div>
            <div class="col-12" >
              <table style="width: 100%">
                <tr>
                  <td style="vertical-align: top; width: 40%">{{data.status === 'Up' ? $t('online_from') : $t('offline_from')}}: </td>
                  <td style="vertical-align: top"><b>{{data.last_change}}</b>
                    <br>
                    {{$helpers.humanizeDuration(data.duration)}}
                  </td>
                </tr>
                <tr v-if="data.status === 'Up'">
                  <td style="vertical-align: top; width: 50%">{{$t('packet_loss')}}: </td>
                  <td style="vertical-align: top; font-weight: bold">
                    <span v-if="data.raw_status > 0 && data.raw_status < 100" style="">{{$t('yes')}} ({{100 - data.raw_status}}%)</span>
                    <span v-if="data.raw_status == 100" style="">{{$t('no')}}</span>
                    <span v-if="data.raw_status == 999" style="">{{$t('only_tcp')}}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
      </div>
      <div  style="margin-top: 10px" class="col-12 col-md-7 col-sm-12 col-lg-7 col-xl-8"  v-if="!loading  && logs.length > 0 ">
        <h4>{{$t('down_logs')}}</h4>
        <hr style="margin-top: 5px; margin-bottom: 5px;">
        <div style="overflow-y: auto; max-height: 600px">
          <table class="VueTables__table VueTables__table table table-striped table-bordered table-hover styled-table table-sm">
            <thead>
              <tr>
                <th>{{$t('down')}}</th>
                <th>{{$t('up')}}</th>
                <th>{{$t('duration')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in logs" :key="l.id">
                <td>{{l.start}}</td>
                <td>{{l.stop}}</td>
                <td> {{$helpers.humanizeDuration(l.duration_sec)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import BoxesList from "../../../../PonBoxes/BoxesList.vue";

export default {
  components: {Preloader },
  props: {
    deviceStore: {
      type: Object,
      default: null,
    },
    deviceCalling: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      data: null,
      logs: [],
    }
  },
  mounted() {
    this.loadInfo()
  },
  methods: {
    async loadInfo(from = 'cache') {
      this.loading = true
      await this.loadDeviceStatus()
      if(this.data !== null) {
        await this.loadDownLogs()
      }
      this.loading = false
    },
    async loadDeviceStatus() {
      await this.$api.get(`/component/pinger/status/${this.deviceStore.device.id}`,null, true).then(r => {
        this.data = r.data
      }).catch(() => {})
    },
    async loadDownLogs() {
      await this.$api.get(`/component/pinger/logs/${this.deviceStore.device.id}`,null, true).then(r => {
        this.logs = r.data
      }).catch(() => {})
    }
  },
}
</script>


<style scoped>

.pon-port {
  margin-top: 5px;
  font-size: 0.95rem;
}
.port-size {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  background: #555555;
  color: #FAFAFA;
  font-weight: normal;
  margin-left: 5px;
}

.pon-port-description {
  margin-left: 10px;
}

.pon-port-status {
  padding: 3px;
  border-radius: 3px;
  margin-left: 5px;
  color: white;
}

.slot-description {
  margin-top: 10px;
  margin-bottom: 5px;
}


.tree-link {
  font-weight: bold;
}

.tree-link a {
  color: black;
}

.tree-link a:active {
  color: black;
}

.tree-link a:visited {
  color: black;
}

</style>