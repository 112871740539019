<template>
  <div ref="mainBlock" >
    <div id="submenu-tooltip" role="tooltip" ref="submenu" v-click-outside="hideSubMenu">
      <router-link v-if="$auth.isPermitted('log_switcher_core_actions')" :title="$t('log_device_calling')"
                   class="submenu-link" :to="{name: 'log_device_calling_full', query: {device_id: box.id}}">
        <div style="width: 30px"><i class="fa fa-code"></i></div>
        <div>{{ $t('log_device_calling') }}</div>
      </router-link>
      <router-link v-if="$auth.isPermitted('device_management')" :title="$t('edit')" class="submenu-link"
                   :to="{name: 'management_device_edit', params: {id: box.id}}">
        <div style="width: 30px"><i class="fa fa-edit"></i></div>
        <div>{{ $t('edit') }}</div>
      </router-link>
    </div>
    <div
        style="position: absolute; top: 7px; right: 10px; height: 75px; width: 60px; padding-left: 25px;   z-index: 9"
        >
      <router-link style="margin-top: 0px" :to="{name: 'pon_boxes_edit_box', params: {'id': box.id,}}">
        <i v-if="$auth.isPermitted('pon_boxes_map_editing')" class="fa fa-edit edit-link" :title="$t('edit')"
           style="  font-size: 20px; padding-left: 5px; padding-right: 3px"></i>
        <i v-else class="fa fa-eye edit-link" :title="$t('edit')"
           style="  font-size: 20px; padding-left: 5px; padding-right: 3px"></i>
      </router-link>
    </div>
    <div :class="getClassByStatus(box)" style="padding: 5px; min-height: 75px" >
      <div class="row">
        <div class="col-3 align-content-center" style="">
          <div v-if="box.status === 'down'" style="position: absolute; left: 35px; top: 10px; z-index: 1; text-shadow: #FC0 1px 0 10px;" >
            <i class="fa fa-fire" style="font-size: 26px; color: #a40404"></i>
          </div>
          <img :src="getSvg(box.type.style)" style="text-align: center">
        </div>
        <div class="col-7" style=" margin: 0; padding: 0">
          <div style="font-size: 90%"><i class="mdi mdi-asterisk"></i> {{ box.type.name }}</div>
          <div class="" v-if="box.count_interfaces !== 0"><i class="mdi mdi-router-wireless"></i> {{$t('count_mon_interfaces')}}: {{ box.count_interfaces }}</div>
          <div style="color: #5F5F5F; font-weight: bold" v-else><i class="mdi mdi-router-wireless"></i> {{$t('no_mon_interfaces')}}</div>
        </div>
      </div>
      <div :style="'margin-top: 0; margin-right: 30px'   ">{{ box.name }}</div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    box: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      submenu: false,
    }
  },
  methods: {
    getSvg(style = {
      width: 28,
      fill: '#052e77',
      stroke: '#91c3ff',
      strokeWidth: 3,
    }) {
      let radius = style.width / 2
      let realWidth = (parseFloat(style.strokeWidth) + parseFloat(style.width)) - 5
      let circleRadius = radius - style.strokeWidth
      let svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="${realWidth + 10}" height="${realWidth + 10}" version="1.1">
  <circle cx="${radius - 1 + 5}" cy="${radius - 1 + 5}" r="${circleRadius + 5}" stroke="${style.stroke}" stroke-width="${style.strokeWidth}" fill="${style.fill}"></circle>
</svg>`
      return 'data:image/svg+xml,' + escape(svg)
    },
    toggleSubMenu(event) {
      if (this.submenu) {
        this.hideSubMenu(null)
      } else {
        this.showSubmenu(event)
      }
    },
    hideSubMenu(event) {
      if (event === null) {
        if (this.$refs.submenu.hasAttribute('data-show')) {
          this.$refs.submenu.removeAttribute('data-show');
        }
        this.submenu = false
        return;
      }

      if (event.path && event.path.filter(e => {
        return (typeof e.id !== 'undefined' && e.id !== null) && (e.id === 'submenu-tooltip' || e.id === 'submenu-btn')
      }).length !== 0) {
        return;
      }

      if (this.$refs.submenu.hasAttribute('data-show')) {
        event.preventDefault()
        this.$refs.submenu.removeAttribute('data-show');
      }
      this.submenu = false
    },
    showSubmenu(event) {
      this.submenu = true
      let submenu = this.$createPopper(event.target, this.$refs.submenu, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
              altBoundary: false,
              flipVariations: true,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [-95, 4],
            },
          },
        ],
      });
      this.$refs.submenu.setAttribute('data-show', '');
    },
    getClassByStatus(box) {
      if (box === null) {
        return ''
      }
      if (box.status === 'unknown') {
        return 'unknown'
      }
      if (box.status !== 'up') {
        return 'offline'
      } else {
        return 'online'
      }
    },
  }
}
</script>
<style scoped>
.online {
  background: rgba(0, 116, 11, 0.05);
  border: 1px solid rgba(0, 116, 11, 1);
  border-left: 10px solid  rgba(0, 116, 11, 1);
  border-collapse: collapse;
}
.unknown {
  background: rgba(70, 107, 73, 0.05);
  border: 1px solid rgb(101, 101, 101);
  border-left: 10px solid rgb(101, 101, 101);
  border-collapse: collapse;
}

.offline {
  background: rgba(116, 0, 0, 0.05);
  border: 1px solid darkred;
  border-left: 10px solid  darkred;
  border-collapse: collapse;
}
</style>

<style>
.submenu-link {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #DADADA;
  border-collapse: collapse;
  margin-bottom: -1px;
  color: #2c2c2c;
  width: 200px;
}

.submenu-link:hover {
  background-color: #27a8e2;
  color: white;
}

.submenu-link:active {
  background-color: #186183;
  color: white;
}

.btn-submenu {
  width: 100%;
  height: 100% !important;
  border-radius: 0;
  padding: 0;
}

.no-padding {
  padding: 0 !important;
}

.padding {
  padding-left: 10px;
  padding-right: 10px;
}

#submenu-tooltip {
  background: #FAFAFA;
  color: black;
  font-size: 13px;
  border-radius: 0px !important;
  display: none;
  margin-left: 10px !important;
  margin-right: 10px !important;
  z-index: 100;
  -webkit-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
}

#submenu-tooltip[data-show] {
  display: block;
}

#submenu-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#submenu-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: 4px;
}

#submenu-tooltip[data-popper-placement^='left'] > #arrow {
  right: 4px;
}

#submenu-tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
