<template>
  <div  v-if="macro !== null">
    <div class="row">
      <div class="col-12 col-mini col-sm-12 col-lg-6 col-xl-6 col-md-12">
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
          <div class="col-sm-9">
            <input v-model="macro.name" class="form-control">
          </div>
        </div>
        <div class="form-group row" v-if="macro.id">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('info') }}</label>
          <div class="col-sm-9">
            {{$t('id')}}: <b>{{macro.id}}</b><br>
            {{$t('created_at')}}: <b>{{macro.created_at}}</b><br>
            {{$t('updated_at')}}: <b>{{macro.updated_at}}</b><br>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('enabled') }}</label>
          <div class="col-sm-9">
            <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
              <input type="checkbox"  v-model="macro.enabled" >
              <span class="slider"></span>
            </label>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('onts_registration.model_vendor') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      v-model="choosed_model_vendors"
                      :clearable="true"
                      multiple
                      return-object
                      single-line
                      :options="modelVendors.filter(e => { return !choosed_model_vendors.includes(e)})"
                      :close-on-select="false"
            ></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('onts_registration.models') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      v-model="macro.models"
                      label="name"
                      :clearable="true"
                      multiple
                      return-object
                      single-line
                      :disabled="choosed_model_vendors.length === 0"
                      :options="formOptions.models.filter(e => {return macro.models.filter(s => e.id === s.id).length === 0 && choosed_model_vendors.filter(s => s == e.vendor).length !== 0 })"
                      :close-on-select="false"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    formOptions: {
      type: Object,
      required: false,
      default: null,
    }
  },
  computed: {
    modelVendors() {
      let vendors = {}
      for (const [key, value] of Object.entries(this.formOptions.models)) {
         vendors[value.vendor] = value.vendor
      }
      let vendorKeys = []
      for (const [key, value] of Object.entries(vendors)) {
        vendorKeys.push(value)
      }

      return vendorKeys.sort()
    },
  },
  data() {
    return {
      macro: null,
      choosed_model_vendors: [],
      loading: true,
      error: '',
    }
  },
  watch: {
    choosed_model_vendors: {
      handler(n) {
        let models = []
        this.macro.models.forEach((model, id) => {
          if(n.includes(model.vendor)) {
              models.push(model)
          }
        })
        this.macro.models = models
      }
    },
    item: {
      handler(n, o) {
        if(n.id != o.id) {
          this.fillVendors()
        }
      }
    }
  },
  async mounted() {
    this.macro = this.item
    this.fillVendors()
    console.log(this.choosed_model_vendors)
  },
  methods: {
    fillVendors() {
      this.choosed_model_vendors = []
      let vendors = {}
      console.log(this.macro)
      for (const [key, value] of Object.entries(this.macro.models)) {
        vendors[value.vendor] = value.vendor
      }
      for (const [key, value] of Object.entries(vendors)) {
        this.choosed_model_vendors.push(value)
      }
    },
  }
};
</script>
