<template>
  <LoginNormalTpl
    v-if="!$config.isWhiteLabel"
    :inProccess="inProccess"
    :message="message"
    :login="login"
    :password="password"
    :error="error"
    :twoFa="need_2fa"
    @update:login="newValue => login = newValue"
    @update:password="newValue => password = newValue"
    @update:twofa_pin="newValue => twofa_pin = newValue"
    @submit="tryLogin"
  />
  <LoginWhiteLabelTpl
    v-else
    :inProccess="inProccess"
    :message="message"
    :login="login"
    :password="password"
    :error="error"
    @update:login="newValue => login = newValue"
    @update:password="newValue => password = newValue"
    @submit="tryLogin"
  />
</template>

<script>
import LoginNormalTpl from "./templates/NormalTpl";
import LoginWhiteLabelTpl from "./templates/WhiteLabelTpl.vue";
export default {
  components: {LoginNormalTpl, LoginWhiteLabelTpl},
  mounted() {
    let script = document.createElement('script')
    script.setAttribute('src', '/assets/auth.js')
    document.head.appendChild(script)
    this.error = this.$route.query.error

    if(this.$route.query.demo) {
      this.login = this.$route.query.demo
      this.password = 'demo'
      setTimeout(() => {
        this.tryLogin()
      }, 500)
    }
  },
  data() {
    return {
      inProccess: false,
      message: '',
      login: this.$auth.getUser()?.login,
      password: '',
      error: '',
      twofa_pin: null,
      need_2fa: false,
    }
  },
  methods: {
    tryLogin() {
        if(this.need_2fa && (!this.twofa_pin || this.twofa_pin.length < 6)) return

        this.error = ''
        this.inProccess = true
        this.$auth.login(this.login, this.password, this.twofa_pin).then(r => {

          if(r?.need_2fa) {
            this.need_2fa = true
            return;
          }

          this.$api.setToken(r.key)
          if(typeof this.$route.query.redirect !== 'undefined') {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }
        }).catch(e => {
           if(e.response.status === 401) {
             this.$noty.error(this.$t('login_or_password_incorrect'))
           } else if (e.response.status === 403) {
             this.$noty.error(e.response.data.error.description)
               this.error = e.response.data.error.description
           } else if (e.response.status === 530) {
             this.$noty.error(e.response.data.error.description)
               this.error = e.response.data.error.description
           } else {
             this.$noty.error(this.$t('unknown_error'))
           }
        }).finally(() => {
          this.inProccess = false
        })
    }
  }
}
</script>

