<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-mini col-sm-12 col-lg-5 col-xl-4 col-md-12">
        <Card :name="$t('personal_info')" :show="true">
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('login') }}</label>
            <div class="col-sm-9">
              <input :disabled="getAllowedActions().indexOf('update_login') === -1" type="text" v-model="data.login"
                     class="form-control" :placeholder="$t('user_login')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
            <div class="col-sm-9">
              <input :disabled="getAllowedActions().indexOf('update_name') === -1" type="text" v-model="data.name"
                     class="form-control" :placeholder="$t('user_name')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('role') }}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser" :disabled="getAllowedActions().indexOf('change_role') === -1"
                        v-model="data.role" label="name" :options="roles"
                        :hide-selected="true"
                        :clearable="false"
                        :close-on-select="true"
                        :filterable="false"
                        :searchable="false"
              ></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('device_groups') }}</label>
            <div class="col-sm-9">
              <v-select multiple class="style-chooser"
                        :disabled="getAllowedActions().indexOf('change_group') === -1 ||  data.role.id < 0 || data.id < 0"
                        :close-on-select="false"
                        :hide-selected="true"
                        :clearable="false"
                        :filterable="false"
                        :searchable="false"
                        v-model="data.device_groups" label="name" :options="getDeviceGroups"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('status') }}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser" :disabled="getAllowedActions().indexOf('change_status') === -1"
                        v-model="data.status" label="name" :options="getStatuses"
                        :hide-selected="true"
                        :clearable="false"
                        :close-on-select="true"
                        :filterable="false"
                        :searchable="false"
              ></v-select>
            </div>
          </div>
          <div class="form-group row"> 
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('new_password') }}</label> 
            <div class="col-10 col-sm-8"> 
              <input 
                :type="showPassword ? 
                'text' : 
                'password'"  
                class="form-control" 
                v-model="data.password" 
                @input="validateForm" 
                placeholder="*************"/>
            </div> 
            <div class="col-2 col-sm-1 toggle">
              <button class="btn btn-block toggle" @click="toggleShow"> 
                <span class="icon is-small is-right"> 
                  <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i> 
                </span> 
              </button> 
            </div>
          </div>          
          <div class="form-group row"> 
              <label class="col-sm-3 text-right control-label col-form-label">{{ $t('confirm_password') }}</label>
              <div class="col-sm-9">
                <input 
                  :type="showPassword ? 
                  'text' : 
                  'password'"
                  class="form-control" 
                  v-bind:class="{ '': isActive, 'invalid': hasError }"
                  v-model="confirmPassword" 
                  @input="validateForm"
                  placeholder="*************"/> 
                <p v-if="errorMessage" class="form-control__msg">{{ errorMessage }}</p>
              </div>
          </div> 
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('language') }}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser"
                        v-model="data.language" label="value" :options="locales"
                        :hide-selected="true"
                        :clearable="false"
                        :close-on-select="true"
                        :filterable="false"
                        :searchable="false"
                        :reduce="locale => locale.key"></v-select>
            </div>
          </div>
          <div class="form-group row"  v-if="data.is_twofa">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('twofa') }}</label>
            <div class="col-sm-9">
              <button class="btn btn-default" @click="disconnect2FA">
                {{ $t('twofa_disconnect') }}
              </button>
            </div>
          </div>
          <div class="row" v-if="getAllowedActions().length !== 0">
            <hr style="margin-top: 10px; width: 100%">
            <div class="col-12 col-mini">
              <button v-if="userId !== 0" @click="updateUser" :disabled="savingInProcess" v-bind:class="{ 'btn btn-default': isActive, 'disabled': hasError }">
                <i class="fa fa-save" style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i>
                {{ $t('save') }}
              </button>
              <button v-if="userId === 0" @click="createUser" :disabled="savingInProcess" v-bind:class="{ 'btn btn-default': isActive, 'disabled': hasError }">
                <i class="fa fa-save" style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i>
                {{ $t('create') }}
              </button>
            </div>
          </div>
        </Card>
        <AccountSettings v-if="userId !== 0" :settings="data.settings" :user-id="userId" />
      </div>
      <div class="col-mini col-12 col-sm-12 col-lg-7 col-xl-8 col-md-12" v-if="userId !== 0">
        <Card :name="$t('notifications_configuration')" :show="true" v-if="
        $auth.isPermitted('notifications_configure_self_contacts') &&
      $config.isComponentEnabled('notifications') &&
      $config.isComponentEnabled('events')
">
          <UserSourcesComponent :user-id="userId"></UserSourcesComponent>
        </Card>
        <Card :name="$t('strict_by_ip_access')" :show="true"  v-if="$auth.isPermitted('user_management_config_strict_ip')">
        <StrictByIp :settings="data.settings" :user-id="userId"></StrictByIp>
      </Card>
        <Card :name="$t('active_sessions')" :show="true" v-if="typeof data.active_sessions !== 'undefined'">
          <div v-cloak>
            <v-client-table v-model="data.active_sessions" :columns="table.columns" :options="table.options">
              <template v-slot:actions="{ row }">
                <div>
                  <button class="btn btn-default" @click="closeSession(row.id)"><i class="mdi mdi-exit-to-app"></i>
                    {{ $t('close_session') }}
                  </button>
                </div>
              </template>
              <template v-slot:device="{ row }">
                <div v-if="row.device !== null  && row.device.device === 'desktop'">
                  <i class="mdi mdi-desktop-tower"></i> <b>{{ row.device.os_info.name }}
                  {{ row.device.os_info.version }}</b>
                  <br>
                  {{ row.device.client.name }} <i>{{ row.device.client.version }}</i>
                </div>
              </template>
            </v-client-table>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import Card from "@/components/Card"
import UserSourcesComponent from "@/views/Notifications/UserContactsConfiguration";
import AccountSettings from "@/components/Blocks/AccountSettings";
import StrictByIp from "@/components/Blocks/StrictByIp.vue";

export default {
  components: {StrictByIp, AccountSettings, UserSourcesComponent, Card, SomethingWrong, Preloader, },
  data() {
    return {
      userId: 0,
      showPassword: false,
      confirmPassword: '',
      errorMessage: '',
      isActive: true,
      hasError: false,
      valid: true,
      deviceGroups: [],
      data: {},
      roles: [],
      statuses: {
        ENABLED: this.$t('user_statuses.enabled'),
        DISABLED: this.$t('user_statuses.disabled'),
      },
      savingInProcess: false,
      loading: true,
      error: '',
      table: {
        columns: ['last_activity', 'remote_addr', 'device', 'actions'],
        options: {
          headings: {
            last_activity: this.$t('last_activity'),
            remote_addr: this.$t('remote_addr'),
            device: this.$t('device'),
            actions: '',
          },
          sortable: ['last_activity', 'remote_addr', 'device'],
          filterable: false,

          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  computed: {
    getDeviceGroups() {
      let groups = []
      this.deviceGroups.forEach(group => {
        if (this.data.device_groups.filter(e => {
          return group.id === e.id
        }).length === 0) {
          groups.push(group)
        }
      })
      return groups
    },
    getStatuses() {
      var elems = [];
      for (const [key, value] of Object.entries(this.statuses)) {
        elems.push({
          key: key,
          name: value
        })
      }
      return elems
    },
    locales() {
      let locales = []
      for (const [key, value] of Object.entries(this.$config.locale.supportedLocales)) {
        locales.push({
          key: key,
          value: value,
        })
      }
      return locales
    }
  },
  async mounted() {
    this.loading = true
    await this.loadRoles()
    await this.loadDeviceGroups()
    this.loading = false
    if (this.$route.params.id === 'new') {
      this.$setRouteMeta(this.$t('create_new_user'))
      this.setUser({
        role: {
          id: 0,
          name: this.$t('user_role'),
        },
        device_groups: [],
        name: '',
        login: '',
        password: '',
        status: 'ENABLED',
        id: 0,
      })
    } else {
      this.userId = this.$route.params.id
      await this.load()
    }
  },
  watch: {
  },
  methods: {
    async disconnect2FA() {
      if(!confirm(this.$t('twofa_confirm'))) return;

      const { data } = await this.$api.put('/user/' + this.userId+'/2fa/connect', { is_twofa: false })

      if(data.success) {
        this.data.is_twofa = false;
        this.$noty.success(this.$t('twofa_disconnect'))
      } else {
        this.$noty.error(this.$t('wrong_pin'))
      }
    },
    validateForm() {
      if(this.data.password === '' && this.confirmPassword === '') {
        this.hasError = false;
        this.errorMessage = '';
        return true;
      } else if (this.data.password !== this.confirmPassword) {
        this.hasError = true;
        this.errorMessage =  this.$t('confirm_password_error') ;
        return false
      } else {
        this.hasError = false;
        this.errorMessage = '';
        return true;
      }    
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    closeSession(sessionId) {
      this.loading = true
      this.$api.delete('/user-session-close/' + sessionId).then(() => {
        this.load()
        this.$noty.success(this.$t('session_closed', {id: sessionId}))
      })
    },
    setUser(resp) {
      resp.status = {
        key: resp.status,
        name: this.statuses[resp.status],
      }
      if (resp.active_sessions === null) {
        resp.active_sessions = []
      }
      this.data = resp
    },
    async updateUser() {
      this.savingInProcess = true
      await this.$api.put('/user/' + this.userId, {
        role: this.data.role,
        name: this.data.name,
        login: this.data.login,
        password: this.data.password,
        status: this.data.status.key,
        language: this.data.language,
        device_groups: this.data.device_groups,
      }).then(data => {
        this.setUser(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('user_successful_updated', {name: this.data.login}))
        this.confirmPassword = ''
      }).catch((alert) => {
      })
      this.savingInProcess = false
    },
    async createUser() {
      if(!this.validateForm()) return
      this.savingInProcess = true
      this.$api.post('/user', {
        role: this.data.role,
        name: this.data.name,
        login: this.data.login,
        password: this.data.password,
        status: this.data.status.key,
        language: this.data.language
      }).then(data => {
        this.setUser(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('user_successful_created', {name: this.data.login, id: data.data.id}))
        this.$router.push({name: 'management_user_edit', params: {id: data.data.id}})
        this.userId = data.data.id
        this.load()

      }).catch((alert) => {
        this.savingInProcess = false
      })
    },
    async load() {
      this.loading = true
      await this.$api.get('/user/' + this.userId).then(data => {
        this.setUser(data.data)
        this.$setRouteMeta(this.$t('edit_user', {login: data.data.login}))
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
    async loadRoles() {
      await this.$api.get('/user-role').then(data => {
        this.roles = []
        data.data.forEach(e => {
          if (e.display) {
            this.roles.push(e)
          }
        })
      }).catch(() => {
        this.error = 'ERROR'
      })
    },
    async loadDeviceGroups() {
      await this.$api.get('/device-group').then(data => {
        this.deviceGroups = []
        data.data.forEach(e => {
          this.deviceGroups.push(e)
        })
      }).catch(() => {
        this.error = 'ERROR'
      })
    },
    getAllowedActions() {
      if (this.$auth.getPermissions().indexOf('user_management') !== -1) {
        return ['update_name', 'update_password', 'update_login', 'close_session', 'change_role', 'change_group', 'change_status']
      }
      if (this.$auth.getPermissions().indexOf('user_self_control') !== -1) {
        return ['update_name', 'update_password']
      }
      return []
    }
  }
}
</script>
