<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- Sales Cards  -->
    <!-- ============================================================== -->
    <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="error-box">
            <div class="error-body text-center">
              <h1 class="error-title text-danger">403</h1>
              <h3 class="text-uppercase error-subtitle">{{$t('forbidden_resource')}}</h3>
              <p class="text-muted m-t-30 m-b-30">{{$t("forbidden_resource_descr")}}</p>
              <a href="/" class="btn btn-danger btn-rounded waves-effect waves-light m-b-40">{{$t('back_to_home')}}</a> </div>
          </div>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- Recent comment and chats -->
    <!-- ============================================================== -->
  </div>
</template>


<script>
export default {

}
</script>