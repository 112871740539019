<template>
  <div class="container-fluid">
    <FullscreenPreloader :show="isLoading"></FullscreenPreloader>

    <div class="row">
      <div class="col-12 col-mini">
        <ul class="nav nav-tabs" role="tablist" style="margin-bottom: -1px">
          <li class="nav-item" v-for="(val, key) in tabs" :key="key" >
            <a :class="'nav-link ' + (key === active_tab ? 'active' : '')" href="javascript:void(0)"
               @click="changeTab(key)">  {{ val.name }}
            </a>
          </li>
        </ul>

        <div v-if="!isLoading && (mode === 'create' || (mode === 'edit' && macros.id !== undefined))" class="tab-content" style="background-color: #FFF; border-left: 1px solid #EAEAEA;border-bottom: 1px solid #EAEAEA;  border-right: 1px solid #EAEAEA;  padding: 10px">
          <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-mini">
            <div style=" display: flex;
      align-items: center;
      justify-content: flex-end;">
              <button class="btn btn-success"
                      style="width: 200px;"
                      v-if="mode === 'create'"
                      @click="createMacros"
              ><i class="fa fa-save"></i>
                {{ $t('macros.create') }}
              </button>
              <button class="btn btn-success"
                      style="width: 200px;"
                      v-if="mode === 'edit'"
                      @click="updateMacros"
              ><i class="fa fa-save"></i>
                {{ $t('macros.save') }}
              </button>
              <button class="btn btn-danger ml-3"
                      style="width: 200px;"
                      v-if="mode === 'edit'"
                      @click="remove(macros.id)"
              ><i class="fa fa-trash"></i>
                {{ $t('macros.remove') }}
              </button>
            </div>

          </div>
            <div class="col-12  col-mini">
              <hr>
            </div>
            <div class="col-12 col-mini" v-if="macros && !isLoading">
              <keep-alive>
                <component :is="tabs[active_tab].component" :api-component-name="apiComponentName" :item="macros" :form-options="options" @update="updateFromComponent"></component>
              </keep-alive>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";

import MacrosParameterConfig from "./tabs/MacrosParameterConfig";
import MacrosTemplateConfiguration from "./tabs/MacrosTemplateConfiguration";
import CommonConfiguration from "./tabs/CommonConfiguration";

export default {
  components: {FullscreenPreloader, MacrosTemplateConfiguration, MacrosParameterConfig, CommonConfiguration},
  data() {
    return {
      active_tab: 'common',
      tabs: {
        'common': {name: this.$t('macros.common'), component: 'CommonConfiguration'},
        'parameters': {name: this.$t('macros.parameters'), component: 'MacrosParameterConfig'},
        'template': {name: this.$t('macros.template'), component: 'MacrosTemplateConfiguration'},
      },
      options: {
        devices: [],
        models: [],
        roles: [],
      },
      apiComponentName: '/component/macros/control',
      isLoading: false,
      mode: null,
      macros: {
        "id": null,
        "name": "",
        "description": "",
        "display_for": [],
        "display_output": "all",
        "models": [],
        "user_roles": [],
        "template": "",
        "parameters": []
      }
    }
  },
  methods: {
    changeTab(tabName) {
      if(this.tabs[tabName]) {
        this.active_tab = tabName
      }
    },
    async loadModels() {
      const { data } = await this.$api.get('/device-model')
      this.options.models = data
    },
    async loadRoles() {
      await this.$api.get('/user-role').then(data => {
        this.options.roles = []
        data.data.forEach(e => {
          if (e.display) {
            this.options.roles.push(e)
          }
        })
      }).catch((e) => {
        console.error(e)
        this.error = 'ERROR'
      })
    },
    async loadDevicesForSelect() {
      var data = []
      await this.$api.get('/device?limit=99999&ascending=name').then(r => {
        r.data.forEach(d => {
          if(!d.enabled) return;
          if (d.name.trim() === '') {
            d.name = this.$t('no_name')
          }
          d.displayName = `${d.ip} - ${d.name}`
          data.push({
            displayName: d.displayName,
            name: d.name,
            id: d.id
          })
        })
      })
      this.options.devices = data
    },
    async remove(id) {
      if(!confirm(this.$t('macros.confirm_remove'))) return;

      await this.$api.delete('/component/macros/control/'+id)
      this.$noty.success(this.$t('macros.success_deleted'))
      await this.$router.push({name: 'config_macros'})
    },
    async createMacros() {
      this.isLoading = true
      this.$api.post(this.apiComponentName, this.macros).then(async ({ data }) => {
        this.$noty.success(this.$t('macros.success_created'))
        this.mode = 'edit'
        this.macros = data
        await this.$router.push({name: 'macros_edit', params: { id: data.id }})
        this.$setRouteMeta(this.$t('macros.edit_macros', {name: this.macros.name}))
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.isLoading = false
      })
    },
    updateFromComponent(data) {
      this.$set(this.macros, data.key, data.value)
    },
    async updateMacros() {
      this.isLoading = true
      this.$api.put(this.apiComponentName+"/"+this.macros.id, this.macros).then(async ({ data }) => {
        this.macros = data
        this.$noty.success(this.$t('macros.success_updated'))
      }).catch((e) => {
        console.error(e)
        this.$noty.warning(this.$t('macros.fail_updated'))
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  async mounted() {
    this.isLoading = true
    const id = this.$route.params.id

    await this.loadRoles()
    await this.loadModels()
    await this.loadDevicesForSelect()

    if(id) {
      const {data} = await this.$api.get('/component/macros/control/' + id)
      let params = []
      data.parameters.forEach(el => {
        if(typeof el.item_filter === 'undefined') {
          el.item_filter = ''
        }
        if(typeof el.item_value === 'undefined') {
          el.item_value = ''
        }
        params.push(el)
      })
      data.parameters = params
      this.macros = data
      this.mode = 'edit'
      this.$setRouteMeta(this.$t('macros.edit_macros', {name: this.macros.name}))
    } else {
      this.mode = 'create'
      this.$setRouteMeta(this.$t('macros.add_new'))
    }

    this.isLoading = false
  }
}
</script>


<style >
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 550px;
}
.height-300 {
  height: 300px;
}
</style>
