<template>
  <footer class="footer text-right" style="padding-bottom: 30px">
    <div v-if="this.$config.isDemoMode" style="font-size: 18px; font-weight: bold; opacity: 0.8; ">DEMO MODE</div>
    <template v-if="!$config.isWhiteLabel">
      All Rights Reserved by <a href="https://wildcore.tools" target="_blank">wildcore.tools</a><br>
      <small>
        Change log in telegram <a href="https://t.me/wildcore_dms">wildcore_dms</a>
        <br>
        Agent: {{$config.version.agentVersion}}-{{$config.version.agentBuildDate}} ({{$config.version.agentCommitId}})<br>
        Panel: {{$config.version.panelVersion}}-{{$config.version.buildDate}} ({{$config.version.commitId}})<br>
      </small>
    </template>
    <template v-else>
      All Rights Reserved<br>
      <small>
        Agent: {{$config.version.agentVersion}}-{{$config.version.agentBuildDate}} ({{$config.version.agentCommitId}})<br>
        Panel: {{$config.version.panelVersion}}-{{$config.version.buildDate}} ({{$config.version.commitId}})<br>
      </small>
    </template>
  </footer>
</template>


<script>
 export default {

 }
</script>
