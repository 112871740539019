<template>
  <div :style="`zoom: ${$config.zoom}`">
  <div id="main-wrapper" :class="mainWrapperClass"  data-sidebartype="mini-sidebar" v-if="!$route.query._iframe || $route.query._iframe !== 'yes'">
    <Preloader :with-text="true" v-show="prealoader" style="position: fixed; top: 0; left: 0; z-index: 999; background: rgba(255, 255, 255, 1);"/>
    <Topbar v-show="!loading"/>
    <LeftSideBar v-show="!prealoader" :is-loaded="!loading" v-click-outside="hideSideBar" />
    <PageWrapper v-if="!loading" />
    <BigNotify ref="notify" :hide-on-click="true" :timeout="0" v-show="!loading"></BigNotify>
    <to-top-btn></to-top-btn>
  </div>
    <div v-else class="iframe">
        <div v-if="$route.query._show_wdms_btn && $route.query._show_wdms_btn === 'yes'"  class="btn-to-NMS">
          <a class="btn btn-info" :href="$route.path" target="_blank"><i class="mdi mdi-arrow-right"></i> {{$t('open')}}</a>
        </div>
        <PageWrapper v-if="!loading" />
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/Main/Topbar";
import LeftSideBar from "@/components/Main/LeftSideBar";
import PageWrapper from "@/components/Main/PageWrapper";
import BigNotify from "@/components/BigNotify";
import Preloader from "@/components/Preloader";
import ToTopBtn from "@/components/ToTopBtn";

export default {
  components: {
    Preloader,
    PageWrapper,
    LeftSideBar,
    Topbar,
    BigNotify,
    ToTopBtn
  },
  data() {
      return {
        loading: true,
        prealoader: true,
        isActiveSideBar: false,
        mainWrapperClass: "mini-sidebar",
        subscrInfo: null,
      }
  },
  async mounted() {
    await this.loadProps()
    if (this.$config.version.panelVersion !== "0.0.0" && this.$config.version.agentVersion !== this.$config.version.panelVersion) {
      this.$refs.notify.displayNoty(this.$t('old_agent_version_reload_your_page'))
    }
    if(this.subscrInfo && this.subscrInfo.limit_is_reached) {
      this.$refs.notify.displayNoty(this.$t('big_noty_subscription_limit_reached'))
    } else if(this.subscrInfo && this.subscrInfo.connect_to_validation_server_failed) {
      this.$refs.notify.displayNoty(this.$t('big_noty_connect_to_validation_server_failed'))
    }
    if (document.getElementById('custom-resource-js') === null) {
      let script = document.createElement('script')
      script.setAttribute('src', '/dist/js/custom.js')
      document.body.appendChild(script)
    }
    let d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000 * 7);
    let expires = "expires=" + d.toUTCString();
    document.cookie =
        "Token=" + this.$auth.getAuthKey() + ";" + expires + ";path=/";
    setTimeout(() => {
      this.prealoader = false
    }, 150)
  },
  watch: {},
  methods: {
    setZoom(zooms) {
      let zoom = zooms.main
      if (this.$route.query._iframe && this.$route.query._iframe === 'yes') {
        zoom = zooms.iframe
      }
      this.$config.zoom = zoom
    },
    hideSideBar(click) {
     if(window.innerWidth > 756) {
       return;
     }
     if(typeof click.target.className.includes === 'function') {
       if (click.target.className.includes('nav-toggler')) {
         return
       }
       if (click.target.className.includes('ti-close')) {
         return
       }
     }
     document.getElementById('main-wrapper').setAttribute('class', '')
     document.getElementById('ti-menu-toggler').setAttribute('class', 'ti-close ti-menu')
    },
    async loadProps() {
      this.loading = true
      await this.$api.get('/system/settings').then(r => {
        this.subscrInfo = r.data.subscription_info
        this.setZoom(r.data.zoom)
        this.$config.enabledComponents = r.data.components.enabled
        if (typeof r.data.version !== 'undefined') {
          this.$config.version.agentVersion = r.data.version
          this.$config.version.agentBuildDate = r.data.build_date
          this.$config.version.agentCommitId = r.data.commit_id
        }
        this.$config.map.coordinates = r.data.map.coordinates
        this.$config.map.default_tile = r.data.map.default_tile
        this.$config.components = {}
        if(this.$config.defaultRolePermissions) {
            this.$config.defaultRolePermissions = r.data.default_role_permissions
        }
        r.data.components.all.forEach(e => {
          this.$set(this.$config.components, e.key, e)
        })
        this.$auth.updateUser(r.data.user)
        this.$i18n.locale = r.data.user.language
        this.$noty.updateLayout(r.data.user.settings.global.notification_place)
      }).catch(e => {
        if (e.response) {
          this.$noty.error("Error loading info from API: " + e.response.data.error.description)
        } else {
          this.$noty.error("Error loading info from API: " + e.message)
        }
      })
     this.loading = false
    }
  }
}
</script>

<style>
.btn-to-NMS {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 900;
  opacity: 0.5;
}
.btn-to-NMS:hover {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 900;
  opacity: 0.9;
}
</style>
