// eslint-disable-next-line no-unused-vars
import axios, {AxiosInstance} from 'axios'



Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};


export default class ServerApi {
    /**
     * @type AxiosInstance
     */
    _http = null

    _noty = null

    _promises = []

    _inProcess = []

    _action401 = null
    _action530 = null

    _promisesEnabled = false

    _i18n = null

    #cancelRequestTimeout = 60000

    setCancelRequestTimeout(cancelTimeoutMs = 60000) {
            this.#cancelRequestTimeout = cancelTimeoutMs
    }

    static instance = null;


    static getInstance() {
        return this.instance
    }

    set401callback(callback) {
        this._action401 = callback
        return this
    }
    set530callback(callback) {
        this._action530 = callback
        return this
    }
    setI18N(i18n) {
        this._i18n = i18n
        return this
    }

    setNoty(noty) {
        this._noty = noty
        return this
    }

    enableSupportWaiting() {
        this._promisesEnabled = true
        this._promises = []
    }
    disableSupportWaiting() {
        this._promisesEnabled = false
        this._promises = []
    }
    _addPromise(promise) {
        if(this._promisesEnabled) {
            this._promises.push(promise)
        }
        return promise
    }
    constructor(baseURL) {
        const defaultOptions = {
            baseURL: baseURL,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        let cache = JSON.parse(localStorage.getItem('api-cache'))
        if(typeof cache !== 'undefined' && cache !== null) {
            this._cache = cache
        }
        this._http = axios.create(defaultOptions);
        ServerApi.instance = this
    }
    setToken(token) {
        this._http.interceptors.request.use(function (config) {
            config.headers['X-Auth-Key'] =  token;
            return config;
        });
    }

    login(user, password, twofaPin=null) {
       return  this._addPromise(this._http.post('/auth', {'login': user, 'password': password, 'twofa_pin': twofaPin}))
    }
    _processError(e, hideErrors = false) {
        if(e.response.status === 401 && this._action401 !== null) {
            this._action401(e)
            return
        }

        if(e.response.status === 530 && this._action530 !== null) {
            this._action530(e)
            return
        }

        if(this._noty !== null && !hideErrors) {
            if (e.response?.data?.error) {
                if(this._i18n !== null) {
                    this._noty.error(`${this._i18n.t('server_errors.' + e.response.data.error.type)} (${e.response.data.error.description})`)
                } else {
                    this._noty.error("Error loading info from API: " + e.response.data)
                }
            } else {
                this._noty.error("Error loading info from API: " + e.message)
            }
        }
    }

    get(url, params = {}, hideErrors = false, abortSignal = null) {
        return this._addPromise(this._http.get(url, {
            params: params,
            signal: abortSignal,
        }).then(r => {
            return r.data
        }).catch(e => {
            this._processError(e, hideErrors)
            throw e
        }))
    }
    post(url,data = {}, hideErrors = false, abortSignal = null) {
        return this._addPromise(this._http.post(url, data, {signal: abortSignal}).then(r => {
            return r.data
        }).catch(e => {
            this._processError(e, hideErrors)
            throw e
        })).finally(() => {

        })
    }
    put(url,data = {}, hideErrors = false, abortSignal) {
        return this._addPromise(this._http.put(url, data, {signal: abortSignal}).catch(e => {
            this._processError(e, hideErrors)
            throw e
        })).then(r => {
            return r.data
        }).finally(() => {

        })
    }
    delete(url,data = {}, hideErrors = false) {
        return this._addPromise(this._http.delete(url, data).then(r => {
            return r.data
        }).catch(e => {
            this._processError(e, hideErrors)
            throw e
        })).finally(() => {

        })
    }
    switcherCore(deviceId, module, args = [], online = false) {
        let type = 'cache'
        if(online) {
            type = 'device'
        }
        return this._addPromise(this._http.post('/switcher-core/' + type + '/' + module + '/' + deviceId, args).then(r => {
            return r.data
        }).catch(e => {
            this._processError(e)
            throw e
        }))
    }
    /**
     *
     * @returns {Promise<unknown[]>}
     */
    async waitResponses() {
        let resp = await axios.all(this._promises);
        this._promises = [];
        return resp;
    }
}