<template>
  <div>
    <ModalForm :loading="disable_form" :title="$t('edit_link')" :disabled-buttons="disable_form || conn_type === 'not_choosed'" ref="editModal" :modal-width="600"
               @save="modalSave"
    >
      <template v-slot:content>
        <form class="container-fluid" style="padding: 10px;  ">
          <div class="form-group row">
            <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{
                $t('link_connection_type')
              }}</label>
            <div class="col-md-8 col-sm-8">
              <v-select v-model="conn_type" class="style-chooser"
                        label="name"
                        :disabled="form.id !== null"
                        :clearable="false"
                        :reduce="e => e.key"
                        :options="options.types"></v-select>
            </div>
          </div>
          <hr>
          <div v-if="conn_type === 'uplink'">
            <div class="form-group row">
              <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{ $t('interface') }}</label>
              <div class="col-md-8 col-sm-8">
                <v-select v-model="form.dest_iface" class="style-chooser"
                          label="name"
                          :options="options.dest_interfaces"></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{$t('uplink_device') }}*</label>
              <div class="col-md-8 col-sm-8">
                <v-select v-model="form.src_device" class="style-chooser"
                          label="label"
                          :clearable="false"
                          :disabled="options.src_devices.length === 0"
                          :options="options.src_devices.filter(d => {return d.id !== device.id})"></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{$t('uplink_interface')}}</label>
              <div class="col-md-8 col-sm-8">
                <v-select v-model="form.src_iface" class="style-chooser"
                          label="name"
                          :disabled="options.src_interfaces.length === 0"
                          :options="options.src_interfaces"></v-select>
              </div>
            </div>
          </div>
          <div v-if="conn_type === 'downlink'">
            <div class="form-group row">
              <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{ $t('interface') }}</label>
              <div class="col-md-8 col-sm-8">
                <v-select v-model="form.src_iface" class="style-chooser"
                          label="name"
                          :options="options.src_interfaces"></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{$t('downlink_device') }}*</label>
              <div class="col-md-8 col-sm-8">
                <v-select v-model="form.dest_device" class="style-chooser"
                          label="label"
                          :clearable="false"
                          :disabled="options.dest_devices.length === 0"
                          :options="options.dest_devices.filter(d => {return d.id !== device.id})"></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-sm-4 text-right control-label col-form-label">{{$t('downlink_interface')}}</label>
              <div class="col-md-8 col-sm-8">
                <v-select v-model="form.dest_iface" class="style-chooser"
                          label="name"
                          :disabled="options.dest_interfaces.length === 0"
                          :options="options.dest_interfaces"></v-select>
              </div>
            </div>
          </div>
        </form>
      </template>
    </ModalForm>
    <Preloader v-if="loading" style="height: 150px"></Preloader>
    <div class="row" v-else :style="zeroPadding ? `margin: 0; padding: 0` : ''">
      <div class="col-mini col-12" :style="`overflow-y: auto; min-width: 380px; max-height: 900px; ` + (zeroPadding ? `margin: 0; padding: 0 ` : '') " >
        <table class="table table-sm table-striped table-bordered table-hover styled-table" v-if="links.length !== 0">
          <thead>
          <tr>
            <th>{{ $t('type') }}</th>
            <th>{{ $t('interface') }}</th>
            <th>{{ $t('device') }}</th>
            <th v-if="$auth.isPermitted('links_edit')">{{ $t('action') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="l in links" :key="l.id">
            <td>
              <b>{{ l.src_device.id === device.id ?  $t('downlink') : $t('uplink') }}</b>
              <br>
              <small>{{$t(`link_types.${l.source}`)}}</small>
            </td>
            <td v-if="l.src_device.id === device.id" v-html="l.src_iface ? l.src_iface.name : '<small>' + $t('not_setted') + '</small>'"></td>
            <td v-if="l.src_device.id !== device.id">{{ l.dest_iface ? l.dest_iface.name : $t('not_setted') }}</td>
            <td style="text-align: left" v-if="l.src_device.id === device.id">
              <router-link v-if="routesTo === 'management'" :to="{name: 'management_device_edit', params: {id: l.dest_device.id}}"><b>{{ l.dest_device.ip }}</b> - {{ l.dest_device.name }}</router-link>
              <router-link v-else :to="{name: 'device_dashboard', params: {id: l.dest_device.id}}"><b>{{ l.dest_device.ip }}</b> - {{ l.dest_device.name }}</router-link>
              <br>
              <span style="font-size: 85%">
                <router-link v-if="l.dest_iface" :to="{name: 'device_iface_dashboard', params: {id: l.dest_device.id, 'interface': l.dest_iface.bind_key}}">{{ l.dest_iface ? l.dest_iface.name : '' }}</router-link>
              </span>
            </td>
            <td style="text-align: left" v-if="l.src_device.id !== device.id">
              <router-link v-if="routesTo === 'management'" :to="{name: 'management_device_edit', params: {id: l.src_device.id}}"><b>{{ l.src_device.ip }}</b> - {{ l.src_device.name }}</router-link>
              <router-link v-else :to="{name: 'device_dashboard', params: {id: l.src_device.id}}"><b>{{ l.src_device.ip }}</b> - {{ l.src_device.name }}</router-link>
               <br>
              <span style="font-size: 85%">
                <router-link v-if="l.src_iface" :to="{name: 'device_iface_dashboard', params: {id: l.src_device.id, 'interface': l.src_iface.bind_key}}">{{ l.src_iface ? l.src_iface.name : '' }}</router-link>
              </span>
            </td>
            <td v-if="$auth.isPermitted('links_edit')">
              <button style="margin-left: 5px; margin-bottom: 3px" class="btn btn-sm btn-default" @click="modalEdit(l)"
                      :title="$t('edit_link')"><i class="mdi mdi-table-edit"></i></button>
              <button style="margin-left: 5px; margin-bottom: 3px" class="btn btn-sm btn-danger" @click="deleteLink(l)"
                      :title="$t('delete_link')"><i class="mdi mdi-trash-can"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
        <h4 v-else-if="this.options.src_interfaces.length === 0" style="text-align: center; margin-top: 10px"><i class="mdi mdi-link-variant-off"></i> {{ $t('links_not_added') }}</h4>
        <h4 v-else-if="links.length === 0" style="text-align: center; margin-top: 10px"><i class="mdi mdi-link-variant-off"></i> {{ $t('links_not_added') }}</h4>
      </div>
      <div class="col-mini col-12" v-if="$auth.isPermitted('links_edit')" style="; margin-left: 0; margin-right: 0">
        <button class="btn btn-default btn-sm" @click="modalAddLink">
          <i class="mdi mdi-plus"></i> {{ $t('add_link') }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import ModalForm from "@/components/ModalForm";

const API_PREFIX = '/component/links'

export default {
  components: {ModalForm, Preloader},
  props: {
    device: {
      type: Object,
      default: null,
    },
    interface: {
      type: Object,
      default: null,
    },
    routesTo: {
      type: String,
      default: 'management',
    },
    disableWatch: {
      type: Boolean,
      default: false,
    },
    zeroPadding: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: true,
      links: [],
      options: {
        types: [
          {key: 'not_choosed', name: this.$t('not_choosed')},
          {key: 'uplink', name: this.$t('uplink')},
          {key: 'downlink', name: this.$t('downlink')},
        ],
        src_devices: [],
        src_interfaces: [],
        dest_devices: [],
        dest_interfaces: [],
      },
      disable_form: false,
      form: {
        id: null,
        src_device: null,
        src_iface: null,
        dest_device: null,
        dest_iface: null,
        source: 'manual',
      },
      conn_type: 'not_choosed',
    }
  },
  mounted() {
    if (this.device) {
      this.load(this.device)
    }
  },
  watch: {
    device(n) {
      if (n !== null && !this.disableWatch) {
        this.load(n)
      }
    },
    'form.dest_device': {
      async handler(n, old) {
        if (!n) return false
        this.disable_form = true
        this.options.dest_interfaces = await this.getInterfacesByDevice(n)
        this.disable_form = false
      },
      deep: true,
    },
    'form.src_device': {
      async handler(n, old) {
        if (!n) return false
        this.disable_form = true
        this.options.src_interfaces = await this.getInterfacesByDevice(n)
        this.disable_form = false
      },
      deep: true,
    },
    conn_type: {
     async handler(n) {
        this.disable_form = true
         if(n === 'downlink') {
           console.log("Choosed type downlink")
           this.form.dest_device = null
           this.form.dest_iface = null
           if(this.interface) {
             this.form.src_iface = this.interface
           }
           this.form.src_device = Object.assign({}, this.device)
           this.options.dest_devices = await this.getDevicesList()
         } else if (n === 'uplink') {
           console.log("Choosed type uplink")
           this.form.src_device = null
           this.form.src_iface = null
           if(this.interface) {
              this.form.dest_iface = this.interface
           }
           this.form.dest_device =  Object.assign({}, this.device)
           this.options.src_devices = await this.getDevicesList()
         }
         this.disable_form = false
      },
    }
  },
  methods: {
    async load() {
      this.loading = true
      this.links = await this.loadLinks()
      this.loading = false
    },
    async loadLinks(withEnabledLoading = false) {
      let data = []
      if (withEnabledLoading) {
        this.loading = true
      }
      let url = `${API_PREFIX}/by-device/${this.device.id}`
      if(this.interface && this.interface.bind_key) {
          url = `${API_PREFIX}/by-interface/${this.interface.id}`
      }
      await this.$api.get(url).then(d => {
        d.data.forEach(link => {
          link.src_device.label = `${link.src_device.ip} - ${link.src_device.name}`
          link.dest_device.label = `${link.dest_device.ip} - ${link.dest_device.name}`
          data.push(link)
        })
        if (withEnabledLoading) {
          this.loading = false
        }
      }).catch(e => {
        if (withEnabledLoading) {
          this.loading = false
        }
      })
      return data
    },
    async updateLink(link, withEnabledLoading = false) {
      if (withEnabledLoading) {
        this.loading = true
      }
      await this.$api.put(`${API_PREFIX}/${link.id}`, link).then(l => {
        link = l.data
        if (withEnabledLoading) {
          this.loading = false
        }
      }).catch(e => {
        if (withEnabledLoading) {
          this.loading = false
        }
      })
      return link
    },
    async addLink(link, withEnabledLoading = false) {
      if (withEnabledLoading) {
        this.loading = true
      }
      await this.$api.post(`${API_PREFIX}`, link).then(l => {
        link = l.data
      }).catch(e => {
      })
      return link
    },
    async deleteLink(link, withEnabledLoading = false) {
      if (!confirm(this.$t('are_you_sure_delete_link'))) {
        return false
      }
      this.loading = true
      this.$api.delete(`${API_PREFIX}/${link.id}`).then(async r => {
        this.links = await this.loadLinks()
      })
      this.loading = false
    },

    async getDevicesList(withEnabledLoading = false) {
      let data = []
      if (withEnabledLoading) {
        this.loading = true
      }
      await this.$api.get(`${API_PREFIX}/options/devices`).then(d => {
        d.data.forEach(elem => {
          elem.label = `${elem.ip} - ${elem.name}`
          data.push(elem)
        })
        if (withEnabledLoading) {
          this.loading = false
        }
      }).catch(e => {
        if (withEnabledLoading) {
          this.loading = false
        }
      })
      return data
    },
    async getInterfacesByDevice(device, withEnabledLoading = false) {
      let data = []
      if (withEnabledLoading) {
        this.loading = true
      }
      await this.$api.get(`${API_PREFIX}/options/interfaces/${device.id}`).then(d => {
        data = d.data
        if (withEnabledLoading) {
          this.loading = false
        }
      }).catch(e => {
        if (withEnabledLoading) {
          this.loading = false
        }
      })
      return data
    },
    async modalSave() {
      if(!this.form.src_device) {
        this.$noty.warning(this.$t('choose_device_is_required'))
        return
      }
      if(!this.form.dest_device) {
        this.$noty.warning(this.$t('choose_device_is_required'))
        return
      }
      this.disable_form = true
      if (this.form.id) {
        await this.updateLink(this.form)
      } else {
        await this.addLink(this.form)
      }
      this.links = await this.loadLinks(this.device, true)
      this.disable_form = false
      this.loading = false
      this.$refs.editModal.hide()
    },
    async modalEdit(link) {
      this.conn_type = this.device.id === link.dest_device.id ? 'uplink' : 'downlink'
      this.$nextTick(() => {
        this.form = Object.assign({}, link)
      })
      this.disable_form = false
      this.$refs.editModal.show()
    },
    async modalAddLink() {
      this.conn_type = 'not_choosed'
      this.form = {
        id: null,
        src_device: null,
        src_iface: null,
        dest_device: null,
        dest_iface: null,
        source: 'manual',
      }
      this.$refs.editModal.show()
    },
  },
}
</script>