<template>
  <Transition name="slide-down">
    <div @click="hideClick" v-if="show"
         :style="`
         background-color: ${color};
         `"
         class="big-notify"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12" v-html="html"></div>
        </div>
      </div>
    </div>
  </Transition>
</template>


<script>
  export default {
      data() {
        return {
          show: false,
          html: '',
        }
      },
      props: {
         timeout: {
           type: Number,
           default: 10000,
         },
         hideOnClick: {
           type: Boolean,
           default: true,
         },
         color: {
           type: String,
           default: 'rgba(253,112,36,0.8)',
         },
      },
      methods: {
        hideClick() {
          if(this.hideOnClick) {
            this.show = false
          }
        },
        hide() {
          this.show = false
        },
        displayNoty(html = '') {
          this.html = html
          this.show = true
          if(this.timeout !== 0) {
            setTimeout(() => {
              this.show = false
            }, this.timeout)
          }
        }
      }
  }
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-down-enter-active, .slide-down-leave-active {
  transition: all .5s;
}

.slide-down-enter-to, .slide-down-leave {
  overflow: hidden;
  max-height: 100%;
}

.slide-down-enter, .slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}
.big-notify {
  position: fixed;
  bottom: 0px;
  right: 0;
  min-height: 70px;
  margin: 0;
  width: 100%;
  margin-left: -10px;
  padding-top: 10px;
  padding-bottom: 10px;

  z-index: 10;
  text-align: center;
  color: black;
}
</style>