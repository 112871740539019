export default {
    parameter_description: {
        EXTERNAL_HTTP_ADDRESS: 'Внешний адрес веб-панели NMS',
    },
    rebuild_required_description: `
          <span style="font-weight: bold; color: darkred">*</span>  Для применения этих параметров, необходимо перезагрузить систему командой<pre>cd "nms path"</pre>
    `,
    agent_is_disabled: "Агент выключен.",
    agent_disabled: "Агент отключен или нет активной подписки.",
    welcome_message: `
        <h3 class="text-info">Добро пожаловать в веб-панель <b>NMS</b>!</h3>
            <div>
              <h5 class="text-info">Оцените возможности диагностики и мониторинга оборудования</h5>
              <ol>
                <li>Добавьте свой первый доступ к оборудованию(community/login/password)
                  <a href="/management/device-access">здесь</a>
                </li>
                <li>Добавьте свое первое устроство
                  <a href="/management/device">здесь</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Настройте отправку уведомлений в Telegram или на электронную почту</h5>
              <ol>
                <li>Настройте способы отправки уведомлений -
                  <a href="/config/notifications">Настройки алертов</a>
                </li>
                <li>Добавьте свои контакты для получения уведомлений -
                  <a href="/account/settings">Настройки аккаунта</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Предоставьте доступ к NMS коллегам</h5>
              <ol>
                <li>Создайте новую группу пользователей, предоставим необходимые им доступы -
                  <a href="/management/user-role">Группы</a>
                </li>
                <li>Добавьте новых пользователей -
                  <a href="/management/user">Пользователи</a>
                </li>
              </ol>
            </div>     
    `,
    notifications: {
        contact_telegram_message: ` 
            <div style="margin-top: 10px">
            Вы можете узнать telegram chat id через бот - <a href="#" target="_blank">@bot</a>. Бот вернет ваш id чата, введите его здесь<br>
            <div style="text-align: center; font-weight: bold">ИЛИ</div>
            <div  style="">
                <li>Добавьте контакт "номер телефона для телеграм"</li>
                <li>Перейти к своему боту - <a href="{url}" target="_blank">@{name}</a> и отправить команду /start. Ваш чат будет добавлен автоматически</li>
            </div>
            </div>
        `,
    }

}