export default class Auth {
    /**
     * @private ServerApi
     */
    _http
    _auth
    constructor(http) {
        this._http = http
        let auth =  JSON.parse(localStorage.getItem('auth'))
        if(!auth) {
            this._auth = this.getEmpty()
        } else {
            this._auth = auth
        }
    }
    isLogged() {
        if(this._auth.key !== null) return true;
    }
    _setAuth(auth) {
        this._auth = auth
        localStorage.setItem('auth', JSON.stringify(auth))
    }
    login(login, password, twofaPin=null) {
        return this._http.login(login, password, twofaPin).then(r => {
            this._setAuth(r.data.data)
            return r.data.data
        })
    }
    updateSettings(settings) {
        return this._http.put('/user/' + this._auth.user.id, {settings: settings}).then(r => {
            this._auth.user = r.data.data
            return r.data.data.settings
        })
    }
    getAuthKey() {
        return this._auth.key
    }
    getUser() {
        return this._auth.user
    }
    updateUser(user) {
        this._auth.user = user
        localStorage.setItem('auth', JSON.stringify(this._auth))
        return this._auth.user
    }
    getPermissions() {
        return this._auth.user.role.permissions
    }
    getSettings() {
        return this._auth.user.settings
    }
    isPermitted(permissionName) {
           return this._auth.user.role.permissions.indexOf(permissionName) !== -1
    }
    logout() {
        this._http.delete('/logout').then(() => {
        }).catch()
        this._setAuth(this.getEmpty())
    }

    getEmpty() {
        return JSON.parse(JSON.stringify({
            key: null,
            settings: {
            },
            user: {
                id: null,
                name: null,
                login: null,
                language: 'en',
                role: {
                    id: null,
                    name: '',
                    permissions: [],
                }
            }
        }))
    }
}
