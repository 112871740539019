<template>
  <div>
    <div class="page-wrapper" v-if="!$route.query._iframe || $route.query._iframe !== 'yes'" style="min-height: 1000px">
      <div style="">
        <PageBreadCrumb v-if="showBreadcrumb"/>
        <router-view/>
      </div>
      <Footer/>
    </div>
    <div v-else style="padding-top: 10px; padding-bottom: 10px">
      <router-view/>
    </div>
  </div>
</template>


<script>
import PageBreadCrumb from "./PageWrapper/PageBreadCrumb";
import Footer from "./PageWrapper/Footer";

export default {
  components: {Footer, PageBreadCrumb},
  data() {
    return {
      showBreadcrumb: true,
    }
  },
  watch: {
    '$route': {
      handler(n) {
        if (n.meta && n.meta.no_breadcrumb) {
          this.showBreadcrumb = false
        } else {
          this.showBreadcrumb = true
        }
      },
      deep: true,
    }
  },
  mounted() {
    if (this.$route.meta && this.$route.meta.no_breadcrumb) {
      this.showBreadcrumb = false
    } else {
      this.showBreadcrumb = true
    }
  }
}
</script>