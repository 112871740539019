<template>
  <ul class="navbar-nav float-left" style="flex-grow: 1">
    <li class="nav-item d-none d-md-block"><a class="nav-link sidebartoggler waves-effect waves-light"
                                              href="javascript:void(0)" data-sidebartype="mini-sidebar"><i
        class="mdi mdi-menu font-24"></i></a></li>
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown">
      <!--
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="d-none d-md-block">Create New <i class="fa fa-angle-down"></i></span>
        <span class="d-block d-md-none"><i class="fa fa-plus"></i></span>
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">Something else here</a>
      </div>
      -->
    </li>
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown float-lg-left" style="flex: auto">
      <Searching></Searching>
    </li>
  </ul>
</template>

<script>
import Searching from "@/components/Main/TopBar/Searching.vue";
export default {
  components: {Searching}
}
</script>