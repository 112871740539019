<template>
  <div class="row">
    <div class="col-12 col-mini">
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-md-12" v-html="$t('macros.parameters_descriptions')">

        </div>
        <div class="col-sm-12 col-lg-3 col-xl-3 col-md-12 col-mini">
          <div class="form-group">
            <label class="mb-0"> &nbsp;</label>
            <button @click="addNewParameter()" class="btn btn-default btn-block" style="vertical-align: top;  ">
              {{ $t('macros.add_parameter') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-mini">
      <draggable v-model="parameters" item-key="order" handle=".handle">
        <div class="row" v-for="(param, num) in parameters" :key="num" style="border: 1px dotted gray; margin-top: 5px">
          <div class="col-11" style="padding: 15px">
            <div class="row">
              <div class="col-12 col-mini col-sm-6 col-lg-3 col-xl-2 col-md-6">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.key') }}</label>
                  <input name="" v-model="param.key" class="form-control">
                  <small>{{ $t('macros.key_reg_description') }}</small>
                </div>
              </div>
              <div class="col-12 col-mini col-sm-6 col-lg-3 col-xl-3 col-md-6">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.visible_name') }}</label>
                  <input name="" v-model="param.name" class="form-control">
                </div>
              </div>
              <div class="col-sm-3 col-lg-2 col-xl-2 col-md-3">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.is_required') }}</label>
                  <input type="checkbox" v-model="param.required" class="form-control"
                         style="margin: 2px; width: 28px; height: 28px; border: 1px solid gray">
                </div>
              </div>
              <div class="col-sm-9 col-lg-4 col-xl-3 col-md-6">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.parameter_type') }}</label>
                  <v-select class="style-chooser" v-model="param.type" :reduce="sel => sel.key" label="name"
                            :options="parameterProperties.types"></v-select>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'input_string'">
                <div>
                  <div class="form-group">
                    <label class="mb-0">{{ $t('macros.default_value') }}</label>
                    <input class="form-control" v-model="param.default_value"
                           :placeholder="$t('macros.parameter_can_be_empty')">
                  </div>
                  <small v-html="$t('macros.input_string_description')"></small>
                </div>
                <div>
                  <div class="form-group">
                    <label class="mb-0">{{ $t('macros.regular_expr') }}</label>
                    <input class="form-control" v-model="param.regular_expr"
                           :placeholder="'^[0-9]{1,14}$'">
                  </div>
                  <small v-html="$t('macros.input_regular_expression')"></small>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'input_variable'">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.source_param_key') }}</label>
                  <v-select class="style-chooser" :create-option="key => ({ key: key, type: 'string' })"
                            v-model="param.source_parameter_key" label="key" :reduce="sel => sel.key"
                            :options="getVariableObjectAsArray(variables).filter(e => {return e.type === 'string' || e.type === 'number' })"></v-select>
                </div>
                <div>
                  <div class="form-group">
                    <label class="mb-0">{{ $t('macros.regular_expr') }}</label>
                    <input class="form-control" v-model="param.regular_expr"
                           :placeholder="'^[0-9]{1,14}$'">
                  </div>
                  <small v-html="$t('macros.input_regular_expression')"></small>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'select_from_variable'">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.source_param_key') }}</label>
                  <v-select class="style-chooser" :create-option="key => ({ key: key, type: 'array' })"
                            v-model="param.source_parameter_key" label="key" :reduce="sel => sel.key"
                            :options="getVariableObjectAsArray(variables).filter(e => {return e.type === 'array'})"></v-select>
                </div>
                <div style="overflow: auto; max-height: 200px"
                     v-if="param.source_parameter_key !== '' && getVariableObjectAsArray(variables).filter(e => {return e.key === param.source_parameter_key}).length !== 0">
                  <label class="mb-0">{{ $t('macros.variants') }}</label>
                  <li v-for="(name, key) in getVariableObjectAsArray(variables).filter(e => {return e.key === param.source_parameter_key})[0].value"
                      :key="key">
                    {{ name }}
                  </li>
                </div>
                <small v-html="$t('macros.select_from_variable_description')"></small>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'select_from_predefined'">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.select_from_variables_list') }}</label>
                  <textarea v-model="param.variants_list" class="form-control" style="min-height: 200px"></textarea>
                </div>
                <small v-html="$t('macros.select_from_predefined_description')"></small>
              </div>

              <div class="col-sm-6 col-lg-4 col-xl-5 col-md-6">
                <div class="form-group">
                  <label class="mb-0">{{ $t('macros.visible_if') }}</label>
                  <textarea name="" v-model="param.visible_if" class="form-control"></textarea>
                  <small>{{ $t('macros.visible_if_description') }}</small>
                  <div v-if="param.type === 'select_from_variable'">
                    <label class="mb-0">{{ $t('macros.item_name') }}</label>
                    <input name="" v-model="param.item_name" class="form-control">
                    <small>{{ $t('macros.item_name_description') }}</small>
                  </div>
                  <div v-if="param.type === 'select_from_variable'">
                    <label class="mb-0">{{ $t('macros.item_filter') }}</label>
                    <input name="" v-model="param.item_filter" class="form-control">
                    <small>{{ $t('macros.item_filter_description') }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-mini col-lg-1 col-xl-1 col-md-1 " style="text-align: right">
            <button class="btn btn-outline-info" style="margin: 5px"><i class="fa fa-align-justify handle"> </i></button>
            <button class="btn btn-danger" @click="deleteParameterByIndex(num)" style="margin: 5px"><i
                class="fa fa-trash"></i></button>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>


import draggable from "vuedraggable";

export default {
  components: {draggable},
  props: {
    value: {
      type: Array,
    },
    variables: {
      type: Object
    }
  },
  data() {
    return {
      parameters: this.value,
      parameterProperties: {
        types: [
          {key: 'select_from_predefined', name: this.$t('macros.select_from_predefined')},
          {key: 'select_from_variable', name: this.$t('macros.select_from_variable')},
          {key: 'input_variable', name: this.$t('macros.input_variable')},
          {key: 'input_string', name: this.$t('macros.input_string')},
        ],
      }
    }
  },
  watch: {
    parameters: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        this.parameters = newVal
      },
      deep: true,
    },
  },
  methods: {
    addNewParameter() {
      this.parameters.push({
        visible_if: '',
        item_name: '',
        item_filter: '',
        order: this.parameters.length + 1,
        key: '',
        name: '',
        required: false,
        type: 'input_variable',
        value: '',
        variants_list: '',
        default_value: '',
        source_parameter_key: '',
        regular_expr: '',
      })
    },
    deleteParameterByIndex(index) {
      if (confirm(this.$t('macros.are_you_sure_to_delete'))) {
        this.parameters = this.parameters.filter((v, k) => {
          return k !== index
        })
        this.$noty.info(this.$t('macros.element_success_deleted'))
      }
    },
    getVariableObjectAsArray(object) {
      let fieldBuilder = function (object, rootKey = '') {
        var elements = []
        if (typeof object === 'object' && object !== null && !Array.isArray(object)) {
          for (const [key, value] of Object.entries(object)) {
            let keys = rootKey + '.' + key
            fieldBuilder(value, keys).forEach(e => {
              elements.push(e)
            })
          }
        } else {
          elements.push({
            key: rootKey,
            value: object
          })
        }
        return elements
      }

      return fieldBuilder(object).map(e => {
        let type = typeof e.value
        if (Array.isArray(e.value)) {
          type = 'array'
        }
        return {
          key: e.key.substring(1),
          value: e.value,
          type: type
        }
      })
    },
  }
}
</script>