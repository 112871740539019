export default {
    parameter_description: {
        PROXY_ENABLED: 'If NMS proxied over proxy, enable this flag',
    },
    open: "Open",
    agent_is_disabled: "Agent is disabled.",
    agent_disabled: "No active subscription/agent is disabled.",
    welcome_message: `
        <h3 class="text-info">Welcome to web panel <b>NMS</b>!</h3>
            <div>
              <h5 class="text-info">Evaluate the ability to diagnose and monitor equipment</h5>
              <ol>
                <li>Add your first hardware access (community/login/password) <a href="/management/device-access">here</a>
                </li>
                <li>Add your first device  <a href="/management/device">here</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Set up notifications to Telegram or email</h5>
              <ol>
                <li>Customize how notifications are sent - <a href="/config/notifications">Notifications settings</a>
                </li>
                <li>Add your contacts to receive notifications  -  <a href="/account/settings">Account settings</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Grant access to NMS to colleagues</h5>
              <ol>
                <li>Create a new users group, give them the access they need - <a href="/management/user-group">Groups</a>
                </li>
                <li>Add new users - <a href="/management/user">Users</a>
                </li>
              </ol>
            </div>    
    `,
    notifications: {
        contact_telegram_message: ` 
            <div style="margin-top: 10px">
            You can get chat telegram ID over bot <a href="#" target="_blank">@bot</a> and fill telegram chat id here<br>
            <div style="text-align: center; font-weight: bold">OR</div>
            <div  style="">
                <li>Add your phone for telegram</li>
                <li>Go to you own bot - <a href="{url}" target="_blank">@{name}</a> and send command /start. You chat ID will be added automaticaly</li>
            </div>
            </div>
        `,
    }

}