export default {
    ua: {
        "tooltips": {
            "placeMarker": "Натисніть, щоб додати бокс",
        },
        "buttonTitles": {
            "drawMarkerButton": "Додати бокс",
            "drawLineButton": "Додати лiнк",
        }
    },
    en: {
        "tooltips": {
            "placeMarker": "Click for add box",
        },
        "buttonTitles": {
            "drawMarkerButton": "Add box",
            "drawLineButton": "Add link",
        }
    },
    ru: {
        "tooltips": {
            "placeMarker": "Нажмите, что бы добавить бокс",
        },
        "buttonTitles": {
            "drawMarkerButton": "Добавить бокс",
            "drawLineButton": "Добавить линк",
        }
    },
}