<template>
  <div  v-if="macro !== null">
    <div class="row">
      <div class="col-12 col-mini col-sm-12 col-lg-6 col-xl-6 col-md-12">
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.name') }}</label>
          <div class="col-sm-9">
            <input v-model="macro.name" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.description') }}</label>
          <div class="col-sm-9">
            <input v-model="macro.description" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.roles') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      :disabled="getAllowedActions().indexOf('change_role') === -1"
                      v-model="macro.user_roles"
                      label="name"
                      :clearable="false"
                      multiple
                      return-object
                      single-line
                      :options="formOptions.roles.filter(e => {return macro.user_roles.filter(s => e.id === s.id).length === 0})"
                      :close-on-select="false"
            ></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.model_vendor') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      v-model="choosed_model_vendors"
                      :clearable="true"
                      multiple
                      return-object
                      single-line
                      :options="modelVendors.filter(e => { return !choosed_model_vendors.includes(e)})"
                      :close-on-select="false"
            ></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.models') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      v-model="macro.models"
                      label="name"
                      :clearable="true"
                      multiple
                      return-object
                      single-line
                      :disabled="choosed_model_vendors.length === 0"
                      :options="formOptions.models.filter(e => {return macro.models.filter(s => e.id === s.id).length === 0 && choosed_model_vendors.filter(s => s == e.vendor).length !== 0 })"
                      :close-on-select="false"
            ></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.display_for') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      v-model="macro.display_for"
                      label="name"
                      :clearable="false"
                      :reduce="k => k.key"
                      multiple
                      :options="displays"
                      :selectable="option => !macro.display_for.includes(option.key)"
                      :close-on-select="false"
            ></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 text-right control-label col-form-label">{{ $t('macros.display_output') }}</label>
          <div class="col-sm-9">
            <v-select class="style-chooser"
                      label="name"
                      :reduce="k => k.key"
                      v-model="macro.display_output"
                      :selectable="option => !macro.display_output.includes(option.key)"
                      :options="displays_output"
                      :clearable="false"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    formOptions: {
      type: Object,
      required: false,
      default: null,
    }
  },
  computed: {
    modelVendors() {
      let vendors = {}
      for (const [key, value] of Object.entries(this.formOptions.models)) {
         vendors[value.vendor] = value.vendor
      }
      let vendorKeys = []
      for (const [key, value] of Object.entries(vendors)) {
        vendorKeys.push(value)
      }

      return vendorKeys.sort()
    },
  },
  data() {
    return {
      macro: null,
      choosed_model_vendors: [],
      displays: [
        {key: 'DEVICE', name: this.$t('macros.display_for_options.DEVICE')},
        {key: 'PORT', name: this.$t('macros.display_for_options.PORT')},
        {key: 'PON', name: this.$t('macros.display_for_options.PON')},
        {key: 'ONU', name: this.$t('macros.display_for_options.ONU')},
      ],
      displays_output: [
        {key: 'no', name: this.$t('macros.display_output_options.no')},
        {key: 'all', name: this.$t('macros.display_output_options.all')},
        {key: 'last', name: this.$t('macros.display_output_options.last')},
      ],
      loading: true,
      error: '',
    }
  },
  watch: {
    choosed_model_vendors: {
      handler(n) {
        let models = []
        this.macro.models.forEach((model, id) => {
          if(n.includes(model.vendor)) {
              models.push(model)
          }
        })
        this.macro.models = models
      }
    }
  },
  async mounted() {
    this.macro = this.item
    this.choosed_model_vendors = []

    let vendors = {}
    console.log(this.macro)
    for (const [key, value] of Object.entries(this.macro.models)) {
      vendors[value.vendor] = value.vendor
    }
    for (const [key, value] of Object.entries(vendors)) {
      this.choosed_model_vendors.push(value)
    }
    console.log(this.choosed_model_vendors)
  },
  methods: {
    getAllowedActions() {
      if (this.$auth.getPermissions().indexOf('user_management') !== -1) {
        return ['update_name', 'update_password', 'update_login', 'close_session', 'change_role', 'change_group', 'change_status']
      }
      if (this.$auth.getPermissions().indexOf('user_self_control') !== -1) {
        return ['update_name', 'update_password']
      }
      return []
    }
  }
};
</script>
