<template>
  <div class="" style="background-color: #000000; background-image: url(/assets/images/login_background.jpg); background-size: 100%; background-repeat: no-repeat;">
    <link href="/dist/css/style.min.css" rel="stylesheet">

    <div style="margin-top: 0 !important;" class="auth-wrapper d-flex  justify-content-center align-items-center">
      <div class="auth-box " style="background-color: #343a40">
        <div>
          <div class="text-center p-t-20 p-b-20" style="font-size: 24pt; color: #FAFAFA;">
            <img style="text-align: center; width: 64px; height: 64px;" src="/assets/images/logo.png"><br>
            {{$config.name}}
          </div>
          <div class="text-center p-t-20 p-b-20" style="font-size: 18pt; color: #f35e5e;" v-if="error === 'agent_disabled'">
            {{$t('agent_disabled')}}
          </div>
          <div class="text-center p-t-20 p-b-20" style="font-size: 18pt; color: #f35e5e;" v-else-if="error !== ''">
            {{error}}
          </div>

          <!-- Form -->
          <form class="form-horizontal m-t-20" style="margin-top: 10px; margin-bottom: 10px" id="loginform" @submit.prevent="$emit('submit')">
            <div class="row p-b-30" v-if="!twoFa">
              <div class="col-12 col-mini">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white" id="basic-addon1"><i class="ti-user"></i></span>
                  </div>
                  <input :disabled="inProccess" type="text"
                         :value="login"
                         @input="$emit('update:login', $event.target.value)"
                         class="form-control form-control-lg" :placeholder="$t('username')" aria-label="Username" aria-describedby="basic-addon1" required="">
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-warning text-white" id="basic-addon2"><i class="ti-pencil"></i></span>
                  </div>
                  <input :disabled="inProccess" type="password"
                         :value="password"
                         @input="$emit('update:password', $event.target.value)"
                         class="form-control form-control-lg" :placeholder="$t('password')" aria-label="Password" aria-describedby="basic-addon1" required="">
                </div>
              </div>
            </div>

            <div class="row p-b-30" v-if="twoFa">
              <div class="col-2 col-mini" >
                <img src="/assets/images/google_auth.png" style="width: 50px; height: 50px; margin-bottom: 8px;">
              </div>
              <div class="col-10 col-mini" style="font-size: 12pt; color: #FAFAFA;">
                <div style="margin-bottom: 5px">
                {{$t('google_twofa_pin_instruction')}}
                  </div>
              </div>
              <div class="col-12 col-mini">
                <div class="input-group mb-3">
                  <input :disabled="inProccess" type="text"
                         :value="twofa_pin"
                         @input="$emit('update:twofa_pin', $event.target.value)"
                         class="form-control form-control-lg" :placeholder="$t('twofa_pin')" aria-label="twofa_pin" aria-describedby="basic-addon1" required="">
                </div>
              </div>
            </div>

            <div class="row border-secondary">
              <div class="col-12 col-mini">
                <div class="form-group">
                  <div class="p-t-10">
                    <!-- <button class="btn btn-info" id="to-recover" type="button"><i class="fa fa-lock m-r-5"></i> Lost password?</button> -->
                    <button class="btn btn-success float-right" :disabled="inProccess" type="submit" >{{$t('sign_in')}}  <i style="padding-left: 5px;" :class="'mdi ' + (inProccess ? ' mdi-spin mdi-loading ' : 'mdi-login')"></i> </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['inProccess', 'message', 'login', 'password', 'error', 'twoFa', 'twofa_pin'],
  emits: ['update:login', 'update:password', 'update:twofa_pin', 'submit'],
}
</script>