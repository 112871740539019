<template>
  <header class="topbar" data-navbarbg="skin5">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark" v-click-outside="hideBlock" >
      <div class="navbar-header" data-logobg="skin5">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)" @click="hideBlock"><i  id="ti-menu-toggler" class="ti-menu ti-close"></i></a>

        <NavBarBrand />
        <a class="topbartoggler d-block d-md-none waves-effect waves-light"
           href="javascript:void(0)"
           @click="togleBlock" style="margin-bottom: -3px"
        ><i class="ti-search"></i><br>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div :class="navBarClasses" id="navbarSupportedContent" >
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <LeftNavBar />
        <!-- ============================================================== -->
        <!-- Right side toggle and nav items -->
        <!-- ============================================================== -->
        <RightNavBar />
      </div>
    </nav>
  </header>
</template>

<script>
import LeftNavBar from "./TopBar/LeftNavBar";
import RightNavBar from "./TopBar/RightNavBar";
import NavBarBrand from "./TopBar/NavBarBrand";

export default {
   data() {
     return {
       navBarClasses: 'navbar-collapse collapse',
       isActive: false,
     }
   },
   watch: {
     "$route": {
        handler() {
          this.hideBlock()
        } ,
       deep: true
     }
   },
   methods: {
     hideBlock() {
       this.navBarClasses = 'navbar-collapse collapse active'
       this.isActive = false
     },
     showBlock() {
       this.navBarClasses = 'navbar-collapse collapse active show'
       this.isActive = true
     },
     togleBlock() {
       if(this.isActive) {
         this.hideBlock()
       } else {
         this.showBlock()
       }
     }
   },
   components: {NavBarBrand, RightNavBar, LeftNavBar}
 }
</script>
