<template>
  <keep-alive include="device_list">
  <router-view />
  </keep-alive>
</template>


<script>
export default {
  mounted() {
    if (typeof this.$route.query.ip !== 'undefined') {
      this.$api.get("/device?ip=" + this.$route.query.ip).then(r => {
        this.$router.push({name: 'device_dashboard', params: {id: r.data.id}})
      }).catch(e => {
      })
    }
  }
}
</script>
