<template>
  <div>
    <ModalForm ref="modal" @save="updateModalForm" :title="modalName" :disabled-buttons="disabledModalButtons">
      <template v-slot:content>
        <div style="padding: 10px">
          <div class="row">
            <div class="col-sm-4 "><label class="mb-0">{{ $t('type') }}</label></div>
            <div class="col-sm-8">
              <v-select :disabled="contact.id !== 0" v-model="contact.type" label="name" :reduce="k => k.key"
                        class="style-chooser"
                        :clearable="false" :options="types"></v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div v-for="s in types" v-show="s.key === contact.type && typeof channels[s.channel] === 'undefined'"
                   :key="s.key"
                   style="font-weight: bold; color: darkred; padding-top: 10px; padding-bottom: 5px">
                {{ $t(`notifications.channels_not_configured.${s.channel}`) }}
              </div>
            </div>
          </div>
          <div v-if="contact.type === 'TELEGRAM_ID'">
            <div >
              <div class="row">
                <div class="col-sm-4 add-padding-top">
                  <label class="mb-0">{{ $t(`notifications.types.telegram_chat_id`) }}</label>
                </div>
                <div class="col-sm-8 add-padding-top">
                  <input v-model="contact.value" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 add-padding-top">
                  <label class="mb-0">{{ $t(`description`) }}</label>
                </div>
                <div class="col-sm-8 add-padding-top">
                  <input v-model="contact.description" class="form-control" placeholder="">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 add-padding-top">
                  <label class="mb-0">{{ $t(`enabled`) }}</label>
                </div>
                <div class="col-sm-8 add-padding-top">
                  <label class="switch-small mb-0" title="Enabled" style="margin-top: 5px; margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                    <input type="checkbox"  v-model="contact.enabled" >
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="typeof channels.telegram !== 'undefined'">
              <div class="row" v-if="contact.value === ''">
                <div class="col-sm-12" v-html="$t('notifications.contact_telegram_message', {
                name: channels.telegram.bot_username,
                url: channels.telegram.url,
              })"></div>
              </div>
            </div>
          </div>
          <div v-if="contact.type === 'PHONE'">
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label class="mb-0">{{ $t(`notifications.types.${contact.type}`) }}<sup>*</sup></label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <input v-model="contact.value" class="form-control" placeholder="+38063000000">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label class="mb-0">{{ $t(`description`) }}</label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <input v-model="contact.description" class="form-control" placeholder="">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label class="mb-0">{{ $t(`enabled`) }}</label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                  <input type="checkbox"  v-model="contact.enabled" >
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="contact.type === 'PHONE_FOR_TELEGRAM'">
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label>{{ $t(`notifications.types.${contact.type}`) }}<sup>*</sup></label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <input v-model="contact.value" class="form-control" placeholder="+38063000000">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label>{{ $t(`enabled`) }}</label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                  <input type="checkbox"  v-model="contact.enabled" >
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12" v-html="$t('notifications.phone_for_telegram_descriptions')">

              </div>
            </div>
          </div>
          <div v-if="contact.type === 'EMAIL'">
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label>{{ $t(`notifications.types.${contact.type}`) }}<sup>*</sup></label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <input v-model="contact.value" class="form-control" placeholder="example@example.com">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label>{{ $t(`description`) }}</label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <input v-model="contact.description" class="form-control" placeholder="">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-top">
                <label>{{ $t(`enabled`) }}</label>
              </div>
              <div class="col-sm-8 add-padding-top">
                <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                  <input type="checkbox"  v-model="contact.enabled" >
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="contact.type !== '' && contact.value !== '' && contact.type !== 'PHONE_FOR_TELEGRAM' ">
            <hr>
            <div class="row">
              <div class="col-sm-4 add-padding-bottom"><label>{{ $t('severities') }}</label></div>
              <div class="col-sm-8 add-padding-bottom">
                <v-select multiple v-model="choosedSeverities" label="name" class="style-chooser"
                          :options="severitiesList" :hide-selected="true" :clearable="false" :close-on-select="false"
                ></v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-bottom"><label>{{ $t('ignore_events') }}</label></div>
              <div class="col-sm-8 add-padding-bottom">
                <v-select multiple v-model="contact.params.ignore_events" :clearable="true" taggable push-tags class="style-chooser"
                          :options="possibleEventsFilter" :close-on-select="false"
                ></v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 add-padding-bottom"><label>{{ $t('ignore_actions') }}</label></div>
              <div class="col-sm-8 add-padding-bottom">
                <v-select multiple v-model="contact.params.ignore_actions" :clearable="true" taggable push-tags class="style-chooser"
                          :options="possibleActionsFilter" :close-on-select="false"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalForm>
    <Preloader v-if="loading"></Preloader>
    <div v-else-if="channels.length === 0">
      <br>
      <h3 align="center" v-html="$t('configured_sources_not_found')"></h3>
      <br>
    </div>
    <div v-else>
      <div v-for="d in data" :key="d.id" class="row">
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <b>{{ $t(`notifications.types.${d.type}`) }}</b>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <input :value="d.value" readonly class="form-control">
        </div>
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <div class="row">
            <div class="col-6">
              <button class="btn btn-default btn-block" @click="editContact(d)"><i class="mdi mdi-message-draw"></i>
              </button>
            </div>
            <div class="col-6">
              <button @click="deleteContact(d)" class="btn btn-default btn-block"><i class="mdi mdi-delete"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <small v-if="d.description">{{ d.description }}</small>
          <hr style="margin-top: 3px">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-4">
          <!--<button @click="updateContacts" class="btn btn-default btn-block"><i class="mdi mdi-content-save-cog"> {{$t('save_contacts')}}</i></button>-->
        </div>
        <div class="col-2 col-sm-2"></div>
        <div class="col-sm-6 col-6">
          <button @click="addContact" class="btn btn-default btn-block">
            <i class="mdi mdi-plus"> {{ $t('add_contact') }}</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import ModalForm from "@/components/ModalForm";

export default {
  props: {
    userId: {
      type: [String, Number],
      default: 0,
    }
  },
  components: {ModalForm, Preloader},
  data() {
    return {
      possibleEvents: [],
      possibleActions: [],
      severities: [],
      choosedSeverities: [],
      types: [],
      modalErrors: [],
      contact: {
        "type": "",
        "value": "",
        "enabled": 1,
        "description": "",
        "params": {
          "severities": [
            "NOTIFICATION",
            "INFO",
            "WARNING",
            "CRITICAL"
          ],
          "ignore_actions": [],
          "ignore_events": [],
          "send_only_by_devices": false
        },
        "id": 0
      },
      channels: {},
      data: [],
      loading: true,
    }
  },
  watch: {
    choosedSeverities: {
      handler() {
        this.contact.params.severities = []
        this.choosedSeverities.forEach(r => {
          this.contact.params.severities.push(r.key)
        })
      },
    },
  },
  computed: {
    disabledModalButtons() {
      return false
    },
    possibleEventsFilter() {
      return this.possibleEvents.filter(r => {
        return this.contact.params.ignore_events.indexOf(r) === -1
      })
    },
    possibleActionsFilter() {
      return this.possibleActions.filter(r => {
        return this.contact.params.ignore_actions.indexOf(r) === -1
      })
    },
    severitiesList() {
      return this.severities.filter(r => {
        return this.choosedSeverities.indexOf(r) === -1
      })
    },
    modalName() {
      if (this.contact === null) return ''
      if (this.contact.id === 0) return this.$t('add_contact')
      return this.$t('edit_contact')
    },
  },
  async mounted() {

    this.loading = true
    this.types = [
      {channel: 'email', key: 'EMAIL', name: this.$t(`notifications.types.EMAIL`)},
  //    {channel: 'phone', key: 'PHONE', name: this.$t(`notifications.types.PHONE`)},
      {channel: 'telegram', key: 'PHONE_FOR_TELEGRAM', name: this.$t(`notifications.types.PHONE_FOR_TELEGRAM`)},
      {channel: 'telegram', key: 'TELEGRAM_ID', name: this.$t(`notifications.types.TELEGRAM_ID`)},
    ]
    this.severities = [
      {key: 'NOTIFICATION', name: this.$t(`notifications.severities.NOTIFICATION`)},
      {key: 'INFO', name: this.$t(`notifications.severities.INFO`)},
      {key: 'WARNING', name: this.$t(`notifications.severities.WARNING`)},
      {key: 'CRITICAL', name: this.$t(`notifications.severities.CRITICAL`)},
    ]
    this.$api.enableSupportWaiting()
    this.loadChannels()
    this.loadContacts()
    this.loadPossibleActions()
    this.loadPossibleEvents()
    await this.$api.waitResponses()
    this.loading = false
  },
  methods: {
    updateModalForm() {
      if (this.contact.id === 0) {
        this.data.push(this.contact)
      } else {
        this.data.forEach(d => {
          if (d.id === this.contact.id) {
            d = this.contact
          }
        })
      }
      this.updateContacts().then(() => {
        this.$refs.modal.hide()
      })
    },
    addContact() {
      this.contact = {
        "user": {
          "id": 1,
        },
        "type": "PHONE_FOR_TELEGRAM",
        "value": "",
        "enabled": 1,
        "description": "",
        "params": {
          "severities": [
            "NOTIFICATION",
            "INFO",
            "WARNING",
            "CRITICAL"
          ],
          "ignore_actions": [],
          "ignore_events": [],
          "send_only_by_devices": false
        },
        "id": 0
      }
      this.$refs.modal.show()
    },
    editContact(contact) {
      this.contact = contact
      this.$refs.modal.show()
    },


    deleteContact(contact) {
      if (!confirm(this.$t('are_you_sure_delete_contact'))) return
      let data = []
      this.data.forEach(d => {
        if (d.id !== contact.id) {
          data.push(d)
        }
      })
      this.data = data
      this.updateContacts()
    },
    updateContacts() {
      this.loading = true
      return this.$api.put("/component/notifications/contacts/by-user/" + this.userId, this.data).then(r => {
        this.data = r.data
        return r
      }).catch((e) => {
        this.loadContacts(true)
        return e
      }).finally(() => {
        this.loading = false
      })
    },
    async loadChannels() {
      await this.$api.get("/component/notifications/configured-channels").then(r => {
        this.channels = r.data
        console.log(this.channels)
      })
    },
    async loadPossibleActions() {
      await this.$api.get('/component/notifications/config/names/actions').then(r => {
        this.possibleActions = r.data
      })
    },
    async loadPossibleEvents() {
      await this.$api.get('/component/notifications/config/names/events').then(r => {
        this.possibleEvents = r.data
      })
    },
    async loadContacts(withDisplayLoad = false) {
      if (withDisplayLoad) {
        this.loading = true
      }
      await this.$api.get("/component/notifications/contacts/by-user/" + this.userId).then(r => {
        this.data = r.data
        this.choosedSeverities = []
        this.severities.forEach(s => {
          this.choosedSeverities.push(s)
        })
        if (withDisplayLoad) {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.add-padding-bottom {
  padding-bottom: 5px;
}

.add-padding-top {
  padding-top: 10px;
}

label {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}
</style>
