<template>
  <div class="container-fluid">
  <div class="row">
    <div class="col-12 col-mini col-sm-12" style="">
      <!-- Nav tabs -->
      <div class="row">
        <div class="col-10 col-sm-10">
          <ul class="nav nav-tabs" role="tablist" style=" margin-bottom: -1px; z-index: 20;  ">
            <li class="nav-item" v-for="(_, tab) in tabs" :key="tab">
              <a @click=setTab(tab) :class="'nav-link ' + (choosedTab === tab ? 'active' : '')" data-toggle="tab" :href="'#' + tab"
                 role="tab">
                <span class="hidden-sm-up"></span>
                <span class="hidden-xs-down">{{ $t(`box_types.tabs.${tab}`) }}</span>
              </a>
            </li>
          </ul>
        </div>

      </div>
      <!-- Tab panes -->
      <div class="tab-content tabcontent-border card-body">
        <div v-for="(component, tab) in tabs" :key="tab"
             :class="'tab-pane ' +  (choosedTab === tab ? 'active' : '')" :id="tab" role="tabpanel">
          <component :is="component"></component>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tabs: {
        boxes: () => import('./Tabs/Boxes'),
        links:  () => import('./Tabs/Links'),
      },
      choosedTab: 'boxes',
      data: [],

    }
  },
  methods: {
    setTab(tabname) {
      this.choosedTab = tabname
    },
  },
  mounted() {
    this.$setRouteMeta(this.$t('pon_boxes.types'))
    if(typeof this.$route.query.tab !== 'undefined') {
      this.choosedTab = this.$route.query.tab
    }
  }
}
</script>
<style scoped>
.tab-content {
  border: 1px solid #dee2e6;
  background: #FFFFFF;
}
</style>