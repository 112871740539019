<template>
  <Card :name="$t('portal_settings')" :show="true">
    <Preloader v-if="loading || data === null"/>
    <div v-else>

      <div class="row">
        <div class="col-mini col-12">
          <h4>{{ $t('global') }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-mini col-7">{{ $t('notification_place') }}</div>
        <div class="col-mini col-5">
          <v-select v-model="data.global.notification_place" label="name" class="style-chooser"
                    :options="notificationPlaces" :hide-selected="true" :clearable="false" :close-on-select="true"
                    :reduce="item => item.key"
          ></v-select>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-mini col-12">
          <h4>{{ $t('device') }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-mini col-7">{{ $t('expand_pon_tree') }}</div>
        <div class="col-mini col-5">
          <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
            <input type="checkbox" v-model="data.device.expand_pon_tree">
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="row" style="margin-top: 5px">
        <div class="col-mini col-7">{{ $t('breadcrumb_show') }}</div>
        <div class="col-mini col-5">
          <v-select v-model="data.device.breadcrumb_show" label="name" class="style-chooser"
                    :options="breadcrumbShow" :hide-selected="true"
                    :clearable="false"
                    :close-on-select="true"
                    :filterable="false"
                    :searchable="false"
                    :reduce="item => item.key"
          ></v-select>
        </div>
      </div>
      <div class="row" style="margin-top: 5px">
        <div class="col-mini col-7">{{ $t('interfaces_stat_type') }}</div>
        <div class="col-mini col-5">
          <v-select v-model="data.device.interfaces_stat_type" label="name" class="style-chooser"
                    :options="interfaceTypes" :hide-selected="true"
                    :clearable="false"
                    :close-on-select="true"
                    :filterable="false"
                    :searchable="false"
                    :reduce="item => item.key"
          ></v-select>
        </div>
      </div>
      <hr>
      <div class="row" >
        <div class="col-mini col-12">
          <h4>{{ $t('device_dashboard') }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-mini col-7">{{ $t('show_groups') }}</div>
        <div class="col-mini col-5">
          <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
            <input type="checkbox" v-model="data.device_dashboard.show_groups">
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="row" style="margin-top: 5px">
        <div class="col-mini col-7">{{ $t('down_devices_on_top') }}</div>
        <div class="col-mini col-5">
          <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
            <input type="checkbox" v-model="data.device_dashboard.down_devices_on_top">
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="row"  style="margin-top: 5px">
        <div class="col-mini col-7">{{ $t('sort_by') }}</div>
        <div class="col-mini col-5">
          <v-select v-model="data.device_dashboard.sort_by" label="name" class="style-chooser"
                    :options="devDashboardSortBy" :hide-selected="true" :clearable="false" :close-on-select="true"
                    :reduce="item => item.key"
          ></v-select>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-mini col-6">
          <button @click="update" class="btn btn-info">
            <i class="mdi mdi-content-save"></i> {{$t('save')}}
          </button>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"

export default {
  components: {Card, Preloader},
  props: {
    userId: {
      type: [Number, String],
      default: 0,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: null,
      loading: true,
      interfaceTypes: [
        {key: 'all', name: this.$t('all')},
        {key: 'splitted', name: this.$t('splitted')},
      ],
      severitiesList: [
        'INFO', 'WARNING', 'CRITICAL'
      ],
      devDashboardSortBy: [
        {key: 'ip', name: this.$t('ip')},
        {key: 'name', name: this.$t('name')},
        {key: 'location', name: this.$t('location')},
      ],
      breadcrumbShow: [
        {key: 'ip', name: this.$t('ip')},
        {key: 'name', name: this.$t('name')},
      ],
      notificationPlaces: [
        {key: 'topLeft', name: this.$t('noty_places.topLeft')},
        {key: 'topRight', name: this.$t('noty_places.topRight')},
        {key: 'topCenter', name: this.$t('noty_places.topCenter')},
        {key: 'center', name: this.$t('noty_places.center')},
        {key: 'centerLeft', name: this.$t('noty_places.centerLeft')},
        {key: 'centerRight', name: this.$t('noty_places.centerRight')},
        {key: 'bottom', name: this.$t('noty_places.bottom')},
        {key: 'bottomLeft', name: this.$t('noty_places.bottomLeft')},
        {key: 'bottomCenter', name: this.$t('noty_places.bottomCenter')},
        {key: 'bottomRight', name: this.$t('noty_places.bottomRight')},
      ],
    }
  },
  mounted() {
    this.data = this.settings
    if (this.data !== null) {
      this.loading = false
    }
  },
  watch: {
    settings: {
      handler(sett) {
        this.data = sett
        this.loading = false
      },
      deep: true,
    }
  },
  methods: {
    async update() {
      this.loading = true
      await this.$api.put('/user/' + this.userId, {
        settings: this.data,
      }).then(data => {
        this.data = data.data.settings
        if(this.userId === 'self' || this.userId === this.$auth.getUser().id) {
          this.$noty.updateLayout(data.data.settings.global.notification_place)
          this.$auth.updateUser(data.data)
          this.$i18n.locale = data.data.language
        }
        this.loading = false
        this.$noty.success(this.$t('account_settings_saved'))
      }).catch((e) => {
        this.data = this.settings
        console.log(e)
      })
      this.loading = false
    },
  }
}
</script>