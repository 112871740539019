<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-mini col-12 col-sm-12 col-lg-12">
        <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options" style="margin-top: 5px; ">
          <template v-slot:key="{row}">
            <span style="font-weight: bold">{{row.key}}</span>
          </template>
          <template v-slot:last_run="{row}">
            <span style="font-weight: bold" v-if="row.latest !== null">{{row.latest}}</span>
            <span style="font-weight: bold" v-if="row.latest === null">{{$t('newer')}}</span>
          </template>
          <template v-slot:component="{row}">
            <span v-if="row.component === null" >{{$t('system_cron')}}</span>
            <span v-if="row.component !== null" :title="`ID: ${row.component.id}, Key: ${row.component.name}`">{{row.component.key}}</span>
          </template>
          <template v-slot:crontab="{row, update}" >
            <input style="min-width: 150px"  :value="row.crontab" @input="update" class="form-control" :readonly="row.readonly" placeholder="*/5 * * * *">
          </template>
          <template v-slot:command="{row, update}">
            <input style="min-width: 500px" :value="row.command" @input="update" class="form-control" :readonly="row.readonly" placeholder="echo 'New job created'">
          </template>
          <template v-slot:state="{row, update}">
              <label class="switch-small" title="Enabled"
                     style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                  <input type="checkbox"  :disabled="row.readonly"  :checked="row.state" @change="update" @update="update">
                  <span class="slider"></span>
              </label>
          </template>
          <template v-slot:actions="{row, update}" >
            <div v-if="row.editable">
              <button  v-if="!row.readonly" :disabled="row.saveInProcess" @click="save(row);" class="btn btn-default btn-sm" style="margin: 3px" :title="$t('save')"><i class="fa fa-save"></i></button>
              <button v-if="row.readonly" @click="row.readonly=false; update(row.readonly)" class="btn btn-default btn-sm" style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></button>
            </div>
          </template>

        </v-client-table>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";

export default {
  components: {Preloader, SomethingWrong},
  data() {
    return {
      data: [],
      schedule: null,
      loading: true,
      error: '',
      table:  {
        columns: ['key', 'last_run', 'component', 'crontab', 'command', 'state', 'actions'],
        options: {
          perPage: 50,
          perPageValues: [50, 100, 'All'],
          skin: 'VueTables__table table table-bordered table-sm table-striped tbl',
          editableColumns:['crontab','command','state', 'actions'],
          headings: {
            key: this.$t('key'),
            last_run: this.$t('last_run'),
            component: this.$t('component'),
            crontab: this.$t('crontab'),
            command: this.$t('command'),
            state: this.$t('state'),
            actions: ''
          },
          sortable: ['last_run', 'key', 'command', 'state'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async save(schedule) {
      let success = false
      console.log(schedule)
      schedule.state = schedule.state ? 'ENABLED' : 'DISABLED'
      await this.$api.put('/system/schedule/' + schedule.id, schedule).then(r => {
         this.$noty.success(this.$t("schedule_updated"))
        success = true
        this.load()
      })
      return success
    },
    async load() {
      this.loading = true
      await this.$api.get('/system/schedule').then(dt => {
        this.data = []
        dt.data.forEach(d => {
            d.state = d.state === 'ENABLED'
            d.saveInProcess = false
            d.readonly = true
            this.data.push(d)
        })
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>
