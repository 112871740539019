<template>
  <div>
    <div class="row">
      <div class="col-12 col-mini">
        <div class="row">
          <div class="col-sm-12" v-html="$t('macros.variable_block')" style="margin-bottom: 15px;"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('macros.device') }}</label>
              <v-select class="style-chooser" v-model="device" label="displayName" :placeholder="$t('choose_device')" :options="formOptions.devices"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('macros.interfaces') }}</label>
              <v-select class="style-chooser" v-model="selectedInterface" label="name" :options="interfaces" :placeholder="$t('choose_interface')" ></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <br>
              <button class="btn btn-info" @click="loadVariables">{{$t('select')}}</button>
            </div>
          </div>
        </div>
        <Preloader v-if="variables === null && loadingVariables"></Preloader>
        <div class="row" v-if="variables">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div class="row" v-if="variables !== null">
          <div class="col-sm-12">
            <div style="overflow-y: auto; max-height: 400px; background: #FAFAFA; border: 1px solid #DADADA">
              <json-tree :data="variables" :level="1"></json-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
      <Parameters v-model="parameters" :variables="variables"></Parameters>
      </div>
    </div>
  </div>
</template>

<script>

import Preloader from "@/components/Preloader.vue";
import Parameters from "@/components/Macros/ParametersFormBuilder.vue";

export default {
  components: {Parameters, Preloader, },
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    formOptions: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      variables: null,
      device: null,
      interfaces: [],
      selectedInterface: null,
      parameters: [],
      loadingVariables: false,
    }
  },
  watch: {
    parameters: {
      handler() {
        let order = 0
        this.parameters.forEach((val, key) => {
          this.parameters[key].order = order++
        })
        this.$emit('update', {
          'key': 'parameters',
          'value': this.parameters,
        })
      },
      deep: true,
    },
    selectedInterface: {
      handler() {
        this.variables = null
      },
      deep: true,
    },
    device: {
      async handler(n) {
        this.interfaces = []
        this.selectedInterface = null
        this.variables = null
        if (n !== null) {
          await this.loadInterfaces()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.parameters = JSON.parse(JSON.stringify(this.item.parameters))
    this.parameters.forEach((val, key) => {
      if(!this.parameters[key]['item_name']) {
        this.parameters[key]['item_name'] = ''
      }
      if(!this.parameters[key]['item_filter']) {
        this.parameters[key]['item_filter'] = ''
      }
    })

  },
  methods: {

    async loadInterfaces() {
      const { data } =  await this.$api.get( '/device-interface/by-device/'+this.device.id);
      this.interfaces = data

    },
    async loadVariables() {
      if(!this.device) {
        this.$noty.warning(this.$t('please_choose_device'))
        return
      }
      this.loadingVariables = true

      let data = null
      await this.$api.post(this.apiComponentName + '/variables', {
        device: {
          id: this.device.id
        },
        interface: {
          id: this.selectedInterface?.id
        },
      }).then(d => {
        data = d.data
      }).catch(e => {
        console.log(e)
        this.device = null
      });
      this.variables = data

      this.loadingVariables = false
    },
  },
};
</script>
