<template>
  <div class="container-fluid">
    <div class="row" v-if="view !== null">
      <div class="col-12 col-mini">
        <component :is="view" :device-store="deviceStore" :device-calling="deviceCalling"
                   v-if="deviceStore && deviceStore.device"></component>
        <!--<lazy-component  :device="device" :deviceId="deviceId" />-->
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";

import {deviceCallingSync} from "./deviceCallingSync";
import currentDeviceStore from "@/views/DeviceInfo/DeviceDashboard/currentDeviceStore";

let randomId = function (length = 6) {
  return Math.random().toString(36).substring(2, length + 2);
};
export default {
  name: "device_info",
  props: {
    deviceStore: {
      type: Object,
      default: null,
    }
  },
  components: {SomethingWrong, Preloader},
  data() {
    return {
      interval: null,
      deviceIdent: null,
      loading: true,
      error: '',
      view: null,
      deviceCalling: deviceCallingSync(randomId(12)),
    }
  },
  mounted() {
    if (!this.view) {
      this.processLoadComponent(this.deviceStore.device.model)
    }
    this.deviceIdent = this.deviceStore.device.id
    if (this.$auth.getSettings().device.breadcrumb_show === 'ip') {
      this.$setRouteMeta(this.deviceStore.device.ip)
    } else {
      this.$setRouteMeta(this.deviceStore.device.name)
    }
  },
  activated() {
    if (!this.view) {
      this.processLoadComponent(this.deviceStore.device.model)
    }
    this.deviceIdent = this.deviceStore.device.id
    if (this.$auth.getSettings().device.breadcrumb_show === 'ip') {
      this.$setRouteMeta(this.deviceStore.device.ip)
    } else {
      this.$setRouteMeta(this.deviceStore.device.name)
    }
  },
  deactivated() {
    if (this.deviceStore.device.id != this.$route.params.id) {
      this.view = null
    }
  },
  watch: {
    'deviceStore.device': {
      handler(n) {
        if(this.$route.name === 'device_dashboard') {
          this.reload()
        }
      },
      deep: true,
    },
  },
  methods: {
    reload() {
      console.log("Calling reload device info...")
      this.processLoadComponent(this.deviceStore.device.model)
      this.deviceIdent = this.deviceStore.device.id
      if (this.$auth.getSettings().device.breadcrumb_show === 'ip') {
        this.$setRouteMeta(this.deviceStore.device.ip)
      } else {
        this.$setRouteMeta(this.deviceStore.device.name)
      }
    },
    findComponentsByDeviceKey(model) {
      var components = null
      this.$config.deviceComponents.some(e => {
        if (e.deviceKeys && e.deviceKeys.indexOf(model.key) !== -1) {
          components = e
          return true
        }
        if (e.types && e.types.indexOf(model.type) !== -1) {
          components = e
          return true
        }
        return false
      })
      if (components === null) {
        console.log(`Model with key ${model.key} not supported by web panel`)
      }
      return components
    },
    processLoadComponent(model) {
      this.view = null
      this.deviceCalling.resetMeta()
      let component = this.findComponentsByDeviceKey(model)
      if (component === null) {
        console.log(`Model with key ${model.key} not supported by web panel`)
        this.view = () => import('../DeviceNotSupportedByWebPanel.vue')
        return
      }
      var isAllModulesSupported = true
      component.needModules.forEach(e => {
        if (this.$config.enabledComponents.indexOf(e) === -1) {
          isAllModulesSupported = false
          console.log(`Module ${e} not found in system for load component!`)
        }
      })
      if (isAllModulesSupported) {
        console.log(`All need modules supported by agent, load deviceInfo component`)
        this.view = () => component.components.deviceInfo
      } else {
        console.log(`Not all modules supported by agent!!!`)
        this.view = () => import('../DeviceNotSupportedByAgent.vue')
      }
    },
  },
}
</script>
