<template>
  <div >
    <div class="row">
      <div class="col-12 col-mini">
        <div class="row">
          <div class="col-12 col-mini" v-html="$t('macros.variable_block')" style="margin-bottom: 15px; "></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('macros.device') }}</label>
              <v-select class="style-chooser" v-model="device" label="displayName" :options="formOptions.devices" :placeholder="$t('choose_device')" ></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('macros.interfaces') }}</label>
              <v-select class="style-chooser" v-model="selectedInterface" label="name" :options="interfaces" :placeholder="$t('choose_interface')" ></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <br>
              <button class="btn btn-info" @click="loadVariables">{{$t('select')}}</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="variables">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <ParametersForm v-model="parameters" :form-declaration="macro.parameters" :vars="variables" v-if="variables"></ParametersForm>
        <Preloader v-if="variables === null && loadingVariables"></Preloader>
        <div class="row" v-if="variables !== null">
          <div class="col-sm-12">
            <h4>{{ $t('macros.variables') }}</h4>
            <div style="overflow-y: auto; max-height: 400px; background: #FAFAFA; border: 1px solid #DADADA">
              <json-tree :data="variables" :level="1"></json-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12  col-mini">
        <hr>
      </div>
      <div class="col-12  col-mini d-flex align-items-center justify-content-between">
        <h4>{{ $t('macros.template_block') }}</h4>
      </div>
      <div class="col-12  col-mini"  >
        <div class="row"  >
          <div class="col-sm-12 col-md-12 col-lg-6  col-xl-6" style="">
              <div style="font-weight: bold"><br></div>
              <pre><code contenteditable @input="changeTemp" class="language-twig">{{ item.template }}</code></pre>
          </div>
          <div class="col-sm-12  col-md-12 col-lg-6 col-xl-6" style="">
              <div style="font-weight: bold">{{ $t('macros.live_result') }}</div>
              <Preloader v-if="live_result.loading" />
              <div class="" v-else-if="live_result.error !== null" style="text-align: center">
                <br>
                <h3>{{ $t('macros.template_compile_problem') }}<br>
                  <small>{{ live_result.error.response.data.error.description }}</small>
                </h3>
              </div>
              <pre v-if="!live_result.loading && live_result.error === null"><code class="language-twig">{{ live_result.result }}</code></pre>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px" class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12" v-html="$t('macros.twig_easy_doc')"></div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import ParametersForm from "@/components/Macros/ParametersForm.vue";

export default {
  components: {ParametersForm, Preloader},
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    formOptions: {
      type: Object,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      loadingVariables: false,
      device: null,
      interfaces: [],
      parameters: {},
      variables: null,
      selectedInterface: null,
      parameter: null,
      templates: {},
      live_result: {
        loading: false,
        result: '',
        error: null,
      },
      macro: null,
    }
  },
  watch: {
    parameters: {
      handler(n) {
        if (!!this.variables && typeof this.variables.params !== 'undefined') {
          this.variables.params = n
          this.renderTemplate()
        }
      },
      deep: true,
    },
    'macro.template': {
      handler(n) {
        this.renderTemplate()
      },
      deep: true,
    },
    device: {
      async handler(n) {
        this.interfaces = []
        this.selectedInterface = null
        this.variables = null
        if (n !== null) {
          await this.loadInterfaces()
        }
      },
      deep: true,
    },
    selectedInterface: {
      async handler(n) {
        this.variables = null
      },
      deep: true,
    },
  },
  async mounted() {
    this.macro = this.item
    this.$nextTick(() => {
        document.querySelectorAll('pre code.language-twig').forEach((el) => {
            window.hljs.highlightElement(el);
        });
    })
  },

  methods: {
    changeTemp(el) {
      this.macro.template = el.target.innerText
    },
    renderTemplate() {
      if (this.variables === null) {
        return
      }
      this.live_result.loading = true
      this.$api.post(this.apiComponentName + '/preview', {
        device: {
          id: this.device.id
        },
        interface: {
          id: this.selectedInterface?.id
        },
        params: this.parameters,
        template: this.item.template,
      }, true).then(r => {
        this.live_result.error = null
        this.live_result.result = r.data
      }).catch(e => {
        this.live_result.error = e
      }).finally(() => {
        this.live_result.loading = false
         this.$nextTick(() => {
           document.querySelectorAll('pre code.language-twig').forEach((el) => {
             if (!el.classList.contains('hljs')) {
               window.hljs.highlightElement(el);
             }
           });
         })
      })
    },
    async loadInterfaces() {
      const { data } =  await this.$api.get( '/device-interface/by-device/'+this.device.id);
      this.interfaces = data
    },
    async loadVariables() {
      if(!this.device) {
        this.$noty.warning(this.$t('please_choose_device'))
        return
      }
      this.loadingVariables = true
      const { data } =  await this.$api.post(this.apiComponentName + '/variables', {
        device: {
          id: this.device.id
        },
        interface: {
          id: this.selectedInterface?.id
        },
        params: this.parameters
      });
      this.variables = data

      this.loadingVariables = false
    },
  },
}
</script>


<style>
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  width: 100%;
  height: 450px;
}

.height-300 {
  height: 300px;
}


/* for block of numbers */
.hljs-ln-numbers {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    text-align: center;
    color: #ccc;
    border-right: 1px solid #CCC;
    vertical-align: top;
    padding-right: 5px !important;

    /* your custom style here */
}

/* for block of code */
.hljs-ln-code {
    padding-left: 10px !important;
}
</style>
