<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-sm-12 col-lg-5 col-xl-4 col-md-12">
        <Card :name="$t('main')" :show="true">


          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('icon') }}</label>
            <div class="col-sm-9">
              <img :src="data.icon" style="max-width: 200px; max-height: 70px">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('key') }}</label>
            <div class="col-sm-9">
              <input type="text" v-model="data.key" disabled class="form-control" :placeholder="$t('key')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
            <div class="col-sm-9">
              <input type="text" v-model="data.name" disabled class="form-control" :placeholder="$t('name')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('type') }}</label>
            <div class="col-sm-9">
              <input type="text" v-model="data.type" disabled class="form-control" :placeholder="$t('type')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('vendor') }}</label>
            <div class="col-sm-9">
              <input type="text" v-model="data.vendor" disabled class="form-control" :placeholder="$t('vendor')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('controller') }}</label>
            <div class="col-sm-9">
              <input type="text" v-model="data.controller" disabled class="form-control"
                     :placeholder="$t('controller')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('default_pollers') }}</label>
            <div class="col-sm-9">
              <div v-if="data.pollers">
                <li v-for="(timeout, poller) in data.pollers" :key="poller"><b>{{ poller }}</b> <i>({{timeout}}sec)</i></li>
              </div>
              <div v-else>{{ $t('no_pollers') }}</div>
            </div>
          </div>
        </Card>
      </div>
      <div class="col-12 col-md-12 col-sm-12 col-lg-7 col-xl-8">

        <Card :name="$t('poller_configuration')" :show="true" v-if="data.pollers">
          <div class="row">
            <div class="col-8">
              <b>{{$t('use_default_poll_configuration')}}</b>
            </div>
            <div class="col-4">
              <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                <input type="checkbox" :checked="!isPollConfigured" @click="changePollConfiguration">
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <transition name="slide">
          <div v-if="isPollConfigured">
            <hr>
            <div class="row" style="margin-bottom: 15px;">
              <div class="col-6">
                <b> {{$t('poller_name')}}</b>
              </div>
              <div class="col-3">
                <b>{{$t('enabled')}}</b>
              </div>
              <div class="col-3">
                <b>{{$t('interval')}}</b>
              </div>
            </div>
            <div class="row" v-for="(poller, name) in data.params.poller_config" :key="name" style="margin-top: 5px">
              <div class="col-6">
                  <b>{{name}}</b>
              </div>
              <div class="col-3">
                <label class="switch-small" title="Enabled" style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                  <input type="checkbox" :disabled="['interfaces_list'].indexOf(name) !== -1" :checked="poller.enabled" @click="poller.enabled = !poller.enabled">
                  <span class="slider"></span>
                </label>
              </div>
              <div class="col-3">
                <input name="interval" required type="number" class="form-control" v-model="poller.interval">
              </div>
            </div>
          </div>
          </transition>
        </Card>
        <Card :name="$t('extra_parameters')" :show="false" :zero-padding="true">
          <VJsoneditor v-model="data.params" :plus="false" :options="paramEditorOptions"></VJsoneditor>
        </Card>
        <button v-if="id !== 0" class="btn btn-default" @click="updateModel" :disabled="savingInProcess"><i
            class="fa fa-save" style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i>
          {{ $t('save') }}
        </button>
      </div>
      <div class="col-12">

      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import Card from "@/components/Card"
import VJsoneditor from 'v-jsoneditor'

export default {
  components: {Card, SomethingWrong, Preloader, VJsoneditor},
  data() {
    return {
      id: 0,
      data: {
        params: {},
      },
      savingInProcess: false,
      loading: true,
      error: '',
      paramEditorOptions: {
        modes: ['code', 'tree'],
        mode: 'tree',
      }
    }
  },
  computed: {
    isPollConfigured() {
      return this.data.params && this.data.params.poller_config && this.data.params.poller_config !== null
    }
  },
  watch: {
    data: {
      handler(d) {
        console.log(d)
      },
      deep: true,
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.load()
  },
  methods: {
    changePollConfiguration() {
      if(this.isPollConfigured) {
        console.log("Reset poller configuration")
        this.$set(this.data.params, 'poller_config', null)
      } else {
        if (this.data.params === null) {
          this.$set(this.data, 'params', {})
        }
        console.log("Setting poller configuration")
        let pollers = {}
        for (const [name, interval] of Object.entries(this.data.pollers)) {
          pollers[name] = {
            enabled: true,
            interval: interval,
          }
        }
        this.$set(this.data.params, 'poller_config',pollers)
        console.log(this.data.params)
      }
    },
    async updateModel() {
      this.savingInProcess = true
      this.$api.put('/device-model/' + this.id, this.data).then(data => {
        this.data = data.data
        this.$noty.success(this.$t('device_model_success_updated', {name: this.data.name}))
      }).finally(() => {
        this.savingInProcess = false
      })
    },
    async load() {
      this.loading = true
      await this.$api.get('/device-model/' + this.id).then(data => {
        this.data = data.data
        this.$setRouteMeta(this.$t('edit_model', {name: data.data.name}))
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>

<style>
div.jsoneditor-tree {
  min-height: 300px;
}

.jsoneditor-menu {
  background-color: #3e5569 !important;
  border: thin solid #3e5569 !important;
}

.jsoneditor {
  border: thin solid #3e5569 !important;
}

.jsoneditor-contextmenu .jsoneditor-menu button {
  color: #FAFAFA !important;
}

.jsoneditor-contextmenu .jsoneditor-menu button:hover {
  color: #FAFAFA !important;
  background-color: #20323b !important;
}
</style>


