<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-4  col-xl-3 margins" v-for="(box, k) in boxes " :key="k">
          <Block :box="box"></Block>
      </div>
    </div>
    <h4 style="text-align: center" v-if="boxes.length === 0">{{$t('no_results')}}</h4>
  </div>
</template>

<script>


import Block from "./Block";

export default {
  components: {Block},
  props: {
    boxes: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style>
.box-online {
  background: rgba(0, 116, 11, 0.05);
  border: 1px solid rgba(0, 116, 11, 1);
  border-left: 10px solid  rgba(0, 116, 11, 1);
  border-collapse: collapse;
}
.box-unknown {
  background: rgba(70, 107, 73, 0.05);
  border: 1px solid rgb(70, 107, 73);
  border-left: 10px solid  rgb(70, 107, 73);
  border-collapse: collapse;
}

.box-offline {
  background: rgba(116, 0, 0, 0.05);
  border: 1px solid darkred;
  border-left: 10px solid  darkred;
  border-collapse: collapse;
}

.margins {
  margin-bottom: 5px;
  color: black;
}
.submenu {
  color: black;
}
.submenu:hover {
  color: #FAFAFA;
  background: rgba(0,0,0,0.5);
  border: 1px solid gray;
  border-radius: 3px;
}
.submenu:active {
  color: #FAFAFA;
  background: rgba(0,0,0,0.3);
  border: 1px solid gray;
  border-radius: 3px;
}


#submenu {
  background: #FAFAFA;
  color: black;
  font-size: 13px;
  padding: 5px;
  border-radius: 4px;
  display: none;
  width: 150px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  z-index: 100;
  -webkit-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
}

#submenu[data-show] {
  display: block;
}

#submenu[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#submenu[data-popper-placement^='bottom'] > #arrow {
  top: 4px;
}

#submenu[data-popper-placement^='left'] > #arrow {
  right: 4px;
}

#submenu[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
