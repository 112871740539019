<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12 col-md-12">

        <FullscreenPreloader :show="preloading"></FullscreenPreloader>

        <button style="float: left; margin-right: 10px" class="btn btn-default" @click="$refs.fileInput.click()"><i class="mdi mdi-import"></i> {{ $t('macros.import') }}</button>
        <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="application/json"
            @change="importMacros"/>
        <router-link style="float: left" class="btn btn-default" :to="{name: 'macros_create'}"><i class="mdi mdi-plus"></i> {{ $t('macros.add') }}</router-link>

        <v-server-table :columns="table.columns" :options="table.options" ref="table"  @loading="() => {this.preloading = true}" @loaded="() => {this.preloading = false}"
                        class="btn-add-margin-top-for-mobile table-sm">
          <template v-slot:name="{row}">
            {{ row.name }}
          </template>
          <template v-slot:description="{row}">
            <div style="min-width: 200px">{{ row.description }}</div>
          </template>
          <template v-slot:display_for="{row}">
            <b>{{ row.display_for.map(e => {
                return $t(`macros.display_for_options.${e}`)
            }).join(', ')  }}</b>
          </template>
          <template v-slot:models="{row}">
            <div style="max-height: 150px; overflow: auto">
              <li v-for="model in row.models" :key="model.id" :title="model.key">{{model.name}}</li>
            </div>
          </template>
          <template v-slot:user_roles="{row}">
            {{ row.user_roles.map(i => i.name).join(', ') }}
          </template>
          <template v-slot:actions="{row}">
            <router-link v-if="$auth.isPermitted('macros_edit')"
                         :to="{name: 'macros_edit', params: {id:row.id}}" class="btn btn-default  btn-sm"
                         style="margin: 3px" :title="$t('macros.edit')"><i class="fa fa-edit"></i></router-link>
            <button v-if="$auth.isPermitted('macros_edit')"
                        @click="remove(row.id)" class="btn btn-danger btn-sm"
                         style="margin: 3px" :title="$t('macros.remove')"><i class="fa fa-trash"></i></button>
            <button v-if="$auth.isPermitted('macros_edit')"
                        @click="clone(row.id)" class="btn btn-default btn-sm"
                         style="margin: 3px" :title="$t('clone')"><i class="fa fa-copy"></i></button>
            <button v-if="$auth.isPermitted('macros_edit')"
                        @click="exportToFile(row.id)" class="btn btn-default btn-sm"
                         style="margin: 3px" :title="$t('macros.export')"><i class="fa fa-download"></i></button>
          </template>
        </v-server-table>

      </div>
    </div>
  </div>
</template>

<script>
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";

export default {
  components: {FullscreenPreloader},

  data() {
    return {
      preloading: false,
      table: {
        columns: ['id','name', 'description', 'display_for', 'models', 'user_roles', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          requestFunction(data) {
            return this.$api.get('/component/macros/control', data).catch(function (e) {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          perPage: 100,
          perPageValues: [50, 100,  500, 1000],
          search: false,
          headings: {
            name: this.$t('macros.name'),
            description: this.$t('macros.description'),
            display_for: this.$t('macros.display_for'),
            models: this.$t('macros.models'),
            user_roles: this.$t('macros.user_roles'),
            actions: '',
          },
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  methods: {
    download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], {type: contentType});
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },
    async remove(id) {
      if(!confirm(this.$t('macros.confirm_remove'))) return;

      await this.$api.delete('/component/macros/control/'+id)
      this.$refs.table.refresh()
    },
    async clone(id) {
      await this.$api.put('/component/macros/control/clone/'+id)
      this.$refs.table.refresh()
    },
    async exportToFile(id) {
      this.preloading = true
      let macro = {}
      await this.$api.get('/component/macros/control/'+id).then(({data}) => {
          let models = []
          data.models.forEach(model => {
             models.push({key: model.key})
          })
          delete(data.id)
          data.models = models
          data.user_roles = []
          macro = data
      })
      if(!macro.name.trim()) {
        macro.name = "NoName"
      }
      this.download(JSON.stringify(macro,null, 2), `${macro.name}.json`, 'application/json')
      this.preloading = false
    },
    async importMacros(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      let object = null
      this.preloading = true
      fileReader.readAsText(files[0])
      fileReader.addEventListener('load', async () => {
        object = JSON.parse(fileReader.result)
        if(!object) {
          this.$noty.error(this.$t('macros.error_read_imported_macros'))
        }
        await this.createMacros(object)
        this.preloading = false
      })
      this.preloading = false

    },
    async createMacros(macros) {
      this.isLoading = true
      this.$api.post(`/component/macros/control`, macros).then(async ({ data }) => {
        this.$noty.success(this.$t('macros.success_created'))
        await this.$router.push({name: 'macros_edit', params: { id: data.id }})
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.preloading = false
      })
    },
  },
  mounted() {
      this.$setRouteMeta(this.$t('macros.list'))
    }
}
</script>


<style >
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 550px;
}
.height-300 {
  height: 300px;
}
</style>
