<template>
  <div style="width: 100%; height: 100%;  text-align: center" class="justify-content-center align-center align-content-center">
     <!-- <img src="/assets/images/loader2.svg" style="margin: 15px; max-width: 80px; width: 60%; vertical-align: center" ><br>-->

      <i class="mdi mdi-spin mdi-loading" :style="'font-size: ' + size + 'px'"></i><br>
    <span v-if="withText" style="">{{loadingText}}</span>
    <div v-if="showReloadPage" style="margin: 0; padding: 20px; border-radius: 5px">
      <h4 v-html="$t('loading_is_very_slow')"></h4>
      <button class="btn btn-light" @click="reload()">
        <i class="mdi mdi-reload"></i> {{$t('reload_page')}}
      </button>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      size: {
        default: "64",
        type: [String, Number],
      },
      withText: {
        default: false,
        type: Boolean
      },
      loadingText: {
        default: "Loading...",
        type: String,
      },
      reloadTimeout: {
        default: 0,
        type: Number
      }
    },
    computed: {
      blockSize() {
        return this.size;
      }
    },
    data() {
      return {
        showReloadPage: false,
      }
    },
    methods: {
      reload() {
        window.location.reload()
      }
    },
    mounted() {
       if(this.reloadTimeout !== 0) {
          setTimeout(() => {
            this.showReloadPage = true
          }, this.reloadTimeout)
       }
    }
  }
</script>
