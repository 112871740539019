<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12 col-md-12">
        <div class="row">
          <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
            <div class="form-group">
              <label class="mb-0">{{$t('name')}}</label>
              <input type="text" class="form-control date-inputmask" v-model="data.name" :placeholder="$t('enter_name')">
            </div>
          </div>
          <div class="col-sm-6 col-lg-5 col-xl-5 col-md-6">
            <div class="form-group">
              <label class="mb-0">{{$t('description')}}</label>
              <input type="text" class="form-control date-inputmask" v-model="data.description" :placeholder="$t('enter_description')">
            </div>
          </div>
          <div class="col-sm-6 col-lg-2 col-xl-2 col-md-6">
            <div class="form-group">
              <label class="mb-0">{{$t('display')}}</label>
              <br>
              <label class="switch mb-0">
                <input type="checkbox" v-model="data.display">
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <div class="col-sm-6 col-lg-2 col-xl-2 col-md-6">
            <div class="form-group">
              <label class="mb-0">&nbsp;</label>
              <button v-if="roleId !== 0" style="display: block;" class="btn btn-default" @click="updateRole" :disabled="savingInProcess"><i class="fa fa-save" style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i> {{$t('save')}}</button>
              <button v-if="roleId === 0" style="display: block;" class="btn btn-default" @click="createRole" :disabled="savingInProcess"><i class="fa fa-save" style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i> {{$t('create')}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12 col-md-12">
        <Card :name="$t('role_privileges')" :show="true">
          <div class="row">
            <div class="col-sm-6 col-md-12 col-lg-6 col-xl-4" v-for="(permGroup, keyGroup) in privileges"
                 :key="keyGroup" style="padding-bottom: 30px">
              <div class="permission-group">
                <input :disabled="roleId < 0" type="checkbox" @click="setRolePermissions(keyGroup, !permGroup.checked)"
                       :checked="permGroup.checked">
                <label :title="keyGroup">{{ permGroup.name }}</label>
                  <div v-if="permGroup.description" style="font-size: 90%; color: gray">{{permGroup.description}}</div>
              </div>
              <div v-for="(perm, key) in permGroup.rules" :key="key" class="permissions">
                <input :disabled="roleId < 0 || $config.defaultRolePermissions.indexOf(key) !== -1" type="checkbox" @click="setPermission(key, !perm.checked)" :checked="perm.checked">
                <label :title="key">{{ perm.name }}</label>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import Card from "@/components/Card"


export default {
  components: {Card, SomethingWrong, Preloader},
  data() {
    return {
      roleId: 0,
      data: {},
      savingInProcess: false,
      loading: true,
      error: '',
      privileges: {},
    }
  },
  async mounted() {
    this.privileges = await this.getPrivilegesList()
    if (this.$route.params.id === 'new') {
      this.$setRouteMeta(this.$t('create_new_role'))
      this.data = {
        name: '',
        description: '',
        display: true,
        permissions: [],
      }
      this.loading = false
    } else {
      this.roleId = this.$route.params.id
      await this.load()
    }
  },
  watch: {},
  methods: {
    setRole(data) {
      console.log(data)
      if(data.permissions === null) {
        data.permissions = []
      }
      this.data = data
      this.recalcCheckedPrivileges()
    },
    recalcCheckedPrivileges() {
      for (const [privRootKey] of Object.entries(this.privileges)) {
        let countUnChecked = Object.keys(this.privileges[privRootKey]['rules']).length
        for (const [privKey] of Object.entries(this.privileges[privRootKey]['rules'])) {
          if (this.data.permissions.indexOf(privKey) !== -1) {
            countUnChecked--
            this.privileges[privRootKey]['rules'][privKey]['checked'] = true
          } else {
            this.privileges[privRootKey]['rules'][privKey]['checked'] = false
          }
        }
        this.privileges[privRootKey]['checked'] = countUnChecked === 0;
      }
    },
    getPermissionsFromForm(privileges) {
      let rules = []
      for (const [privRootKey] of Object.entries(privileges)) {
        for (const [privKey] of Object.entries(privileges[privRootKey]['rules'])) {
          if (privileges[privRootKey]['rules'][privKey]['checked']) {
            rules.push(privKey)
          }
        }
      }
      return rules
    },
    setRolePermissions(roleName, value) {
      for (const [privKey] of Object.entries(this.privileges[roleName]['rules'])) {
        if (value && this.data.permissions.indexOf(privKey) === -1) {
          this.data.permissions.push(privKey)
        } else if (!value && this.$config.defaultRolePermissions.indexOf(privKey) === -1) {
          this.data.permissions.remove(privKey)
        }
      }
      this.recalcCheckedPrivileges()
    },
    setPermission(permissionName, value) {
      if (value && this.data.permissions.indexOf(permissionName) === -1) {
        this.data.permissions.push(permissionName)
      } else if (!value) {
        this.data.permissions.remove(permissionName)
      }
      this.recalcCheckedPrivileges()
    },
    async getPrivilegesList() {
      var privileges = {}
      await this.$api.get('/user-role-permissions').then(r => {
        r.data.forEach(e => {
          if (typeof privileges[e.logic_group] === 'undefined') {
            privileges[e.logic_group] = {
              name: this.$t('permissions.groups.' + e.logic_group),
              description: this.$te('permissions.group_descriptions.' + e.logic_group) ? this.$t('permissions.group_descriptions.' + e.logic_group) : null,
              key: e.logic_group,
              rules: {},
              checked: false,
            }
          }
          privileges[e.logic_group]['rules'][e.key] = {
            name: this.$t('permissions.keys.' + e.key),
            checked: false,
            key: e.key,
            description: e.description,
          }
        })
      })
      return privileges
    },
    async updateRole() {
      this.savingInProcess = true
      this.$api.put('/user-role/' + this.roleId, {
        name: this.data.name,
        description: this.data.description,
        display: this.data.display,
        permissions: this.data.permissions,
      }).then(data => {
        this.setRole(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('role_successful_updated', {name: this.data.name}))
      })
    },
    async createRole() {
      this.savingInProcess = true
      this.$api.post('/user-role', {
        name: this.data.name,
        description: this.data.description,
        display: this.data.display,
        permissions: this.data.permissions,
      }).then(data => {
        this.load(data.data.id)
        this.$noty.success(this.$t('role_successful_created', {name: this.data.name}))
      }).catch(() => {
        this.savingInProcess = false
      })
    },
    async load(roleId = 0) {
      this.loading = true
      if (roleId === 0) {
        roleId = this.roleId
      }
      await this.$api.get('/user-role/' + roleId).then(data => {
        this.setRole(data.data)
        this.$setRouteMeta(this.$t('edit_role', {name: data.data.name}))
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>

<style scoped>

.permission-group {
  vertical-align: center;
  border-bottom: 1px solid #DADADA;
  margin-bottom: 10px;
}

.permission-group input {
  width: 24px;
  height: 24px;
}

.permission-group label {
  padding-left: 5px;
  margin-top: -10px;
  position: absolute;
  top: 12px;
  font-size: 16px;
}

.permissions {
  padding-left: 15px;
}

.permissions input {
  width: 16px;
  height: 16px;
}

.permissions label {
  padding-left: 5px;
}

</style>
