export default class LocalConfig {
    _settings
    constructor() {
        let cfg =  JSON.parse(localStorage.getItem('local_config'))
        if(!cfg) {
            this._settings = this.getEmpty()
        } else {
            this._settings = cfg
        }
    }
    getEmpty() {
        return JSON.parse(JSON.stringify({
            pages: {
                device_dashboard: {
                    view_type: 'blocks'
                }
            },
        }))
    }
    set(name, value = null) {
        console.log(`Set parameter ${name} with value=${value}`)
        this._settings[name] = value
        this.persist()
        return this
    }
    get(name, defaultValue = null) {
        if(typeof this._settings[name] !== 'undefined') {
            return this._settings[name]
        } else {
            return defaultValue
        }
    }

    getPagesCfgParameter(page, parameter) {
        if(typeof this._settings.pages[page] === 'undefined') {
            return  null
        }
        if(typeof this._settings.pages[page][parameter] === 'undefined') {
            return  null
        }
        return this._settings.pages[page][parameter]
    }

    setPagesCfgParameter(page, parameter, value) {
        if(typeof this._settings.pages[page] === 'undefined') {
            this._settings.pages[page] = {}
        }
        this._settings.pages[page][parameter] = value
        this.persist()
        return this._settings
    }

    persist() {
        localStorage.setItem('local_config', JSON.stringify(this._settings))
    }
}
