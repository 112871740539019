<template>
    <div>
        <div>
            <Preloader v-if="!loaded"></Preloader>
            <Bar
                    ref="chart"
                    :style="`height: ${height}px`"
                    v-if="loaded"
                    @setChart="setChart"
                    :draggable="true"
                    :data="chartdata"
                    :options="chartOptions"/>
        </div>
    </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import defaultOptions from './options/line/default'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js/auto'
import { getRelativePosition } from 'chart.js/helpers';


export default {
    name: 'BarChartContainer',
    components: {Bar, Preloader },
    props: {
        options: {
            type: Object,
            default: () => defaultOptions,
        },
        callBack: {
            type: Object,
            default: null
        },
        height: {
            type: [Number, String],
            default: 300
        },
        hideOptions: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        time_range: {
          handler(n) {
            if(n.length === 0) return;
             this.form = {
               start: n[0].getTime() / 1000,
               stop: n[1].getTime() / 1000,
               step: this.form.step
             }
          }
        },
    },
    data: () => ({
        requestParams: {},
        chartOptions: null,
        loaded: false,
        chartdata: null,
        chartObj: null,
    }),
    methods: {
        setChart(obj) {
          this.chartObj = obj
        },
        async load() {
          try {
            await this.callBack.sendRequest(null, null).then((r => {
              this.chartdata = r.data
              this.loaded = true
            }))
          } catch (e) {
            console.error(e)
          }
        },
        sendRequest(params) {
            this.loaded = false
            try {
                this.callBack.sendRequest(null, params).then((r => {
                    this.chartdata = r.data
                    this.loaded = true
                }))
            } catch (e) {
                console.error(e)
            }
        }
    },
    mounted() {
        this.loaded = false
        if(this.options) {
            this.chartOptions = this.options
        } else {
            this.chartOptions = defaultOptions
        }

        if(!this.hideOptions) {
           this.time_range = [
             new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
             new Date()
          ]
        }


      this.chartOptions.onClick = (e) => {
        let canvasPosition = getRelativePosition(e, e.chart);
        let index = e.chart.scales.x.getValueForPixel(canvasPosition.x);
        console.log("Index", index)
        console.log("Label", this.chartdata.labels[index])
        this.$emit('onClick', this.chartdata.labels[index])
      }
        // this.chartOptions.onClick = (event) => {
        //     let canvasPosition = Chart.helpers.getRelativePosition(event, this.chartObj);
        //     let elemId = this.chartObj.scales['x-axis-0'].getValueForPixel(canvasPosition.x);
        //     this.$emit('onClick', this.chartdata.labels[elemId])
        // }
    }
}
</script>
