<template>
  <div class="container-fluid">
    <div class="row">
      <FullscreenPreloader :show="loading"></FullscreenPreloader>
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12">
        <Card :show="true" :name="$t('filter')">
          <div class="row">
            <div class="col-sm-6 col-lg-5 col-xl-4 col-md-5">
              <div class="form-group">
                <label class="mb-0">{{ $t('device') }}</label>
                <v-select class="style-chooser" required v-model="form.device" label="label"
                          :placeholder="$t('device')"
                          :options="options.devices"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-7 col-xl-8 col-md-7" v-if="form.device">
              <table>
                <tr>
                  <th>{{ $t('ip') }}:</th>
                  <td>{{ form.device.ip }}</td>
                </tr>
                <tr>
                  <th>{{ $t('model') }}:</th>
                  <td>{{ form.device.model.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('name') }}:</th>
                  <td>{{ form.device.name }}</td>
                </tr>
                <tr v-if="form.device.coordinates">
                  <th>{{ $t('location_from_coordinates') }}:</th>
                  <td>{{ form.device.coordinates.display_name }}</td>
                </tr>
              </table>
            </div>
          </div>
          <hr>
          <div class="row">

            <div class="col-sm-12">
              <label :style="options.interfaces.length === 0 ? 'color: #909090': ''">{{ $t('interfaces') }}</label>
            </div>
            <div class="col-sm-6">
              <button style="margin: 6px" class="btn btn-sm btn-success" :disabled="options.interfaces.length === 0"
                      @click="options.interfaces.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                {{ $t('select_all') }}
              </button>
              <button style="margin: 6px" class="btn btn-sm btn-secondary" :disabled="options.interfaces.length === 0"
                      @click="options.interfaces.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                {{ $t('unselect_all') }}
              </button>
            </div>
            <div class="col-12 col-sm-11 col-lg-11 col-xl-11 col-md-11">
              <div style="display: inline">
                <a v-for="iface in options.interfaces"
                   :key="iface.name"
                   :class="'badge badge-pill a-to-badge' + (iface.selected ? ' badge-success':' badge-secondary')"
                   style="font-size: 15px; margin: 3px"
                   @click="iface.selected = !iface.selected"
                   href="javascript:void(0)"
                >
                  <i class="fa fa-plus" v-if="!iface.selected"></i>
                  <i class="fa fa-minus" v-if="iface.selected"></i>
                  {{ iface.name }}
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-1 col-lg-1 col-xl-1 col-md-1">
              <button @click="$refs.boxesList.refresh()" class="btn btn-info btn-block" ><i class="mdi mdi-reload"></i></button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <BoxesList ref="boxesList" :interfaces="this.options.interfaces.filter(i => {return i.selected})"></BoxesList>
  </div>
</template>


<script>
import Card from "@/components/Card"
import FullscreenPreloader from "../../components/FullscreenPreloader";
import BoxesList from "./BoxesList";

export default {
  components: {BoxesList,   FullscreenPreloader, Card},
  data() {
    return {
      loading: true,
      options: {
        devices: [],
        interfaces: [],
        ifaceExpanding: {},
      },
      form: {
        device: null,
      },
    }
  },
  computed: {
    isEditingAllowed() {
      return this.$auth.isPermitted('pon_boxes_map_editing')
    },
  },
  watch: {
    $route: {
      handler(n) {
        if (n.name === 'pon_boxes_boxes') {
          this.$setRouteMeta(this.$t('pon_boxes.list'))
          if (n.query.refresh && n.query.refresh === 'yes' && this.options.interfaces.length > 0) {
            this.$refs.boxesList.refresh()
          }
        }
      }
    },
    'form.device': {
      async handler(n) {
        if (n !== null) {
          if(!n.coordinates) {
            alert(this.$t('choose_device_coordinates_before_start_work_with_device'))
            this.form.device = null
            return
          }
          await this.loadInterfaces(n)
        } else {
          this.options.interfaces = []
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.$setRouteMeta(this.$t('pon_boxes.list'))
    await this.loadDevices()
    console.log(this.$route.query)
    if(this.$route.query.device_id) {
      let device = null
      this.options.devices.some(d => {
         if(this.$route.query.device_id == d.id) {
           device = d
           return true
         }
         return  false
      })
      this.form.device = device
    }
    this.loading = false
  },
  methods: {
    async loadDevices() {
      await this.$api.get('/component/pon_boxes/options/devices').then(r => {
        this.options.devices = []
        r.data.forEach(e => {
          e.label = e.ip + ' - ' + e.name
          this.options.devices.push(e)
        })
      })
    },
    async loadInterfaces(device) {
      await this.$api.get(`/component/pon_boxes/options/interfaces/${device.id}`).then(r => {
        this.options.interfaces = []
        r.data.forEach(e => {
          e.selected = true
          this.options.interfaces.push(e)
          this.$set(this.options.ifaceExpanding, e.id, false)
        })
      })
    },

  },

}
</script>
<style scoped>
</style>