<template>
    <div class="container-fluid">
      <ModalForm :title="$t('pinger_info')" ref="modal" :modal-width="1200">
        <template v-slot:content>
            <Pinger :device-store="choosedDevice"></Pinger>
        </template>
        <template v-slot:footer>
          <div style="height: 0px"></div>
        </template>
      </ModalForm>
        <div class="row">
            <div class="col-12 col-mini col-sm-12">
                <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
                <Card :name="$t('filters')" :show="true" :disable-hide="true">
                    <div class="row">
                        <div class="col-xl-4 col-sm-8 col-lg-5 col-md-6" style="min-width: 330px; ">
                            <div class="form-group">
                                <label class="mb-0">{{ $t('time_range') }}</label>
                                <date-picker v-model="filter.time_range" type="datetime" range :showSecond="false"
                                             style="width: 100%"></date-picker>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-4 col-lg-3 col-md-2" style=" ">
                            <div class="form-group">
                                <label class="mb-0">{{ $t('graph_step') }}</label>
                                <v-select class="style-chooser"
                                          v-model="filter.step"
                                          label="displayName"
                                          :options="step"
                                          :multiple="false"
                                          :close-on-select="true"
                                          :clearable="false"
                                />
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-md-2  col-6">
                            <div class="form-group">
                                <label class="mb-0"> &nbsp;</label>
                                <button class="btn btn-default btn-block btn-sm"   @click="search">
                                    <i
                                            class="mdi mdi-table-search mdi-18px"></i></button>
                            </div>
                        </div>
                      <div class="col-xl-2 col-lg-2 col-sm-2 col-md-2  col-6">
                        <div class="form-group">
                          <label class="mb-0"> &nbsp;</label>
                          <div>
                            <WcaExportExcel  style="width: 100%" name="Errors" v-if="!preloading" :fetch="exportXls.fetch" :fields="exportXls.fields" ></WcaExportExcel>
                          </div>
                        </div>
                      </div>
                    </div>
                </Card>
            </div>
            <div class="col-12 col-mini">
                <Card :name="$t('chart_data')" :show="true"  :disable-hide="true">
                    <Preloader v-if="chartLoading"></Preloader>
                    <line-chart-container @onClick="setDate" v-show="!chartLoading" :height="300" ref="chart"
                                          :hide-options="true" :options="chartOptions"
                                          :call-back="chartCallBack"></line-chart-container>
                    <small>{{$t('analytics_click_on_chart_for_load_data')}}</small>
                    <div style="text-align: right; float: right; font-weight: bold"><i class="mdi mdi-timelapse"></i>
                     {{ moment(filter.choosed_time).format('YYYY-MM-DD HH:mm:ss') }}
                    </div>
                </Card>


                    <v-server-table :columns="table.columns" :options="table.options" ref="table"
                                    @loading="() => {this.preloading = true}" @loaded="() => {this.preloading = false}"
                                    class="btn-add-margin-top-for-mobile table-sm">
                        <template v-slot:ip="{row}">
                            <router-link :to="{name: 'device_dashboard', params: {id: row.id}}">
                                {{ row.ip }}
                            </router-link>
                        </template>
                        <template v-slot:name="{row}">
                            {{row.name}}
                        </template>
                        <template v-slot:group="{row}">
                            {{row.group.name}}
                        </template>
                        <template v-slot:model="{row}">
                            {{row.model.name}}
                        </template>
                        <template v-slot:status="{row}">
                            <div :style="`color: ` + (row.status === 'Up' ? 'darkgreen' : 'darkred')">
                                <div style="float: left; "
                                     :class="`status-flicker ` + (row.status === 'Up' ? 'status-online' : 'status-offline')"></div>
                                <div style="float: left; font-weight: bold">{{ row.status }}</div>
                            </div>
                        </template>
                        <template v-slot:uptime_sec="{row}">
                            <div>
                                <span v-if="row.uptime_sec && row.status === 'Up'">
                                    {{ moment.unix((new Date().getTime() / 1000) - row.uptime_sec).locale($t('lang_short')).fromNow() }} <span style="font-size: 80%; color: gray">{{row.uptime_sec}}</span>
                                </span>
                                <span v-else style="color: gray">
                                    {{ $t('unknown') }}
                                </span>
                            </div>
                        </template>
                      <template v-slot:info="{row}">
                          <a :title="$t('detailed_info')" href="javascript:void(0)" @click="showDetailedInfo(row)"><i class="mdi mdi-information mdi-18px"></i> </a>
                      </template>
                    </v-server-table>
            </div>
        </div>
    </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import LineChartContainer from "@/components/Charts/LineChartContainer.vue";
import DefaultChartOptions from "@/components/Charts/options/line/default";
import ChartCallBack from "@/components/Charts/callback";
import Chart from "chart.js";
import ModalForm from "@/components/ModalForm.vue";
import Pinger from "@/views/DeviceInfo/DeviceDashboard/General/Tabs/Pinger.vue"
import WcaExportExcel from "@/components/WcaExportExcel.vue";

var filter = {}

export default {
    components: {
      ModalForm,
        LineChartContainer,
        FullscreenPreloader,
        Card,
        Preloader,
        DatePicker,
      Pinger,
      WcaExportExcel
    },
    data() {
        return {
          exportXls: {
            fields: {
              'ip':'ip',
              'name':'name',
              'group':'group',
              'model':'model',
              'uptime_sec':'uptime_sec',
              'status':'status'
            },
            async fetch() {
              const filter2 = {...filter}
              let tm = Math.round(filter.choosed_time.getTime() / 1000)
              filter2.filter = JSON.parse(JSON.stringify(filter))
              filter2.filter.choosed_time = tm
              if(filter2.orderBy && data.orderBy === 'group') {
                filter2.orderBy = 'group.name'
              }
              if(filter2.orderBy && filter2.orderBy === 'model') {
                filter2.orderBy = 'model.name'
              }

              const {data} = await this.$api.put('/component/analytics/table/device-statuses', filter2)

              const data2 = data.map(i => {
                return {
                  ...i,
                  group: i.group?.name,
                  model: i.model?.name,
                };
              })

              return data2;
            }
          },
            data: [],
            choosedDevice: null,
            filter: {
                devices: [],
                time_range: [],
                step: '10m',
                choosed_time: null,
            },
            step: [
                '5m',
                '10m',
                '15m',
                '30m',
                '1h',
                '2h',
                '3h',
            ],
            chartOptions: DefaultChartOptions,
            chartCallBack: (new ChartCallBack()),
            preloading: true,
            chartLoading: true,
            actionQuery: '',
            deviceList: [],
            table: {
                columns: ['ip', 'name', 'group', 'model', 'uptime_sec', 'status', 'info'],
                options: {
                    requestFunction(data) {
                        let tm = Math.round(filter.choosed_time.getTime() / 1000)
                        data.filter = JSON.parse(JSON.stringify(filter))
                        data.filter.choosed_time = tm
                        if(data.orderBy && data.orderBy === 'group') {
                            data.orderBy = 'group.name'
                        }
                        if(data.orderBy && data.orderBy === 'model') {
                            data.orderBy = 'model.name'
                        }
                        return this.$api.put('/component/analytics/table/device-statuses', data).catch(function (e) {
                            this.dispatch('error', e);
                        }).then(resp => {
                            resp.count = resp.meta.total_records
                            return resp;
                        });
                    },
                    skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
                    perPage: 300,
                    perPageValues: [300, 500, 1000],
                    headings: {},
                    sortable: ['ip', 'name', 'group', 'model',  'status',],
                    // filterable: false,
                    texts: {
                        count: this.$t('dt_table.count'),
                        first: this.$t('dt_table.first'),
                        last: this.$t('dt_table.last'),
                        filter: this.$t('dt_table.filter'),
                        filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
                        limit: this.$t('dt_table.limit'),
                        page: this.$t('dt_table.page'),
                        noResults: this.$t('dt_table.noResults'),
                        filterBy: this.$t('dt_table.filterBy'),
                        loading: this.$t('dt_table.loading'),
                        defaultOption: this.$t('dt_table.defaultOption'),
                        columns: this.$t('dt_table.columns'),
                    },
                },
            },
        }
    },
    created() {
        let currDateString = moment().format("YYYY-MM-DD");
        this.filter.time_range = [
            new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
            new Date()
        ]
        this.filter.choosed_time = this.filter.time_range[1]
        filter = this.filter
        console.log(this.filter)
    },
    async mounted() {
        this.$setRouteMeta(this.$t('device_statuses'))
        this.$api.enableSupportWaiting()
        if (typeof this.$route.query.device_id !== 'undefined') {
            console.log("DeviceID setted")
        }
        await this.$api.waitResponses()
        this.loadChart()
        this.preloading = false
    },
    methods: {
        showDetailedInfo(dev) {
          this.choosedDevice = {device: dev}
          console.log(dev)
          this.$refs.modal.show()
        },
        setDate(date) {
            console.log(date)
            this.filter.choosed_time = moment(date, 'DD.MM.YY hh:mm').toDate()
            this.$refs.table.refresh()
        },
        async search() {
            this.loadChart()
            this.filter.choosed_time = this.filter.time_range[1]
            this.$refs.table.refresh()
        },
        async loadChart() {
            this.chartCallBack.setUri('/component/analytics/charts/device-statuses')
            this.chartCallBack.setParams({
                step: this.filter.step,
                start: this.filter.time_range[0].getTime() / 1000,
                end: this.filter.time_range[1].getTime() / 1000,
            })
            this.chartLoading = true
            await this.$refs.chart.load()
            this.chartLoading = false
        },
    }
}
</script>
<style>
.VueTables__limit-field label {
    display: inline;
    margin: 5px;
}

.json-tree {
    padding-left: 10px !important;
}

.json-tree-key {
    font-size: 14px;
}

.status-flicker {
    animation: flicker 2s infinite;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 50%;

}

.status-online {
    -webkit-box-shadow: 0px 0px 5px 0px darkgreen;
    -moz-box-shadow: 0px 0px 5px 0px darkgreen;
    box-shadow: 0px 0px 5px 0px darkgreen;
    background: darkgreen;
}

.status-offline {
    -webkit-box-shadow: 0px 0px 5px 0px darkred;
    -moz-box-shadow: 0px 0px 5px 0px darkred;
    box-shadow: 0px 0px 5px 0px darkred;
    background: darkred;
}

@keyframes flicker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.8;
    }
}
</style>