<template>
    <div class="row">
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12" style="margin-bottom: 3px;"
           v-for="iface in interfaces" :key="iface.id">
        <GroupBlock v-if="objects[iface.id]" :iface="iface" :show="ifaceExpanding[iface.id]"
                    @change-show="function(expand) {ifaceExpanding[iface.id] = expand}"
                    :stat="{all: objects[iface.id].length, online: objects[iface.id].filter(e => {return e.status === 'up'}).length}">
          <Blocks :boxes="objects[iface.id]"></Blocks>
        </GroupBlock>
      </div>
      <div class="col-12">
        <Preloader v-if="loading" ></Preloader>
      </div>
    </div>
</template>


<script>
import GroupBlock from "./BoxesList/GroupBlock";
import Blocks from "./BoxesList/Blocks";
import Preloader from "@/components/Preloader";

export default {
  components: {Preloader, Blocks, GroupBlock},
  props: {
    interfaces: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      ifaceExpanding: {},
      objects: {},
    }
  },
  computed: {
    isEditingAllowed() {
      return this.$auth.isPermitted('pon_boxes_map_editing')
    },
  },
  watch: {
    'interfaces': {
      async handler(n) {
        this.objects = {}
        if (n !== null && n.length !== 0) {
          this.loadObjectsByInterfaces(n)
        } else {
          this.objects = {}
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadObjectsByInterfaces(this.interfaces)
  },
  methods: {
    refresh() {
       this.loadObjectsByInterfaces(this.interfaces)
    },
    async loadObjectsByInterfaces(ifaces) {
      this.loading = true
      let template = null
      this.objects = {}

      for (let i = 0; i < ifaces.length; i++) {
        this.$set(this.objects, ifaces[i].id, [])
        if(!this.ifaceExpanding[ifaces[i].id]) {
          this.$set(this.ifaceExpanding, ifaces[i].id, false)
        }
        try {
          await this.$api.get('/component/pon_boxes/template/by-interface/' + ifaces[i].id).then(r => {
            template = r.data
          })
          await this.$api.get('/component/pon_boxes/object/by-template/' + template.id).then(r => {
            let elements = []
            r.data.forEach(elem => {
              elem.onts = []
              elem.template = template
              if (elem.type.type !== 'box') return
              elements.push(elem)
            })
            this.$set(this.objects, ifaces[i].id, elements)
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.loading = false
    },

  },

}
</script>
<style scoped>
</style>