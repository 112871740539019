<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12 col-md-12">

        <FullscreenPreloader :show="preloading"></FullscreenPreloader>

        <button style="float: left; margin-right: 10px" class="btn btn-default" @click="$refs.fileInput.click()"><i
            class="mdi mdi-import"></i> {{ $t('onts_registration.import') }}
        </button>
        <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="application/json"
            @change="importOntsRegistrationMacros"/>
        <router-link style="float: left" class="btn btn-default" :to="{name: 'onts_registration_create'}"><i
            class="mdi mdi-plus"></i> {{ $t('onts_registration.add') }}
        </router-link>

        <v-server-table :columns="table.columns" :options="table.options" ref="table"
                        @loading="() => {this.preloading = true}" @loaded="() => {this.preloading = false}"
                        class="btn-add-margin-top-for-mobile table-sm">
          <template v-slot:name="{row}">
            {{ row.name }}
          </template>
          <template v-slot:created_at="{row}">
            {{ row.created_at }}
          </template>
          <template v-slot:updated_at="{row}">
            {{ row.updated_at }}
          </template>
          <template v-slot:enabled="{row}">
            <div style="min-width: 200px">{{ row.enabled }}</div>
          </template>
          <template v-slot:models="{row}">
            <div style="max-height: 150px; overflow: auto">
              <li v-for="model in row.models" :key="model.id" :title="model.key">{{ model.name }}</li>
            </div>
          </template>
          <template v-slot:actions="{row}">
            <router-link v-if="$auth.isPermitted('unregistered_onts_config')"
                         :to="{name: 'onts_registration_edit', params: {id:row.id}}" class="btn btn-default  btn-sm"
                         style="margin: 3px" :title="$t('onts_registration.edit')"><i class="fa fa-edit"></i>
            </router-link>
            <button v-if="$auth.isPermitted('unregistered_onts_config')"
                    @click="remove(row.id)" class="btn btn-danger btn-sm"
                    style="margin: 3px" :title="$t('onts_registration.remove')"><i class="fa fa-trash"></i></button>
            <button v-if="$auth.isPermitted('unregistered_onts_config')"
                    @click="clone(row.id)" class="btn btn-default btn-sm"
                    style="margin: 3px" :title="$t('clone')"><i class="fa fa-copy"></i></button>
            <button v-if="$auth.isPermitted('unregistered_onts_config')"
                    @click="exportToFile(row.id)" class="btn btn-default btn-sm"
                    style="margin: 3px" :title="$t('onts_registration.export')"><i class="fa fa-download"></i></button>
          </template>
        </v-server-table>

      </div>
    </div>
  </div>
</template>

<script>
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";

export default {
  components: {FullscreenPreloader},

  data() {
    return {
      preloading: false,
      table: {
        columns: ['id', 'name', 'created_at', 'updated_at', 'enabled', 'models', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          requestFunction(data) {
            return this.$api.get('/component/onts_registration/control', data).catch(function (e) {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          perPage: 100,
          perPageValues: [50, 100, 500, 1000],
          search: false,
          headings: {
            name: this.$t('name'),
            created_at: this.$t('created_at'),
            updated_at: this.$t('updated_at'),
            enabled: this.$t('enabled'),
            models: this.$t('macros.models'),
            actions: '',
          },
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  methods: {
    download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], {type: contentType});
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },
    async remove(id) {
      if (!confirm(this.$t('onts_registration.confirm_remove'))) return;

      await this.$api.delete('/component/onts_registration/control/' + id)
      this.$refs.table.refresh()
    },
    async clone(id) {
      await this.$api.put('/component/onts_registration/control/clone/' + id)
      this.$refs.table.refresh()
    },
    async exportToFile(id) {
      this.preloading = true
      let macro = {}
      await this.$api.get('/component/onts_registration/control/' + id).then(({data}) => {
        let models = []
        data.models.forEach(model => {
          models.push({key: model.key})
        })
        delete (data.id)
        data.models = models
        data.user_roles = []
        macro = data
      })
      if (!macro.name.trim()) {
        macro.name = "NoName"
      }
      this.download(JSON.stringify(macro, null, 2), `${macro.name}.json`, 'application/json')
      this.preloading = false
    },
    async importOntsRegistrationMacros(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      let object = null
      this.preloading = true
      fileReader.readAsText(files[0])
      fileReader.addEventListener('load', async () => {
        object = JSON.parse(fileReader.result)
        if (!object) {
          this.$noty.error(this.$t('onts_registration.error_read_imported_macros'))
        }
        await this.createMacros(object)
        this.preloading = false
      })
      this.preloading = false

    },
    async createMacros(macros) {
      this.isLoading = true
      this.$api.post(`/component/onts_registration/control`, macros).then(async ({data}) => {
        this.$noty.success(this.$t('onts_registration.success_created'))
        await this.$router.push({name: 'onts_registration_edit', params: {id: data.id}})
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.preloading = false
      })
    },
  },
  mounted() {
    this.$setRouteMeta(this.$t('onts_registration.list'))
  }
}
</script>


<style>
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 550px;
}

.height-300 {
  height: 300px;
}
</style>
