<template>
    <div class="container-fluid">
        <ModalForm :title="$t('edit_interface_polling')" :adaptive="false" ref="interfaceModal">
            <template v-slot:content>
                <InterfacesModal :interfaces-list="interfaces"></InterfacesModal>
            </template>
            <template v-slot:footer>
                <div></div>
            </template>
        </ModalForm>
        <div class="row" v-if="loading && error === ''">
            <div class="col-12 col-mini">
                <Preloader/>
            </div>
        </div>
        <div class="row" v-if="error !== ''">
            <div class="col-12 col-mini">
                <SomethingWrong/>
            </div>
        </div>
        <div class="row" v-if="!loading && error === ''">
            <div class="col-12 col-mini">
                <div style="margin-left: -3px; margin-right: -3px; margin-bottom: 10px" class="row flex-wrap">
                    <div class="flex-fill ctrl-fill"  v-if="deviceId !== 0">
                        <button class="btn btn-default btn-block btn-edited" @click="updateDevice"
                                :disabled="savingInProcess"><i class="fa fa-save "></i> {{ $t('save') }}
                        </button>
                    </div>
                    <div class="flex-fill ctrl-fill" v-if="deviceId !== 0">
                        <button class="btn btn-warning  btn-block  btn-edited" @click="runPoller"
                                :disabled="savingInProcess"><i class="mdi mdi-download"></i>
                            {{ $t('run_poller') }}
                        </button>
                    </div>
                    <div class="flex-fill ctrl-fill" v-if="deviceId !== 0">
                        <button class="btn btn-warning btn-block btn-edited" @click="clearHistory"
                                :disabled="savingInProcess" :title="$t('clear_device_poller_history')">
                            <i class="mdi mdi-eraser-variant"></i>
                            {{ $t('clear_history') }}
                        </button>
                    </div>
                    <div class="flex-fill ctrl-fill" v-if="deviceId === 0">
                        <button class="btn btn-default btn-block btn-edited" @click="createDevice"
                                :disabled="savingInProcess"><i class="fa fa-save"></i>
                            {{ $t('create') }}
                        </button>
                    </div>
                    <div class="flex-fill ctrl-fill" v-if="deviceId !== 0">
                        <button class="btn btn-danger btn-block btn-edited" @click="deleteDevice"
                                :disabled="savingInProcess"><i class="fa fa-trash"></i>
                            {{ $t('delete') }}
                        </button>
                    </div>
                    <div class="flex-fill ctrl-fill" v-if="deviceId !== 0">
                        <router-link class="btn btn-outline-info btn-block btn-edited" :to="{name: 'device_dashboard', params: {id: this.deviceId}}"
                                :disabled="savingInProcess"><i class="mdi mdi-arrow-right-bold"></i>
                            {{ $t('go_to_device') }}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-12 col-mini col-sm-12 col-lg-6 col-xl-6 col-md-12">
                <Card :name="$t('main')" :show="true">
                    <div class="row" v-if="deviceId !== 0">
                        <div class="col-sm-3"></div>
                        <div class="col-mini col-sm-9" style="margin-bottom: 20px;">
                            <table style="width: 100%">
                                <tr>
                                    <th>{{ $t('device_id') }}</th>
                                    <td>{{ data.id }}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t('created') }}</th>
                                    <td>{{ data.created_at }}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t('updated') }}</th>
                                    <td>{{ data.updated_at }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{ $t('ip') }}</label>
                        <div class="col-mini col-sm-9">
                            <input type="text" v-model="data.ip" class="form-control" :placeholder="$t('ip')">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                            $t('access')
                            }}</label>
                        <div class="col-mini col-sm-9">
                            <v-select class="style-chooser"
                                      v-model="data.access" label="name" :options="accesses"></v-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                            $t('enabled')
                            }}</label>
                        <div class="col-mini col-sm-9">
                            <label class="switch-small" title="Enabled"
                                   style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                                <input type="checkbox" :checked="data.enabled" @click="data.enabled = !data.enabled">
                                <span class="slider"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0"></label>
                        <div class="col-mini col-sm-9">
                            <button @click="loadInfo"
                                    :disabled="loadFromDeviceInProcess || !data.access.id || data.ip === ''"
                                    class="btn btn-block btn-sm btn-info"><i class="mdi mdi-download"></i>
                                {{ $t('load_info_from_device') }}
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div :style="loadFromDeviceInProcess ? 'opacity: .5;' : ''">
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('name')
                                }}</label>
                            <div class="col-mini col-sm-9">
                                <input type="text" v-model="data.name" class="form-control" :placeholder="$t('name')"
                                       :disabled="loadFromDeviceInProcess">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('location')
                                }}</label>
                            <div class="col-mini col-sm-9">
                                <input type="text" v-model="data.location" class="form-control"
                                       :placeholder="$t('location')"
                                       :disabled="loadFromDeviceInProcess">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('model')
                                }}</label>
                            <div class="col-mini col-sm-9">
                                <v-select class="style-chooser"
                                          v-model="data.model" label="name" :options="models"
                                          :disabled="loadFromDeviceInProcess"></v-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('mac')
                                }}</label>
                            <div class="col-mini col-sm-9">
                                <input type="text" v-model="data.mac" class="form-control" :placeholder="$t('mac')"
                                       :disabled="loadFromDeviceInProcess">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('group')
                                }}</label>
                            <div class="col-mini col-sm-9">
                                <v-select class="style-chooser"
                                          v-model="data.group" label="name" :options="groups"
                                          :disabled="loadFromDeviceInProcess"></v-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('serial')
                                }}</label>
                            <div class="col-mini col-sm-9">
                                <input type="text" v-model="data.serial" class="form-control"
                                       :placeholder="$t('serial')"
                                       :disabled="loadFromDeviceInProcess">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-mini col-sm-3 text-right control-label col-form-label mb-0">{{
                                $t('description')
                                }}</label>
                            <div class="col-mini col-sm-9">
              <textarea type="text" v-model="data.description" class="form-control" :placeholder="$t('description')"
                        :disabled="loadFromDeviceInProcess">

              </textarea>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card :name="$t('extra_parameters')" :show="false" :zero-padding="true">
                    <div class="row">
                        <div class="col-12 col-mini">
                            <VJsoneditor class="" v-model="data.params" :plus="false"
                                         :options="paramEditorOptions"></VJsoneditor>
                        </div>
                    </div>
                </Card>
            </div>
            <div class="col-mini col-12 col-mini col-md-12 col-sm-12 col-lg-6 col-xl-6">
                <Card :name="$t('links')" :show="true"
                      v-if="data.id && $config.isComponentEnabled('links') && $auth.isPermitted('links_view')">
                    <LinksTable :device="data"></LinksTable>
                </Card>
                <ModalForm :title="$t('choose_coordinate')" :hide-footer="true" :modal-width="900"
                           ref="searchMapCoordinates">
                    <template v-slot:content>
                        <CoordinatesMapSearch style="z-index: 99" @updated="updateCoordinates"
                                              :coordinates="data.coordinates"></CoordinatesMapSearch>
                    </template>
                    <template v-slot:footer>
                        <div></div>
                    </template>
                </ModalForm>
                <Card :name="$t('coordinates')" :show="true" v-if="data.id">
                    <div class="row">
                        <div class="col-mini col-10">
                            <div v-if="data.coordinates !== null">
                                {{ data.coordinates.display_name }}
                            </div>
                            <div v-else>
                                <h4 style="text-align: center"><i class="mdi mdi-map-marker-off-outline"></i>  {{ $t('coordinates_not_setted') }}</h4>
                            </div>
                        </div>
                        <div class="col-mini col-2">
                            <button class="btn btn-info btn-block" @click="$refs.searchMapCoordinates.show()"><i
                                    class="mdi mdi-map"></i></button>
                        </div>
                    </div>
                    <div class="row" v-if="data.coordinates !== null ">
                        <div class="col-mini col-12">
                            <hr>
                        </div>
                        <div class="col-mini col-12">
                            <CoordinatesMapMini :zoom="17" :coordinates="data.coordinates"></CoordinatesMapMini>
                        </div>
                    </div>
                </Card>
                <Card :name="$t('poller_configuration')" :show="true" v-if="deviceId !== 0 && data?.model?.type !== 'UNKNOWN'">
                    <div class="row">
                        <div class="col-mini col-8">
                            <b>{{ $t('use_default_poll_configuration') }}</b>
                        </div>
                        <div class="col-mini col-4">
                            <label class="switch-small" title="Enabled"
                                   style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                                <input type="checkbox" :checked="!isPollConfigured" @click="changePollConfiguration">
                                <span class="slider"></span>
                            </label>
                        </div>
                    </div>
                    <transition name="slide">
                        <div v-if="isPollConfigured">
                            <hr>
                            <div class="row" style="margin-bottom: 15px; ">
                                <div class="col-mini col-6">
                                    <b> {{ $t('poller_name') }}</b>
                                </div>
                                <div class="col-mini col-3">
                                    <b>{{ $t('enabled') }}</b>
                                </div>
                                <div class="col-mini col-3">
                                    <b>{{ $t('interval') }}</b>
                                </div>
                            </div>
                            <div class="row" v-for="(poller, name) in data.params.poller_config" :key="name"
                                 style="margin-top: 5px">
                                <div class="col-mini col-6">
                                    <b>{{ name }}</b>
                                </div>
                                <div class="col-mini col-3">
                                    <label class="switch-small" title="Enabled"
                                           style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                                        <input type="checkbox" :disabled="['interfaces_list'].indexOf(name) !== -1"
                                               :checked="poller.enabled" @click="poller.enabled = !poller.enabled">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                                <div class="col-mini col-3">
                                    <input name="interval" required type="number" class="form-control"
                                           v-model="poller.interval">
                                </div>
                            </div>
                        </div>
                    </transition>
                </Card>
              <Card :name="$t('oxidized_configuration')" :show="true" v-if="deviceId !== 0 && oxidizedSupported && data?.model?.type !== 'UNKNOWN'">
                <div class="row" >
                  <div class="col-mini col-8">
                    <b>{{ $t('backup_oxidized_enabled') }}</b>
                  </div>
                  <div class="col-mini col-4">
                    <label class="switch-small" title="Enabled"
                           style=" margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                      <input type="checkbox" :checked="isOxidizedEnabled" @click="toggleOxidizedStatus">
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div class="col-12" v-if="isOxidizedEnabled && oxidizedStatus !== null && oxidizedStatus.last">
                    <hr>
                  </div>
                  <div class="col-9" v-if="isOxidizedEnabled">
                    <div v-if="oxidizedStatus !== null && oxidizedStatus.last">
                      {{$t('model')}}: <b>{{oxidizedStatus.model}}</b><br>
                      {{$t('last_status')}}: <b>{{oxidizedStatus.last.status}}</b><br>
                      {{$t('start')}}: <b>{{oxidizedStatus.last.start}}</b><br>
                      {{$t('end')}}: <b>{{oxidizedStatus.last.end}}</b><br>
                      {{$t('spent')}}: <b>{{oxidizedStatus.last.time.toFixed(2)}}s</b><br>
                    </div>
                  </div>
                  <div class="col-3" v-if="isOxidizedEnabled">
                    <div v-if="oxidizedStatus !== null && oxidizedStatus.last" style="float: right">
                      <a :href="`/oxidized/node/show/${data.ip}`" target="_blank" :title="$t('open_oxidized')"><i class="mdi mdi-file-code-outline" style="font-size: 28px"></i> </a>
                    </div>
                  </div>
                </div>
              </Card>
                <Card :name="$t('configure_port_polling')" :show="true" v-if="deviceId !== 0 && data?.model?.type !== 'UNKNOWN'">
                    <Preloader v-if="!interfacesLoaded"/>
                    <div class="row" v-else-if="interfaces.length === 0">
                        <div class="col-mini col-12">
                            <h4 style="text-align: center">{{ $t('interfaces_list_not_loaded') }}</h4>
                            <div class="row">
                                <div class="col-4">
                                    <button class="btn btn-info" @click="pollInterfaces" :disabled="pollingInProcess">
                                        <i :class="'mdi ' + (pollingInProcess ? ' mdi-spin mdi-loading ' : ' mdi-download')"></i>
                                        {{ $t('poll_interfaces') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-mini col-8">
                            <b>{{ $t('count_interfaces') }}</b>: {{ interfaces.length }}<br>
                            <b>{{ $t('count_polled') }}</b>: {{
                            interfaces.filter(e => {
                              return e.poll_enabled === true
                            }).length
                            }}<br>
                        </div>
                        <div class="col-mini col-4">
                            <button class="btn btn-info" @click="showPortModal"><i class="mdi mdi-folder-edit"></i>
                                {{ $t('edit_ports') }}
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
</template>


<script>
import validator from 'validator';
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import Card from "@/components/Card"
import VJsoneditor from 'v-jsoneditor'
import ModalForm from "@/components/ModalForm";
import InterfacesModal from "@/views/Managment/Device/InterfacesModal";
import CoordinatesMapSearch from "@/components/Blocks/CoordinatesMapSearch";
import CoordinatesMapMini from "../../../components/Blocks/CoordinatesMapMini";
import LinksTable from "../../../components/Links/LinksTable.vue";

export default {
    components: {
        LinksTable,
        CoordinatesMapMini,
        CoordinatesMapSearch, InterfacesModal, ModalForm, Card, SomethingWrong, Preloader, VJsoneditor
    },
    data() {
        return {
            oxidizedSupported: false,
            oxidizedStatus: null,
            loadFromDeviceInProcess: false,
            deviceId: 0,
            data: {
                params: {},
            },
            pollingInProcess: false,
            interfaces: null,
            params: {},
            models: [],
            accesses: [],
            groups: [],
            savingInProcess: false,
            interfacesLoaded: false,
            loading: true,
            error: '',
            paramEditorOptions: {
                modes: ['code', 'tree'],
                mode: 'tree',
            }
        }
    },
    computed: {
        isPollConfigured() {
            return this.data.model.pollers && this.data.params && this.data.params.poller_config && this.data.params.poller_config !== null
        },
        isOxidizedEnabled() {
            return this.data.params && this.data.params.oxidized_enabled
        },
    },
    watch: {
        data: {
            handler(d) {
                console.log(d)
            },
            deep: true,
        },
        '$route': {
            handler(d) {
                if (d.name !== 'management_device_edit') return
                if (d.params.id != this.deviceId) {
                    this.deviceId = d.params.id
                    this.load()
                }
            },
            deep: true,
        }
    },
    async mounted() {
        await this.loadAccesses()
        await this.loadModels()
        await this.loadGroup()
        if (this.$route.params.id === 'new') {
            let chooseGroup = () => {
                if (this.groups.length > 0) {
                    return this.groups[0]
                } else {
                    return {
                        id: -2,
                        name: "SYSTEM_GROUP",
                    }
                }
            }
            let chooseAccess = () => {
                if (this.accesses.length > 0) {
                    return this.accesses[0]
                } else {
                    return {
                        id: 0,
                        name: "",
                    }
                }
            }
            this.data = {
                id: 0,
                name: '',
                location: '',
                model: {
                    id: 0,
                    name: '',
                },
                access: chooseAccess(),
                group: chooseGroup(),
                ip: '',
                enabled: true,
                mac: '',
                serial: '',
                created_at: '',
                updated_at: '',
                description: '',
                coordinates: null,
            }
            this.deviceId = 0
            this.$setRouteMeta(this.$t('create_new_device'))
            this.loading = false
        } else {
            this.deviceId = this.$route.params.id
            await this.load()
            await this.loadOxidizedInfo()
            this.loadInterfaces()
        }
    },
    methods: {
        async loadOxidizedInfo() {
          if(this.$config.isComponentEnabled('oxidized')) {
            await this.$api.get(`/component/oxidized/is-oxidized-supported/${this.deviceId}`).then(r => {
              this.oxidizedSupported = r.data
            }).catch(e => {console.log(e); this.savingInProcess = false; this.loading = false})
            if (this.oxidizedSupported && this.isOxidizedEnabled) {
              await this.$api.get(`/component/oxidized/data/status/${this.deviceId}`,null, true).then(r => {
                this.oxidizedStatus = r.data
              }).catch(e => {console.log(e); this.savingInProcess = false; this.loading = false})
            } else {
              this.oxidizedStatus = null
            }
          } else {
            this.oxidizedStatus = null
          }
        },
        updateCoordinates(b) {
            this.data.coordinates = b
        },
        showPortModal() {
            this.$refs.interfaceModal.show()
        },
        changePollConfiguration() {
            if (this.isPollConfigured) {
                console.log("Reset poller configuration")
                this.$set(this.data.params, 'poller_config', null)
            } else {
                if (this.data.params === null) {
                    this.$set(this.data, 'params', {})
                }
                console.log("Setting poller configuration")
                let pollers = {}
                for (const [name, interval] of Object.entries(this.data.model.pollers)) {
                    pollers[name] = {
                        enabled: true,
                        interval: interval,
                    }
                }
                if (this.data.model.params.poller_config) {
                    for (const [name, data] of Object.entries(this.data.model.params.poller_config)) {
                        pollers[name] = {
                            enabled: data.enabled,
                            interval: data.interval,
                        }
                    }
                }

                this.$set(this.data.params, 'poller_config', pollers)
            }
        },
        toggleOxidizedStatus() {
            if(this.data.params === null) {
              this.data.params = {}
            }
            if(this.data.params.oxidized_enabled === null) {
              this.$set(this.data.params, 'oxidized_enabled', false)
            }
            this.$set(this.data.params, 'oxidized_enabled', !this.data.params.oxidized_enabled)
        },
        loadInfo() {
            this.loadFromDeviceInProcess = true
            if (!validator.isIP(this.data.ip)) {
                this.$noty.error(this.$t('ip_address_not_valid'))
                this.loadFromDeviceInProcess = false
                return
            }
            if (!this.data.access) {
                this.$noty.error(this.$t('choose_access_is_required'))
                this.loadFromDeviceInProcess = false
                return
            }
            this.$api.post('/device-detect', {
                access_id: this.data.access.id,
                ip: this.data.ip,
            }).then(r => {
                this.data.model = r.data.model
                this.data.serial = r.data.serial
                this.data.mac = r.data.mac
                if (this.data.name === '') {
                    this.data.name = r.data.name
                }
                if (this.data.location === '') {
                    this.data.location = r.data.location
                }
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                this.loadFromDeviceInProcess = false
            })
        },
        validate(deviceData) {
            let ret = true
            if (deviceData.model === null || deviceData.model.id <= 0) {
                this.$noty.warning(this.$t('choose_model_is_required'))
                ret = false
            }
            if (deviceData.access === null || deviceData.access.id <= 0) {
                this.$noty.warning(this.$t('choose_access_is_required'))
                ret = false
            }
            if (deviceData.group === null) {
                this.$noty.warning(this.$t('choose_group_is_required'))
                ret = false
            }
            if (deviceData.mac && !validator.isMACAddress(deviceData.mac)) {
                this.$noty.warning(this.$t('mac_address_is_incorrect'))
                ret = false
            }
            if (!validator.isIP(deviceData.ip)) {
                this.$noty.warning(this.$t('ip_address_not_valid'))
                ret = false
            }
            return ret
        },
        async updateDevice() {
            if (!this.validate(this.data)) return false
            this.savingInProcess = true
            this.$api.put('/device/' + this.deviceId, this.data).then(data => {
                this.data = data.data
                this.savingInProcess = false
                this.$noty.success(this.$t('device_success_updated', {name: this.data.name}))
            }).catch(e => {console.log(e); this.savingInProcess = false})
        },
        async deleteDevice() {
            if (confirm(this.$t("are_you_sure_for_delete_device"))) {
                this.loading = true
                this.$api.delete('/device/' + this.deviceId, this.data).then(data => {
                    this.data = data.data
                    this.savingInProcess = false
                    this.$noty.info(this.$t('device_success_deleted'))
                    this.$router.push({name: 'management_device_list'})
                }).catch(e => {console.log(e); this.savingInProcess = false; this.loading = true})
            }
        },
        async clearHistory() {
            if (confirm(this.$t("are_you_sure_for_clear_poller_history"))) {
                this.savingInProcess = true
                this.$api.delete('/poller/clear-history/' + this.deviceId).then(r => {
                    setTimeout(() => {
                        this.savingInProcess = false
                        this.$noty.info(this.$t('success_cleared'))
                        this.load()
                    }, 2000)
                }).catch(e => {
                    this.savingInProcess = false
                })
            }
        },
        async pollInterfaces() {
            this.pollingInProcess = true
            await this.$api.put('/poller/poll/' + this.deviceId, ['interfaces_list']).then(data => {
                this.$noty.success(this.$t('interfaces_list_success_polled'))
            })
            await this.loadInterfaces()
            this.pollingInProcess = false
        },
        async runPoller() {
            await this.$api.put('/poller/poll-background/' + this.deviceId).then(data => {
                this.$noty.success(this.$t('start_poller_requested'))
            })
        },
        async createDevice() {
            if (!this.validate(this.data)) return false
            this.savingInProcess = true
            this.$api.post('/device', this.data).then(data => {
                this.savingInProcess = false
                this.$noty.success(this.$t('device_success_created', {name: this.data.name}))
                this.$router.push({name: 'management_device_edit', params: {id: data.data.id}})
                this.deviceId = data.data.id
                this.load()
                this.interfacesLoaded = true
                this.interfaces = []
            }).catch(() => {
                this.savingInProcess = false
            })
        },
        async load() {
            this.loading = true
            await this.$api.get('/device/' + this.deviceId).then(data => {
                this.data = data.data
                this.$setRouteMeta(this.$t('edit_device', {name: data.data.name}))
            }).catch(() => {
                this.error = 'ERROR'
            })
            this.loading = false
        },
        async loadAccesses() {
            await this.$api.get('/device-access').then(data => {
                this.accesses = data.data
            }).catch(() => {
                this.error = 'ERROR'
            })
        },

        async loadGroup() {
            await this.$api.get('/device-group').then(data => {
                this.groups = data.data
            }).catch(() => {
                this.error = 'ERROR'
            })
        },
        async loadInterfaces() {
            this.interfacesLoaded = false
            await this.$api.get(`/device-interface?device_id=${this.deviceId}`).then(data => {
                this.interfaces = data.data
            }).catch(() => {
                this.error = 'Interfaces not found'
            })
            this.interfacesLoaded = true
        },
        async loadModels() {
            await this.$api.get('/device-model').then(data => {
                this.models = data.data
            }).catch(() => {
                this.error = 'ERROR'
            })
        },

    }
}
</script>

<style>
.btn-edited {
    border-radius: 0px !important;
}

.ctrl-fill {
    padding: 3px;
}
</style>

