<template>
  <a class="navbar-brand" href="javascript:void(0)" @click="$router.push('/')">
    <b class="logo-icon " v-if="!$config.isWhiteLabel">
      <img src="/assets/images/logo.png" alt="homepage" class="" style="invert(100%) ;width: 42px; height: 42px; margin-right: 10px"/>
    </b>
    <span class="logo-text" style="margin-left: 10px">{{ name }}</span>
  </a>
</template>

<script>
export default {
  data() {
    return {
      name: '',
    }
  },
  mounted() {
    this.name = this.$config.name
  }
}
</script>
