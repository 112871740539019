<template>
  <div class="page-login">
    <link href="/dist/css/style.min.css" rel="stylesheet">

    <div style="margin-top: 0 !important;" class="auth-wrapper d-flex  justify-content-center align-items-center">
      <div class="auth-box">
        <div>
          <div class="text-center p-t-20 p-b-20" style="font-size: 24pt; color: #FAFAFA;">
            {{$config.name}}
          </div>
          <div class="text-center p-t-20 p-b-20" style="font-size: 18pt; color: #f35e5e;" v-if="error === 'agent_disabled'">
            {{$t('agent_disabled')}}
          </div>
          <div class="text-center p-t-20 p-b-20" style="font-size: 18pt; color: #f35e5e;" v-else-if="error !== ''">
            {{error}}
          </div>

          <!-- Form -->
          <form class="form-horizontal m-t-20" style="margin-top: 10px; margin-bottom: 10px" id="loginform" @submit.prevent="$emit('submit')">
            <div class="row p-b-30">
              <div class="col-12 col-mini">
                <div class="input-group mb-3">
                  <input :disabled="inProccess" type="text"
                         :value="login"
                         @input="$emit('update:login', $event.target.value)"
                         class="form-control form-control-lg" :placeholder="$t('username')" aria-label="Username" aria-describedby="basic-addon1" required="">
                  <div class="input-group-prepend">
                    <span class="input-group-text " id="basic-addon1"><i class="ti-user"></i></span>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input :disabled="inProccess" type="password"
                         :value="password"
                         @input="$emit('update:password', $event.target.value)"
                         class="form-control form-control-lg" :placeholder="$t('password')" aria-label="Password" aria-describedby="basic-addon1" required="">
                  <div class="input-group-append">
                    <span class="input-group-text " id="basic-addon2"><i class="ti-pencil-alt"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row border-secondary">
              <div class="col-12 col-mini">
                <div class="form-group">
                  <div class="p-t-10">
                    <!-- <button class="btn btn-info" id="to-recover" type="button"><i class="fa fa-lock m-r-5"></i> Lost password?</button> -->
                    <button class="btn btn-primary float-right" :disabled="inProccess" type="submit" >{{$t('sign_in')}}  <i style="padding-left: 5px;" :class="'mdi ' + (inProccess ? ' mdi-spin mdi-loading ' : 'mdi-login-variant')"></i> </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['inProccess', 'message', 'login', 'password', 'error'],
  emits: ['update:login', 'update:password', 'submit'],
}
</script>
<style>
.page-login {
  background-color: #535353;
}
.auth-box {
  background-color: #343a40 !important;
  border-radius: 2px;
}
</style>