<template>
  <div class="container-fluid">
    <FullscreenPreloader :show="loading"></FullscreenPreloader>
    <ModalForm ref="boxEdit" :title="$t('edit_box')" @before-close="saveObject">
      <template v-slot:content v-if="choosedLayer">
        <div class="container-fluid" style="margin-top: 5px">
          <div class="row">
            <div class="col-sm-6">
              <small>{{ $t('name') }}</small><br>
              <input class="form-control" v-model="objectForm.name" :placeholder="$t('box_name')" required>
            </div>
            <div class="col-sm-6">
              <small>{{ $t('type') }}</small><br>
              <v-select class="style-chooser" required v-model="objectForm.type" label="name" :clearable="false"
                        :options="options.boxTypes"></v-select>
            </div>
            <div class="col-sm-12" v-if="form.device !== null && form.interface !== null">
              <small>{{ $t('monitoring_onts') }}</small><br>
              <v-select class="style-chooser" multiple v-model="objectForm.onts" label="name" :close-on-select="false"
                        :options="options.onts.filter(e => {
                          let found = null
                          objectForm.onts.filter(f => {
                            if(f.id === e.id) {
                              found = f
                            }
                          })
                          return found === null
                        })"></v-select>
            </div>
            <div class="col-sm-12" v-else>
              <small>{{ $t('monitoring_onts') }}</small><br>
              <h3 style="text-align: center; color: #6c6c6c">{{ $t('for_control_choose_device_and_interface') }}</h3>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="height: 0px"></div>
      </template>
    </ModalForm>
    <ModalForm ref="linkEdit" :title="$t('edit_link')" @before-close="saveObject">
      <template v-slot:content v-if="choosedLayer">
        <div class="container-fluid" style="margin-top: 5px">
          <div class="row">
            <div class="col-sm-6">
              <small>{{ $t('name') }}</small>
              <input class="form-control" v-model="objectForm.name">
            </div>
            <div class="col-sm-6">
              <small>{{ $t('type') }}</small><br>
              <v-select class="style-chooser" required v-model="objectForm.type" label="name" :clearable="false"
                        :options="options.lineTypes"></v-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="height: 0px"></div>
      </template>
    </ModalForm>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header waves-effect waves-dark" style="padding-top: 6px; padding-bottom: 6px">
            {{ $t('control') }}
          </div>
          <div class="card-body" style="">
            <div class="row">
              <div class="col-sm-6 col-lg-5 col-xl-4 col-md-5">
                <div class="form-group">
                  <label class="mb-0">{{ $t('device') }}</label>
                  <v-select class="style-chooser" required v-model="form.device" label="label"
                            :placeholder="$t('device')"
                            :options="options.devices"></v-select>
                </div>
              </div>
              <div class="col-sm-4 col-lg-4 col-xl-3 col-md-4">
                <div class="form-group">
                  <label class="mb-0">{{ $t('interface') }}</label>
                  <v-select class="style-chooser" required :disabled="form.device === null" v-model="form.interface"
                            label="name"
                            :placeholder="$t('interface')"
                            :options="options.interfaces"></v-select>
                </div>
              </div>
              <div class="col-sm-4">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header waves-effect waves-dark" style="padding-top: 6px; padding-bottom: 6px">
            {{ $t('map') }}
          </div>
          <div class="card-body" style="padding: 0">
            <div style="height: 800px; width: 100%; overflow: hidden; z-index: 1 !important;" id="map"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ModalForm from "@/components/ModalForm";

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import translations from "./translations";
import FullscreenPreloader from "../../components/FullscreenPreloader";

export default {
  components: {FullscreenPreloader, ModalForm},
  data() {
    return {
      loading: true,
      objects: [],
      oldObjects: [],
      form: {
        device: null,
        interface: null,
        template: null,
      },
      options: {
        boxTypes: [],
        lineTypes: [],
        devices: [],
        interfaces: [],
        onts: [],
      },
      choosedLayer: null,
      objectForm: {
        id: null,
        name: '',
        type: null,
        status: null,
        count_interfaces: 0,
        onts: [],
      },
      map: null,
      data: null,
    }
  },
  watch: {
    'form.device': {
      handler(n) {
        if (n !== null) {
          if(!n.coordinates) {
            alert(this.$t('choose_device_coordinates_before_start_work_with_device'))
            this.form.device = null
            return
          }
          this.removeAllLayers()
          this.addDeviceMarker(n)
          this.configSettedDeviceFromForm(n)
        } else {
          this.removeAllLayers()
          this.form.interface = null
          this.disableControls()
        }
      },
      deep: true,
    },
    'form.interface': {
      async handler(n) {
        this.objects = []
        if (n !== null) {
          await this.loadObjectsByInterface(n)
          await this.loadOnts(this.form.device, n)
          this.reinitLayers()
          this.enableControls()
        } else {
          this.disableControls()
          this.removeAllLayers()
          if (this.form.device) {
            this.addDeviceMarker(this.form.device)
          }
        }
      },
      deep: true,
    },
    'objectForm': {
      handler(n) {
        if (this.choosedLayer.toGeoJSON().geometry.type === 'Point') {
          if (n.type && n.type.style) {
            this.stylingMarker(this.choosedLayer, n.type.style)
          }
        } else {
          if (n.type && n.type.style) {
            this.stylingLine(this.choosedLayer, n.type.style)
          }
        }
        console.log("Updating object...", n)
        n.count_interfaces = n.onts.length
        this.choosedLayer.properties = n
        this.choosedLayer.bindPopup(this.generatePopupFromProperties(this.choosedLayer))
      },
      deep: true,
    },

    $route: {
      handler(n) {
        if(n.name === 'pon_boxes_map_builder') {
          this.$setRouteMeta(this.$t('pon_boxes.map_edit'))
        }
      },
      deep: true,
    }
  },
  async mounted() {
    this.$setRouteMeta(this.$t('pon_boxes.map_edit'))
    this.initMap()
    this.$api.enableSupportWaiting()
    this.loadDevices()
    this.loadTypes()
    await this.$api.waitResponses()
    this.$api.disableSupportWaiting()
    this.loading = false
  },
  methods: {
    async loadObjectById() {
       if(this.objectForm.id !== null) {
          this.loading = true
          await this.$api.get(`/component/pon_boxes/object/${this.objectForm.id}`).then(r => {
            let data = r.data
            data.count_interfaces = r.data.mon_interfaces.length
            data.onts = r.data.mon_interfaces
            data.status = null
            this.objectForm = data
            this.loading = false
          })
       }
    },
    addDeviceMarker(device) {
      let svgIcon = window.L.divIcon({
        html: this.$config.components.pon_boxes.configuration.icons.OLT,
        className: "",
        iconSize: [32, 32],
        iconAnchor: [17, 17],
      });
      let marker = window.L.marker([device.coordinates.lat, device.coordinates.lon], {icon: svgIcon})
      marker.device = device
      marker.addTo(this.map)
    },
    removeAllLayers() {
      let layers = this.findLayers(this.map)
      for (let i = 0; i < layers.length; i++) {
        this.map.removeLayer(layers[i]);
      }
    },
    setView(lat, lon, zoom) {
      this.map.setView(new window.L.LatLng(lat, lon), zoom);
    },
    generatePopupFromProperties(layer) {
      let html = '<div>'
      if (layer.properties && layer.properties.name) {
        html += `<div style="text-align: center; width: 100%; font-weight: bold">${layer.properties.name}</div>`
      }
      if (layer.properties.type && layer.properties.type.name) {
        html += `<div style="color: gray">${this.$t('type')}: <b>${layer.properties.type.name}</b></div>`
      }
      if (layer.properties.type && layer.properties.type.type === 'box' && layer.properties.count_interfaces) {
        html += `<div style="color: gray"><small>${this.$t('mon_interfaces')}: <b>${layer.properties.count_interfaces}</b></small></div>`
      }
      html += `</div>`
      html += `<div style="display: flex; text-align: center; align-content: center; ">
                    <div style="flex: 1 1 60px; text-align: center; border-right: 1px solid #D0D0D0">
                      <a href="javascript:void(0)" onclick="window.$map.openModal(${layer._leaflet_id})">
                         <i class="mdi mdi-file-edit" style="font-size: 24px"></i>
                      </a>
                    </div>
                    <div style="flex: 1 1 60px; text-align: center; border-left: 1px solid #D0D0D0">
                      <a href="javascript:void(0)" onclick="window.$map.deleteLayer(${layer._leaflet_id})">
                         <i class="mdi mdi-trash-can" style="font-size: 24px"></i>
                      </a>
                    </div>
                </div>`
      return html
    },
    async openModal(layerId) {
      let layer = null
      this.map.eachLayer(l => {
        if (l._leaflet_id === layerId) {
          layer = l
        }
      })
      this.choosedLayer = layer
      this.objectForm = layer.properties
      await this.loadObjectById()
      if (this.choosedLayer.toGeoJSON().geometry.type === 'Point') {
        this.$refs.boxEdit.show()
      } else {
        this.$refs.linkEdit.show()
      }

    },
    saveObject(event) {
      if (this.objectForm.type === null || this.choosedLayer.properties.name === "") {
        if (confirm(this.$t('type_or_name_not_setted_object_deleted'))) {
          this.map.removeLayer(this.choosedLayer)
        } else {
          event.cancel()
        }
        return
      }
      let data = {
        id: this.objectForm.id,
        name: this.objectForm.name,
        mon_interfaces: this.objectForm.onts,
        type: this.objectForm.type,
        template: this.form.template,
        coordinates: null,
      }
      if (this.objectForm.type.type === 'box') {
        data.coordinates = [
          this.choosedLayer._latlng.lat,
          this.choosedLayer._latlng.lng,
        ]
      } else {
        data.coordinates = []
        this.choosedLayer._latlngs.forEach(l => {
          data.coordinates.push([
            l.lat,
            l.lng,
          ])
        })
      }
      if (this.choosedLayer.properties.id === null) {
        this.$api.post(`/component/pon_boxes/object`, data).then(r => {
          this.objectForm.id = r.data.id
        })
      } else {
        this.$api.put(`/component/pon_boxes/object/${this.choosedLayer.properties.id}`, data).then(r => {

        })
      }
    },
    deleteLayer(layerId) {
      if (!confirm(this.$t('are_you_sure_delete_element'))) {
        return false;
      }
      this.map.eachLayer(l => {
        if (l._leaflet_id === layerId) {
          this.loading = true
          this.$api.delete(`/component/pon_boxes/object/${l.properties.id}`).then(() => {
            this.map.removeLayer(l)
          }).finally(() => {
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    stylingMarker(marker, style = {
      width: 24,
      fill: '#052e77',
      stroke: '#91c3ff',
      strokeWidth: 3,
    }) {
      let radius = style.width / 2
      let realWidth = (style.strokeWidth + style.width)
      let circleRadius = radius - style.strokeWidth

      let icon = window.L.divIcon({
        html: `
<svg xmlns="http://www.w3.org/2000/svg" width="${realWidth}" height="${realWidth}" version="1.1">
  <circle cx="${radius - 1}" cy="${radius - 1}" r="${circleRadius}" stroke="${style.stroke}" stroke-width="${style.strokeWidth}" fill="${style.fill}"></circle>
</svg>`,
        className: "svg-icon",
        iconSize: [style.width, style.width],
        iconAnchor: [radius, radius],
      });
      marker.setIcon(icon)
      return marker
    },
    stylingLine(line, style = {
      weight: 3,
      color: '#052e77',
    }) {
      line.setStyle(style)
      return line
    },
    findLayers(map) {
      var layers = [];
      map.eachLayer(layer => {
        if (
            layer instanceof window.L.Polyline ||
            layer instanceof window.L.Marker ||
            layer instanceof window.L.Circle ||
            layer instanceof window.L.CircleMarker
        ) {
          layers.push(layer);
        }
      });

      // filter out layers that don't have the leaflet-geoman instance
      layers = layers.filter(layer => !!layer.pm);

      // filter out everything that's leaflet-geoman specific temporary stuff
      layers = layers.filter(layer => !layer._pmTempLayer);

      return layers;
    },
    enableControls() {
      this.map.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        continueDrawing: false,
        drawCircleMarker: false,
        drawRectangle: false,
        drawPolyline: true,
        cutPolygon: false,
        rotateMode: false,
        editMode: true,
        drawText: false,
        drawPolygon: false,
      });
    },
    disableControls() {
      this.map.pm.removeControls()
    },
    initMap() {
      window.$map = this
      var startPoint = [this.$config.map.coordinates.lat, this.$config.map.coordinates.lon];

      let osm = window.L.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: 'Data \u00a9 <a href="http://www.openstreetmap.org/copyright"> OpenStreetMap Contributors </a> Tiles \u00a9 HOT'
      });
      let otm = window.L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: 'Data \u00a9 <a href="http://www.openstreetmap.org/copyright"> OpenStreetMap Contributors </a> Tiles \u00a9 HOT'
      });

      let google = window.L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        attribution: '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        subdomains:['mt0','mt1','mt2','mt3']
      });
      let googleH = window.L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        attribution: '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        subdomains:['mt0','mt1','mt2','mt3']
      });
      let baseMaps = {
        "Google (Street)": google,
        "Google (Hybrid)": googleH,
        "OpenStreetMap": osm,
        "OpenTopoMap": otm,
      };

      let activeLayer = google
      if(this.$config.map.default_tile && baseMaps[this.$config.map.default_tile]) {
        activeLayer = baseMaps[this.$config.map.default_tile]
      }

      let map = window.L.map('map', {
        pmIgnore: false,
        snappable: true,
        pinning: true,
        allowSelfIntersection: false,
        requireSnapToFinish: true,
        layers: [activeLayer],
        // fullscreenControl: true,
        // fullscreenControlOptions: {
        //   position: 'topleft'
        // }
      });
      window.L.control.layers(baseMaps).addTo(map);

      map.setView(startPoint, 16)
      map.pm.setLang(this.$t('lang_short'), translations[this.$t('lang_short')], this.$t('lang_short'));
      map.on('pm:globaleditmodetoggled', (e) => {
        if (e.enabled) {
          return
        }
        this.loading = true
        this.$api.enableSupportWaiting()
        this.findLayers(this.map).forEach(o => {
          if (typeof o === 'undefined') return
          if (!o.properties) return;
          if (o.properties.id === null) return;
          let coordinates = []
          if (o._latlngs) {
            o._latlngs.forEach((e) => {
              coordinates.push([e.lat, e.lng])
            })
          }
          if (o._latlng) {
            coordinates = [
              o._latlng.lat,
              o._latlng.lng,
            ]
          }
          if (JSON.stringify(coordinates) != JSON.stringify(o.properties.coordinates)) {
            this.$api.put(`/component/pon_boxes/object/${o.properties.id}`, {
              coordinates: coordinates,
            }).then(() => {
              o.properties.coordinates = coordinates
            })
          }
        })
        this.$api.waitResponses()
        this.$api.disableSupportWaiting()
        this.loading = false
      })
      map.on('pm:create', (e) => {
        this.objectForm = {
          id: null,
          name: '',
          type: null,
          status: null,
          count_interfaces: 0,
          onts: [],
        }
        e.layer.properties = {
          id: null,
          name: '',
          type: null,
          status: null,
          count_interfaces: 0,
          onts: [],
        }
        e.layer.bindPopup(this.generatePopupFromProperties(e.layer))
        this.choosedLayer = e.layer
        if (e.shape === 'Marker') {
          map.pm.disableGlobalEditMode()
          this.$refs.boxEdit.show()
        } else if (e.shape === 'Line') {
          this.$refs.linkEdit.show()
        }
      });
      this.map = map
    },
    reinitLayers() {
      this.removeAllLayers()
      this.addDeviceMarker(this.form.device)
      this.objects.forEach((elem) => {
        let layer = null
        if (elem.type.type === 'box') {
          if (elem.type && elem.type.style) {
            layer = window.L.marker(elem.coordinates)
            this.stylingMarker(layer, elem.type.style)
            layer.properties = elem
            layer.addTo(this.map)
          }
        } else if (elem.type.type === 'line') {
          if (elem.type && elem.type.style) {
            layer = new window.L.Polyline(elem.coordinates);
            layer.properties = elem
            this.stylingLine(layer, elem.type.style)
            layer.addTo(this.map)
          }
        }
        layer.bindPopup(this.generatePopupFromProperties(layer))
      })
    },
    async loadDevices() {
      await this.$api.get('/component/pon_boxes/options/devices').then(r => {
        this.options.devices = []
        r.data.forEach(e => {
          e.label = e.ip + ' - ' + e.name
          this.options.devices.push(e)
        })
      })
    },
    async loadInterfaces(device) {
      await this.$api.get(`/component/pon_boxes/options/interfaces/${device.id}`).then(r => {
        this.options.interfaces = r.data
      })
    },
    async loadOnts(device, iface) {
      await this.$api.get(`/component/pon_boxes/options/interfaces/${device.id}/${iface.id}?type=ONU`).then(r => {
        this.options.onts = r.data
      })
    },
    async configSettedDeviceFromForm(n) {
      this.form.interface = null
      if (n !== null) {
        if (n.coordinates === null) {
          alert(this.$t('error_choose_device_must_contain_coordinates'))
          this.form.device = null
          return
        }
        this.removeAllLayers()
        this.addDeviceMarker(n)
        this.setView(n.coordinates.lat, n.coordinates.lon, 16)
        await this.loadInterfaces(n)
        if (this.options.interfaces.length === 0) {
          alert(this.$t('pon_ports_not_found_in_storage'))
        }
      }
    },
    async loadObjectsByInterface(iface) {
      this.loading = true
      await this.$api.get('/component/pon_boxes/template/by-interface/' + iface.id).then(r => {
        this.form.template = r.data
      })
      await this.$api.get('/component/pon_boxes/object/by-template/' + this.form.template.id).then(r => {
        this.objects = []
        r.data.forEach(elem => {
          elem.onts = []
          this.objects.push(elem)
        })
      })
      this.loading = false
    },
    async loadTypes() {
      await this.$api.get('/component/pon_boxes/type').then(r => {
        this.options.boxTypes = []
        this.options.lineTypes = []
        r.data.forEach(elem => {
          if (elem.type === 'line') {
            this.options.lineTypes.push(elem)
          } else if (elem.type === 'box') {
            this.options.boxTypes.push(elem)
          }
        })
      })
    },
  }
}
// https://leafletjs.com/examples/geojson/example.html - Пример использования импорта geoJSON
// https://stackblitz.com/edit/leaflet-editable?file=script.js,Leaflet.Editable.js - Пример перемещения
// https://aratcliffe.github.io/Leaflet.contextmenu/examples/index.html - контекст-меню
// https://brunob.github.io/leaflet.fullscreen/ - Fullscreen
// https://prominentedge.com/leaflet-measure-path/ - Measure plugin
// https://makinacorpus.github.io/Leaflet.Snap/ - липкие линии
// https://dwilhelm89.github.io/Leaflet.StyleEditor/ - стилизация элементов

// Группировка элементов
// http://ghybs.github.io/Leaflet.FeatureGroup.SubGroup/examples/subGroup-markercluster-controlLayers-realworld.388.html - группировка элементов
// https://leaflet.github.io/Leaflet.markercluster/example/marker-clustering-realworld.388.html - группировка
// http://sintef-9012.github.io/PruneCluster/examples/random.10000-size.html

</script>

<style>
.leaflet-popup-content {
  margin: 10px !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 6px !important;
}

</style>