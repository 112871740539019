import http from '../../common/http'

export default class {
    _api = null
    _uri = ''
    _params = {}
    _method = 'POST'
    constructor() {
        console.log("Constructor created")
        this._api = http.getInstance()
    }
    setUri(uri) {
        this._uri = uri
        return this
    }
    setParams(params) {
        this._params = params
        return this
    }
    getUri() {
        return this._uri
    }
    getParams() {
        return this._params
    }
    setMethod(method) {
        this._method = method
        return this
    }
    getMethod() {
        return this._method
    }
    sendRequest(uri = null, params = null) {
        if(uri === null) {
            uri = this._uri
        }
        if(params === null) {
            params = this._params
        }
        switch (this._method) {
            case 'GET': return this._api.get(uri, params)
            case 'POST': return this._api.post(uri, params)
            case 'PUT': return this._api.put(uri, params)
            case 'DELETE': return this._api.delete(uri, params)
        }
        throw new Error("Unknown method")
    }
}