<template>
  <ModalForm :title="modalTitle" ref="chartModal" :modal-width="1200">
    <template v-slot:content>
      <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden; margin-top: -3px; padding-right: 10px">
        <line-chart-container :options="chartOptions" :call-back="chartCallBack"></line-chart-container>
      </div>
    </template>
    <template v-slot:footer>
      <div style="height: 0px"></div>
    </template>
  </ModalForm>
</template>


<script>
import LineChartContainer from "@/components/Charts/LineChartContainer.vue";
import ModalForm from "@/components/ModalForm.vue";
import ChartCallBack from "@/components/Charts/callback";
import stackedline from "@/components/Charts/options/line/stackedline";

export default {
  components: {ModalForm, LineChartContainer},
  props: {
  },
  data() {
    return {
      chartOptions: stackedline,
      chartCallBack: (new ChartCallBack()),
      storedIface: null,
      modalTitle: '',
    }
  },
  mounted() {
    this.chartOptions = stackedline
  },
  methods: {
    showChart(storedIface) {
      console.log("Start show chart for", storedIface)
      this.modalTitle = this.$t('increasing_errors_for_iface', { 'iface': storedIface.name})
      this.chartCallBack.setUri('/component/analytics/errors/increasing-chart/by-interface/' + storedIface.id)
      this.$refs.chartModal.show()
      this.chartCallBack.setParams({
        step: '10m'
      })
    },
  },
}
</script>