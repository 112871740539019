<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <Card :name="$t('filters')" :show="true">
          <div class="row">
            <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6" style="min-width: 330px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('time_range') }}</label>
                <date-picker v-model="filter.range" type="datetime" range :showSecond="false"
                             style="width: 100%"></date-picker>
              </div>
            </div>
            <div class="col-sm-6 col-lg-8 col-xl-4 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('contain_text') }}</label>
                <input type="text" v-model="filter.query" class="form-control">
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('hide_success') }}</label>
                <input type="checkbox" class="form-control" style="width: 30px; height: 30px; margin-top: 1px"
                       v-model="filter.only_failed">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">{{ $t('pollers') }}</label>
            </div>
            <div class="col-sm-6">
              <button style="margin: 3px" class="btn btn-sm btn-success"
                      @click="pollersList.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                {{ $t('select_all') }}
              </button>
              <button style="margin: 3px" class="btn btn-sm btn-secondary"
                      @click="pollersList.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                {{ $t('unselect_all') }}
              </button>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
              <div style="display: inline">
                <a v-for="action in pollersList.filter(e => {return e.name.indexOf(actionQuery) !== -1})"
                   :key="action.name"
                   :class="'badge badge-pill a-to-badge' + (action.selected ? ' badge-success':' badge-secondary')"
                   style="font-size: 15px; margin: 3px"
                   @click="action.selected = !action.selected"
                   href="javascript:void(0)"
                >
                  <i class="fa fa-minus" v-if="!action.selected"></i>
                  <i class="fa fa-plus" v-if="action.selected"></i>
                  {{ action.name }}
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-10 col-xl-10"></div>
            <div class="col-sm-6 col-lg-2 col-xl-2">
              <button @click="loadData(filter)" style="margin-top: 10px" class="btn btn-sm btn-block btn-outline-info"><i class="mdi mdi-refresh"></i> {{$t('reload_info')}}</button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-mini col-12 col-lg-12 col-md-12">
            <Preloader v-if="loading"/>
            <v-client-table v-model="data" :columns="table.columns" :options="table.options"
                            :style="'margin-top: 5px; position: relative;  z-index: 1; ' "
                            v-if="!loading">
              <template v-slot:child_row="{row}">
                <div class="row" v-for="(data, key) in row" :key="key" style="margin: 0; padding: 0">
                  <div class="col-sm-2" style="margin: 0; padding: 0">{{key}}</div>
                  <div class="col-sm-10"  style="margin: 0; padding: 0" v-if="['telnet_output', row.status].indexOf(key) === -1" >
                    <json-tree :data="data" :level="1" style="max-width: 1000px"></json-tree>
                  </div>
                  <div class="col-sm-10"  style="margin: 0; padding: 0"  v-if="key === 'telnet_output'">
                    <pre>{{data}}</pre>
                  </div>
                  <div class="col-sm-10"  style="margin: 0; padding: 0"  v-if="key === requestHandler(row.status)">
                    Message: <b>{{data.message}}</b><br>
                    File: <b>{{data.file}}:{{data.line}}</b><br>
                    Trace: <br>
                    <pre style="max-width: 1000px">{{data.trace}}</pre>
                  </div>
                </div>
              </template>
              <template v-slot:user="{row}">
                <router-link :to="{name: 'management_user_edit', params: {id: row.user.id}}">{{ row.user.name }}
                </router-link>
              </template>
              <template v-slot:arguments="{row}">
                {{row.arguments}}
              </template>
              <template v-slot:device="{row}">
                <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}">{{ row.device.name }}<br><small>({{row.device.ip}})</small>
                </router-link>
              </template>
              <template v-slot:status="{row}">
                <span
                    :style="row.status === 'FAILED' ? 'color: darkred; font-weight: bold' : ''">{{ $t("log_statuses." + row.status) }}</span>
              </template>
              <template v-slot:module="{row}">
                <b>{{ row.module }}</b>
              </template>
            </v-client-table>
          </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"

export default {
  components: {
    Card,
    Preloader,
    DatePicker,
  },
  data() {
    return {
      data: [],
      filter: {
        range: [],
        pollers: [],
        only_failed: false,
        query: '',
      },
      loading: false,
      actionQuery: '',
      pollersList: [],
      table: {
        columns: ['id', 'start_at', 'stop_at', 'device', 'poller', 'status'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table table-sm',
          perPage: 50,
          perPageValues: [50, 100, 300, 500],
          headings: {
            id: 'Id',
            start_at: this.$t('start_at'),
            stop_at: this.$t('stop_at'),
            poller: this.$t('poller'),
            device: this.$t('device'),
            arguments: this.$t('arguments'),
            status: this.$t('status'),
          },
          sortable: ['id', 'start_at','stop_at', 'device', 'poller', 'status'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  watch: {
    filter: {
      handler(n) {
        this.loadData(n)
      },
      deep: true,
    },
    pollersList: {
      handler(n) {
        var tagged = []
        n.forEach(e => {
          if (e.selected) {
            tagged.push(e.name)
          }
        })
        this.filter.pollers = tagged
      },
      deep: true,
    }
  },
  created() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 1));
    this.filter.range = [
      priorDate,
      today,
    ]
  },
  async mounted() {
    this.$setRouteMeta(this.$t('log_poller'))
    this.pollersList = await this.loadCollects()
  },
  methods: {
    requestHandler(status) {
      const any = 'any';
      return any ? status === "succes" || status === "error" || status === null : undefined;
    },
    async loadData(filters) {
      let status = ''
      if (filters.only_failed) {
        status = 'FAILED'
      }
      let query = {
        start: moment(filters.range[0]).format("YYYY-MM-DD HH:mm:ss"),
        stop: moment(filters.range[1]).format("YYYY-MM-DD HH:mm:ss"),
        query: filters.query,
        pollers: filters.pollers,
        status: status
      }
      this.loading = true
      await this.$api.post('/logs/poller/pollers', query).then(r => {
        this.data = []
        r.data.forEach(d => {
          this.data.push(d)
        })
      }).catch(() => {
      })
      this.loading = false
    },
    async loadCollects() {
      var options = []
      await this.$api.get('/logs/poller/existed-pollers').then(r => {
        r.data.forEach(e => {
          options.push({
            name: e,
            selected: false,
          })
        })
      }).catch(() => {
      })
      return options
    },
  }
}
</script>
<style>
.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}
.json-tree {
  padding-left: 10px !important;
}
.json-tree-key {
  font-size: 14px;
}
</style>