<template>
  <div class="container-fluid">
    <div class="row" v-if="view !== null">
      <div class="col-12 col-mini">
        <component :is="view" :device-store="deviceStore" :interface-id="interfaceId"
                   :device-calling="deviceCalling"></component>
        <!--<lazy-component  :device="device" :deviceId="deviceId" />-->
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader";
import SomethingWrong from "@/components/SomethingWrong";
import {deviceCallingSync} from "./deviceCallingSync";

let randomId = function (length = 6) {
  return Math.random().toString(36).substring(2, length + 2);
};
export default {
  name: 'device_iface_dashboard',
  props: {
    deviceStore: {
      type: Object,
      default: null,
    }
  },
  components: {SomethingWrong, Preloader},
  data() {
    return {
      interfaceId: null,
      loading: true,
      error: '',
      view: null,
      deviceCalling: deviceCallingSync(randomId(12)),
    }
  },
  async mounted() {
    console.log("Loading InterfaceInfo")
    if (typeof this.$route.params.interface === 'undefined' || typeof this.$route.params.id === 'undefined') {
      this.interfaceId = null
    } else {
      this.interfaceId = this.$route.params.interface
      let iface = await this.getInterfaceData(this.interfaceId)
      this.processLoadComponent(this.deviceStore.device.model, iface)
      this.setRouteName(iface)
    }

  },
  deactivated() {
      this.view = null
  },
  watch: {
    'deviceStore.device': {
      handler(n) {
        if(this.$route.name === 'device_iface_dashboard') {
          this.reload()
        }
      },
      deep: true,
    },
    '$route': {
      handler(n, o) {
        if (n.params.interface !== o.params.interface) {
          console.log(`Interface changed from ${o.params.interface} to ${n.params.interface}, reloading info...`)
          this.reload()
        }
      },
      deep: true,
    },
  },
  methods: {
    async getInterfaceData(interfaceId) {
      let iface = this.deviceStore.getInterfaceByBindKey(interfaceId)
      if(iface) {
        console.log("Interface for component chooser found", iface)
        return iface
      }
      let data = null
      await this.$api.get(`/switcher-core/cache/parse_interface/${this.deviceStore.device.id}?interface=${interfaceId}`, null, true).then(r => {
        data = r.data
      }).catch(e => {
        console.log(e)
      })
      return  data
    },
    async reload() {
      if (typeof this.$route.params.interface === 'undefined' || typeof this.$route.params.id === 'undefined') {
        this.interfaceId = null
      } else {
        this.interfaceId = this.$route.params.interface
        let iface = await this.getInterfaceData(this.interfaceId)
        this.processLoadComponent(this.deviceStore.device.model, iface)
        this.setRouteName(iface)
      }
    },
    setRouteName(iface) {
      if (iface) {
        if (this.$auth.getSettings().device.breadcrumb_show === 'ip') {
          this.$setRouteMeta(`${this.deviceStore.device.ip} -> ${iface.name}`)
        } else {
          this.$setRouteMeta(`${this.deviceStore.device.name} -> ${iface.name}`)
        }
      } else {
        this.$setRouteMeta(this.$t('interfaces_not_loaded'))
      }
    },
    findComponentsByDeviceKey(model) {
      var components = null
      this.$config.deviceComponents.some(e => {
        if (e.deviceKeys && e.deviceKeys.indexOf(model.key) !== -1) {
          components = e
          return true
        }
        if (e.types && e.types.indexOf(model.type) !== -1) {
          components = e
          return true
        }
        return false
      })
      if (components === null) {
        console.log(`Model with key ${model.key} not supported by web panel`)
      }
      return components
    },
    processLoadComponent(model, iface) {
      this.view = null
      this.deviceCalling.resetMeta()
      let component = this.findComponentsByDeviceKey(model)
      if (component === null) {
        console.log(`Model with key ${model.key} not supported by web panel`)
        this.view = () => import('../DeviceNotSupportedByWebPanel.vue')
        return
      }
      var isAllModulesSupported = true
      component.needModules.forEach(e => {
        if (this.$config.enabledComponents.indexOf(e) === -1) {
          isAllModulesSupported = false
          console.log(`Module ${e} not found in system for load component!`)
        }
      })
      if (isAllModulesSupported) {
        console.log(`All need modules supported by agent, load interfaceInfo component`)
        if (component.components.interfacesInfo && iface) {
          component.components.interfacesInfo.forEach(tp => {
            if(tp.types.includes(iface.type)) {
              this.view = () => tp.component
              return true
            }
          })
        } else if(component.components.interfaceInfo) {
          this.view = () => component.components.interfaceInfo
        }

      }
      if(this.view == null){
        console.log(`Not all modules supported by agent!!!`)
        this.view = () => import('../DeviceNotSupportedByAgent.vue')
      }
    },
  },
}
</script>