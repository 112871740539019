<template>
  <div class="container-fluid">
    <FullscreenPreloader :show="loading"></FullscreenPreloader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header waves-effect waves-dark" style="padding-top: 6px; padding-bottom: 6px">
            {{ $t('control') }}
          </div>
          <div class="card-body" style="">
            <div class="row">
              <div class="col-sm-6 col-lg-5 col-xl-4 col-md-5">
                <div class="form-group">
                  <label class="mb-0">{{ $t('device') }}</label>
                  <v-select class="style-chooser" required v-model="form.device" label="label"
                            :placeholder="$t('device')"

                            :options="options.devices"></v-select>
                </div>
              </div>
              <div class="col-sm-4 col-lg-4 col-xl-3 col-md-4">
                <div class="form-group">
                  <label class="mb-0">{{ $t('interface') }}</label>
                  <v-select class="style-chooser" multiple required :disabled="form.device === null"
                            v-model="form.interfaces"
                            label="name" :hide-selected="true" :close-on-select="false"
                            :placeholder="$t('interface')"
                            :options="options.interfaces.filter(i => {
                               return !form.interfaces.includes(i)
                            })"></v-select>
                </div>
              </div>
              <div class="col-sm-4 col-lg-4 col-xl-3 col-md-4">
                <div class="form-group">
                  <label class="mb-0">{{ $t('display_by_status') }}</label><br>
                  <label class="switch-small" title="Hide online"
                         style="margin-top: 5px; margin-bottom: 0; margin-right: 15px; padding-bottom: 0">
                    <input type="checkbox" v-model="form.display_by_status">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header waves-effect waves-dark" style="padding-top: 6px; padding-bottom: 6px">
            {{ $t('map') }}
          </div>
          <div class="card-body" style="padding: 0">
            <div style="height: 600px; width: 100%; overflow: hidden; z-index: 1 !important;" id="map"></div>
          </div>
        </div>
      </div>
    </div>
    <ModalForm :hide-footer="true" :title="$t('show_box')" :modal-width="1200" ref="editBoxModal" :adaptive="true">
      <template v-slot:content v-if="choosedBox">
        <div style="height: 100%; overflow-y: auto">
          <EditBox :box_id="choosedBox"  ></EditBox>
        </div>
      </template>
    </ModalForm>
  </div>
</template>

<script>
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import translations from "./translations";
import FullscreenPreloader from "../../components/FullscreenPreloader";
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter'
import ModalForm from "@/components/ModalForm.vue";
import EditBox from "@/views/PonBoxes/EditBox.vue";

export default {
  components: {EditBox, ModalForm, FullscreenPreloader,},
  data() {
    return {
      loading: true,
      objects: [],
      form: {
        device: null,
        interfaces: [],
        display_by_status: false,
      },
      options: {
        devices: [],
        interfaces: [],
      },
      map: null,
      data: null,
      choosedBox: null,
    }
  },
  watch: {
    'form.device': {
      handler(n) {
        if (n !== null) {
          if(!n.coordinates) {
            alert(this.$t('choose_device_coordinates_before_start_work_with_device'))
            this.form.device = null
            return
          }
          this.removeAllLayers()
          this.addDeviceMarker(n)
          this.configSettedDeviceFromForm(n)
        } else {
          this.removeAllLayers()
          this.form.interface = null
        }
      },
      deep: true,
    },

    'form.interfaces': {
      async handler(n) {
        this.objects = []
        if (n !== null) {
          await this.loadObjectsByInterfaces(n)
          this.reinitLayers()
        } else {
          this.removeAllLayers()
          if (this.form.device) {
            this.addDeviceMarker(this.form.device)
          }
        }
      },
      deep: true,
    },
    'form.display_by_status': {
      handler(n) {
        this.reinitLayers()
      }
    },
    $route: {
      handler(n) {
        if(n.name === 'pon_boxes_map') {
          this.$setRouteMeta(this.$t('pon_boxes.map'))
          if(n.query.iface_id) {
            this.initAllByInterfaceId(n.query.iface_id)
          }
        }
      },
      deep: true,
    }
  },
  async mounted() {

    this.initMap()
    this.$setRouteMeta(this.$t('pon_boxes.map'))
    await this.loadDevices()
    if(this.$route.query.iface_id) {
       await this.initAllByInterfaceId(this.$route.query.iface_id)
    }
    this.loading = false
  },

  methods: {
    async initAllByInterfaceId(ifaceId) {
      this.loading = true
      this.objects = []
      let template = null
      await this.$api.get('/component/pon_boxes/template/by-interface/' + ifaceId).then(r => {
        template = r.data
       })
      this.options.devices.some(dev => {
        if(dev.id === template.interface.device.id) {
          this.form.device = dev
          return true
        }
        return false
      })
      this.form.interfaces = [template.interface]
      await this.$api.get('/component/pon_boxes/object/by-template/' + template.id).then(r => {
        r.data.forEach(elem => {
          elem.onts = []
          elem.template = template
          this.objects.push(elem)
        })
      })
      this.loading = false
    },
    addDeviceMarker(device) {
      let svgIcon = window.L.divIcon({
        html: this.$config.components.pon_boxes.configuration.icons.OLT,
        className: "",
        iconSize: [32, 32],
        iconAnchor: [17, 17],
      });
      let marker = window.L.marker([device.coordinates.lat, device.coordinates.lon], {icon: svgIcon})
      marker.device = device
      marker.addTo(this.map)
    },
    removeAllLayers() {
      let layers = this.findLayers(this.map)
      for (let i = 0; i < layers.length; i++) {
        this.map.removeLayer(layers[i]);
      }
    },
    setView(lat, lon, zoom) {
      this.map.setView(new window.L.LatLng(lat, lon), zoom);
    },
    generatePopupFromProperties(layer) {
      let html = '<div>'
      if(!layer.properties) {
        return `<div>Error loading info</div>`
      }
      if (layer.properties && layer.properties.name) {
        html += `<div style="text-align: center; min-width: 120px; width: 100%; font-weight: bold">${layer.properties.name}</div>`
      }
      if (layer.properties.type && layer.properties.type.name) {
        html += `<div style="color: gray">${this.$t('type')}: <b>${layer.properties.type.name}</b></div>`
      }
      if (layer.properties.template && layer.properties.template.interface) {
        html += `<div style=" "><small>${this.$t('interface')}: <b>${layer.properties.template.interface.name}</b></small></div>`
      }
      if (layer.properties.type && layer.properties.type.type === 'box'  && !layer.properties.status) {
        html += `<div style="color: darkgray">${this.$t('no_binded_onts_for_mon')}</b></div>`
      }
      if (layer.properties.type && layer.properties.type.type === 'box'  && layer.properties.status) {
        if(layer.properties.status === 'up') {
          html += `<div style="color: darkgreen">${this.$t('status')}: <b>OK</b></div>`
        }
        if(layer.properties.status === 'down') {
          html += `<div style="color: darkred">${this.$t('status')}: <b>${this.$t('problem')}</b></div>`
        }

        if (layer.properties.type && layer.properties.type.type === 'box' && layer.properties.count_interfaces) {
          html += `<div style="color: gray"><small>${this.$t('mon_interfaces')}: <b>${layer.properties.count_interfaces}</b></small></div>`
        }
      }
      html += `</div>`
      html += `<div style="display: flex; flex-wrap: wrap">
                    <div style="flex: 1 1 60px; text-align: center;  ">
                      <a href="javascript:void(0)" onclick="window.$map.openBox(${layer.properties.id})">
                         <i class="mdi mdi-file-edit" style="font-size: 24px"></i>
                      </a>
                    </div>
                </div>`
      return html
    },
    stylingMarker(marker, style = {
      width: 24,
      fill: '#052e77',
      stroke: '#91c3ff',
      strokeWidth: 3,
    }) {

      let radius = style.width / 2
      let circleRadius = radius - style.strokeWidth
      let strokeColor = style.stroke
      let strokeWidth = style.strokeWidth
      let realWidth = (strokeWidth + style.width)
      let html = ``
      if(this.form.display_by_status && marker.properties) {
        console.log("display by status enabled")
        if(!marker.properties.status ) {
          strokeColor = '#797979'
        } else if (marker.properties.status === 'down') {
          strokeColor = '#9b0000'
          strokeWidth += 1
          realWidth = (strokeWidth + style.width)
          circleRadius = radius - strokeWidth
          html = `
<svg xmlns="http://www.w3.org/2000/svg" width="${realWidth}" height="${realWidth}" version="1.1">

  <circle cx="${radius - 1}" cy="${radius - 1}" r="${circleRadius}" stroke="${strokeColor}" stroke-width="${strokeWidth}" fill="${style.fill}"></circle>
</svg>`
        } else {
          strokeColor = '#0e6200'
          html = `
<svg xmlns="http://www.w3.org/2000/svg" width="${realWidth}" height="${realWidth}" version="1.1">
  <circle cx="${radius - 1}" cy="${radius - 1}" r="${circleRadius}" stroke="${strokeColor}" stroke-width="${strokeWidth}" fill="${style.fill}"></circle>
</svg>`
        }
      } else {
        html = `
<svg xmlns="http://www.w3.org/2000/svg" width="${realWidth}" height="${realWidth}" version="1.1">
  <circle cx="${radius - 1}" cy="${radius - 1}" r="${circleRadius}" stroke="${strokeColor}" stroke-width="${strokeWidth}" fill="${style.fill}"></circle>
</svg>`
      }
      console.log(html)
      let icon = window.L.divIcon({
        html: html,
        className: "svg-icon",
        iconSize: [style.width, style.width],
        iconAnchor: [radius, radius],
      });
      marker.setIcon(icon)
      return marker
    },
    stylingLine(line, style = {
      weight: 3,
      color: '#052e77',
    }) {
      line.setStyle(style)
      return line
    },
    openBox(id) {
      this.choosedBox = id
      this.$refs.editBoxModal.show()
    },
    findLayers(map) {
      var layers = [];
      map.eachLayer(layer => {
        if (
            layer instanceof window.L.Polyline ||
            layer instanceof window.L.Marker ||
            layer instanceof window.L.Circle ||
            layer instanceof window.L.CircleMarker
        ) {
          layers.push(layer);
        }
      });

      // filter out layers that don't have the leaflet-geoman instance
      layers = layers.filter(layer => !!layer.pm);

      // filter out everything that's leaflet-geoman specific temporary stuff
      layers = layers.filter(layer => !layer._pmTempLayer);

      return layers;
    },
    initMap() {
      window.$map = this
      var startPoint = [this.$config.map.coordinates.lat, this.$config.map.coordinates.lon];

      let osm = window.L.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: 'Data \u00a9 <a href="http://www.openstreetmap.org/copyright"> OpenStreetMap Contributors </a> Tiles \u00a9 HOT'
      });
      let otm = window.L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: 'Data \u00a9 <a href="http://www.openstreetmap.org/copyright"> OpenStreetMap Contributors </a> Tiles \u00a9 HOT'
      });

      let google = window.L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        attribution: '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        subdomains:['mt0','mt1','mt2','mt3']
      });
      let googleH = window.L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        attribution: '&copy; <a target="_blank" href="https://www.google.com/maps">Google maps</a>',
        subdomains:['mt0','mt1','mt2','mt3']
      });
      let baseMaps = {
        "Google (Street)": google,
        "Google (Hybrid)": googleH,
        "OpenStreetMap": osm,
        "OpenTopoMap": otm,
      };

      let activeLayer = google
      if(this.$config.map.default_tile && baseMaps[this.$config.map.default_tile]) {
        activeLayer = baseMaps[this.$config.map.default_tile]
      }

      let map = window.L.map('map', {
        pmIgnore: false,
        snappable: true,
        pinning: true,
        allowSelfIntersection: false,
        requireSnapToFinish: true,
        layers: [activeLayer],
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topleft'
        }
      });
      window.L.control.layers(baseMaps).addTo(map);

      new SimpleMapScreenshoter().addTo(map)
      map.setView(startPoint, 16)
      map.pm.setLang(this.$t('lang_short'), translations[this.$t('lang_short')], this.$t('lang_short'));

      this.map = map
    },
    reinitLayers() {
      console.log("REINIT LAYERS")
      this.removeAllLayers()
      this.addDeviceMarker(this.form.device)
      this.objects.forEach((elem) => {
        let layer = null
        if (elem.type.type === 'box') {
          if (elem.type && elem.type.style) {
            layer = window.L.marker(elem.coordinates)
            layer.properties = elem
            this.stylingMarker(layer, elem.type.style)
            layer.addTo(this.map)
          }
        } else if (elem.type.type === 'line') {
          if (elem.type && elem.type.style) {
            layer = new window.L.Polyline(elem.coordinates);
            layer.properties = elem
            this.stylingLine(layer, elem.type.style)
            layer.addTo(this.map)
          }
        }
        layer.bindPopup(this.generatePopupFromProperties(layer))
      })
    },
    async loadDevices() {
      await this.$api.get('/component/pon_boxes/options/devices').then(r => {
        this.options.devices = []
        r.data.forEach(e => {
          e.label = e.ip + ' - ' + e.name
          this.options.devices.push(e)
        })
      })
    },
    async loadInterfaces(device) {
      await this.$api.get(`/component/pon_boxes/options/interfaces/${device.id}`).then(r => {
        this.options.interfaces = r.data
      })
    },
    async configSettedDeviceFromForm(n) {
      this.form.interface = null
      if (n !== null) {
        if (n.coordinates === null) {
          alert(this.$t('error_choose_device_must_contain_coordinates'))
          this.form.device = null
          return
        }
        this.removeAllLayers()
        this.addDeviceMarker(n)
        this.setView(n.coordinates.lat, n.coordinates.lon, 16)
        await this.loadInterfaces(n)
        if (this.options.interfaces.length === 0) {
          alert(this.$t('pon_ports_not_found_in_storage'))
        }
      }
    },
    async loadObjectsByInterfaces(ifaces) {
      this.loading = true
      let template = null
      this.objects = []

      for (let i = 0; i < ifaces.length; i++) {
        try {
          await this.$api.get('/component/pon_boxes/template/by-interface/' + ifaces[i].id).then(r => {
            template = r.data
          })
          await this.$api.get('/component/pon_boxes/object/by-template/' + template.id).then(r => {
            r.data.forEach(elem => {
              elem.template = template
              this.objects.push(elem)
            })
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.loading = false
    },
  }
}
// https://leafletjs.com/examples/geojson/example.html - Пример использования импорта geoJSON
// https://stackblitz.com/edit/leaflet-editable?file=script.js,Leaflet.Editable.js - Пример перемещения
// https://aratcliffe.github.io/Leaflet.contextmenu/examples/index.html - контекст-меню
// https://brunob.github.io/leaflet.fullscreen/ - Fullscreen
// https://prominentedge.com/leaflet-measure-path/ - Measure plugin
// https://makinacorpus.github.io/Leaflet.Snap/ - липкие линии
// https://dwilhelm89.github.io/Leaflet.StyleEditor/ - стилизация элементов

// Группировка элементов
// http://ghybs.github.io/Leaflet.FeatureGroup.SubGroup/examples/subGroup-markercluster-controlLayers-realworld.388.html - группировка элементов
// https://leaflet.github.io/Leaflet.markercluster/example/marker-clustering-realworld.388.html - группировка
// http://sintef-9012.github.io/PruneCluster/examples/random.10000-size.html
</script>

<style>
.leaflet-popup-content {
  margin: 10px !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 6px !important;
}

</style>