<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <Card :name="$t('filters')" :show="true">
          <div class="row">
            <div class="col-sm-12 col-lg-6 col-xl-3 col-md-12" style="min-width: 330px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('time_range') }}</label>
                <date-picker v-model="filter.range" type="datetime" range :showSecond="false"
                             style="width: 100%"></date-picker>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-3 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('device') }}</label>
                <v-select class="style-chooser" :placeholder="$t('choose_device')"
                          v-model="filter.device" label="displayName" :options="deviceList"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-12 col-xl-4 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('contain_text') }}</label>
                <input type="text" v-model="filter.query" class="form-control" :placeholder="$t('start_write_for_filter')">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">{{ $t('names') }}</label>
            </div>
            <div class="col-sm-3 col-md-2 col-lg-2  col-6">
              <button style="margin-bottom: 3px; " class="btn btn-sm btn-success btn-block"
                      @click="objectNames.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                {{ $t('select_all') }}
              </button>
            </div>
            <div class="col-sm-3 col-md-2 col-lg-2 col-6">
              <button style="margin-bottom: 3px; " class="btn btn-sm btn-secondary btn-block"
                      @click="objectNames.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                {{ $t('unselect_all') }}
              </button>
            </div>
            <div class="col-sm-6 col-md-2 col-lg-4 col-12">
              <input class="form-control form-control-sm" style="margin-bottom: 3px;" :placeholder="$t('object_filter')" v-model="objectNamesFilter">
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
              <div style="display: inline">
                <a v-for="action in objectNames.filter(e => {return objectNamesFilter === '' || e.name.includes(objectNamesFilter)})"
                   :key="action.name"
                   :class="'badge badge-pill a-to-badge' + (action.selected ? ' badge-success':' badge-secondary')"
                   style="font-size: 15px; margin: 3px"
                   @click="action.selected = !action.selected"
                   href="javascript:void(0)"
                >
                  <i class="fa fa-plus" v-if="!action.selected"></i>
                  <i class="fa fa-minus" v-if="action.selected"></i>
                  {{ action.name }}
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-7 col-xl-7"></div>
            <div class="col-sm-6 col-lg-3 col-xl-3">
            </div>
            <div class="col-sm-6 col-lg-2 col-xl-2">
              <button @click="loadData()" style="margin-top: 10px" class="btn btn-sm btn-block btn-outline-info"><i
                  class="mdi mdi-refresh"></i> {{ $t('reload_info') }}
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-12 col-lg-12 col-md-12">
        <Preloader v-if="loading"/>
        <v-server-table :columns="table.columns" :options="table.options" ref="table"  v-if="displayTable"
                        v-show="!loading"
                        @loading="loading = true"
                        @loaded="loading = false"
        >
          <template v-slot:child_row="{row}">
            <div class="row" style="margin-left: 0; margin-right: 0; margin-top: 5px; padding: 0"  >
              <div class="col-6">
                <div class="row">
                  <div class="col-sm-2">{{ $t('name') }}</div>
                  <div class="col-sm-10" >
                    {{ row.name }}
                  </div>
                </div>
                <div class="row"  >
                  <div class="col-sm-2">{{ $t('object') }}</div>
                  <div class="col-sm-10" >
                    {{ row.object }}
                  </div>
                </div>
                <div class="row"   v-if="row.description">
                  <div class="col-sm-2">{{ $t('description') }}</div>
                  <div class="col-sm-10" >
                    {{ row.description }}
                  </div>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-2">
                <button class="btn btn-sm btn-default btn-block" @click="exportAsObject(row)"><i class="mdi mdi-export"></i> {{$t('traps.export')}}</button>
              </div>
            </div>
            <div class="row" style="margin-left: 0; margin-right: 0;  padding: 0"  v-if="row.modules && row.modules.length !== 0">
              <div class="col-12"><hr style="margin-top: 3px; margin-bottom: 3px"></div>
              <div class="col-sm-2">{{ $t('modules') }}</div>
              <div class="col-sm-10" >
                <json-tree :data="row.modules" :level="1" ></json-tree>
              </div>
            </div>
            <div class="row" style="margin-left: 0; margin-right: 0;  padding: 0"  v-if="row.parsed && row.parsed.length !== 0">
              <div class="col-12"><hr style="margin-top: 3px; margin-bottom: 3px"></div>
              <div class="col-sm-2">{{ $t('parsed_oids') }}</div>
              <div class="col-sm-10" >
                <json-tree :data="row.parsed" :level="0" ></json-tree>
              </div>
            </div>
            <div class="row" style="margin-left: 0; margin-right: 0;  padding: 0"  v-if="row.raw && row.raw.length !== 0">
              <div class="col-12"><hr style="margin-top: 3px; margin-bottom: 3px"></div>
              <div class="col-sm-2">{{ $t('raw_data') }}</div>
              <div class="col-sm-10" >
                <json-tree :data="row.raw" :level="0" ></json-tree>
              </div>
            </div>
            <div class="row" style="margin-left: 0; margin-right: 0;  padding: 0"  v-if="row.errors && row.errors.length !== 0">
              <div class="col-12"><hr style="margin-top: 3px; margin-bottom: 3px"></div>
              <div class="col-sm-2">{{ $t('errors') }}</div>
              <div class="col-sm-10" >
                <li v-for="(error, id) in row.errors" :key="id">{{error}}</li>
              </div>
            </div>
          </template>
          <template v-slot:id="{row}">
            <div>{{ row.id }}</div>
          </template>
          <template v-slot:created_at="{row}">
            <div style="min-width: 70px;">{{ row.created_at }}</div>
          </template>
          <template v-slot:name="{row}">
            <div style="min-width: 70px;">{{ row.name }}</div>
          </template>
          <template v-slot:device="{row}">
            <div v-if="row.device !== null">
              <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}"> {{ row.device.ip }}</router-link>
            </div>
            <div v-else>{{ $t('no_device') }}</div>
          </template>
          <template v-slot:interface="{row}">
            <div v-if="row.interface !== null">
                <router-link :to="{name: 'device_iface_dashboard', params: {id: row.device.id, interface: row.interface.bind_key}}">
                  {{ row.interface.name }}
                </router-link>
            </div>
            <div v-else style="color: gray">{{ $t('no_interface') }}</div>
          </template>
          <template v-slot:object="{row}">
            <div style="min-width: 250px;">{{ row.object }}</div>
          </template>
          <template v-slot:has_errors="{row}">
            <div><input type="checkbox"  onclick="return false;" style="accent-color: #962f2f; height: 18px; width: 18px" :checked="row.errors && row.errors.length !== 0"></div>
          </template>
          <template v-slot:has_modules="{row}">
            <div><input type="checkbox"   onclick="return false;"  style=" height: 18px; width: 18px" :checked="row.modules && row.modules.length !== 0"></div>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"

export default {
  components: {
    Card,
    Preloader,
    DatePicker,
  },
  data() {
    return {
      objectNamesFilter: '',
      displayTable: false,
      filter: {
        range: [],
        query: '',
        device: null,
        names: [],
      },
      loading: false,
      objectNames: [],
      usersList: [],
      deviceList: [],
      table: {
        columns: ['id', 'created_at', 'name','object', 'device', 'interface',  'has_errors', 'has_modules'],
        options: {
          requestFunction: (data) => {
            console.log(data)
            data.names = this.filter.names
            data.start = moment(this.filter.range[0]).format("YYYY-MM-DD HH:mm:ss")
            data.end = moment(this.filter.range[1]).format("YYYY-MM-DD HH:mm:ss")
            data.device = this.filter.device
            data.query = this.filter.query
            data.not_resolved = this.filter.only_not_resolved
            data.severity = this.filter.severity
            return this.$api.post('/component/trapservice/history', data).catch(function (e) {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          rowClassCallback(row) {
            if(row.resolved_at !== null) {
              return  'row-resolved'
            }
            return ''
          },
          perPage: 50,
          perPageValues: [50, 100, 300, 500],
          skin: 'VueTables__table table table-sm table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            created_at: this.$t('time'),
            name: this.$t('name'),
            description: this.$t('description'),
            severity: this.$t('severity'),
            resolved_at: this.$t('resolved_at'),
          },
          sortable: ['id', 'created_at', 'severity', 'device'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  watch: {
    filter: {
      handler() {
        console.log(this.filter.range)
        if(this.filter.range[0] === null || this.filter.range[1] === null) {
          var today = new Date();
          var priorDate = new Date(new Date().setDate(today.getDate() - 30));
          this.filter.range = [
            priorDate,
            today,
          ]
        }
        this.loadData()
      },
      deep: true,
    },
    objectNames: {
      handler(n) {
        var tagged = []
        n.forEach(e => {
          if (e.selected) {
            tagged.push(e.name)
          }
        })
        this.filter.names = tagged
      },
      deep: true,
    }
  },
  created() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 30));
    this.filter.range = [
      priorDate,
      today,
    ]
  },
  async mounted() {
    if (typeof this.$route.query.device_id !== 'undefined') {
      console.log("DeviceID setted")
      this.filter.device = await this.getDevice(this.$route.query.device_id)
    }
    if (typeof  this.$route.query.only_not_resolved !== 'undefined') {
      this.filter.only_not_resolved = true
    }
    if (typeof  this.$route.query.severity !== 'undefined') {
      this.filter.severity = this.$route.query.severity
    }
    this.$setRouteMeta(this.$t('traps.name'))
    await this.loadObjects()
    await this.loadDevicesList()
    if (typeof  this.$route.query.event_name !== 'undefined') {
      this.objectNames.forEach(n => {
        if(this.$route.query.event_name === n.name) {
          n.selected = true
        }
      })
    }
    this.displayTable = true
  },
  methods: {
    exportAsObject(object) {
       let obj = {
         host: object.device.ip,
         community: '<COMMUNITY>',
         object: object.object,
         data: object.raw
       }
       console.log(object)
       console.log(obj)
       this.download(JSON.stringify(obj), 'trap-' + obj.object + '.json', 'application/json')
    },
    download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], {type: contentType});
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },

    async loadObjects() {
      await this.$api.get('/component/trapservice/history/params/object-names').then(r => {
        this.objectNames = []
        r.data.forEach(e => {
          this.objectNames.push({
            name: e,
            selected: false,
          })
        })
      }).catch(() => {
      })
    },

    async loadDevicesList() {
      await this.$api.get('/device/options').then(r => {
        this.deviceList = []
        r.data.forEach(elem => {
          if (elem.name.trim() === '') {
            elem.name = this.$t('no_name')
          }
          elem.displayName = `${elem.ip} - ${elem.name}`
          this.deviceList.push(elem)
        })
      }).catch(() => {
      })
    },
    async getDevice(deviceId) {
      let data = {}
      await this.$api.get('/device/' + deviceId).then(r => {
        r.data.displayName = `${r.data.ip} - ${r.data.name}`
        data = r.data
      }).catch(() => {
      })
      return data
    },
    loadData() {
      console.log("Refresh table")
      this.loading = true
      if (typeof this.$refs.table !== 'undefined') {
        this.$refs.table.refresh()
      }
    }
  }
}
</script>
<style scoped >

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

</style>