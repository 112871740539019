<template>
  <div >
    <FullscreenPreloader v-if="variables === null && loadingVariables"></FullscreenPreloader>
    <div class="row">
      <div class="col-12 col-mini">
        <div class="row">
          <div class="col-12 col-mini" v-html="$t('macros.variable_block')" style="margin-bottom: 15px; "></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('onts_registration.device') }}</label>
              <v-select class="style-chooser" v-model="device" label="displayName" :options="formOptions.devices"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('onts_registration.unregisteredOnts') }}</label>
              <v-select class="style-chooser" v-model="selectedOnt"  label="_display_name" :options="unregisteredOnts"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <br>
              <button class="btn btn-info" @click="loadVariables">{{$t('select')}}</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="variables">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <ParametersForm v-model="parameters" :form-declaration="macro.parameters" :vars="variables" v-if="variables"></ParametersForm>
        <div class="row" v-if="variables !== null">
          <div class="col-sm-12">
            <h4>{{ $t('macros.variables') }}</h4>
            <div style="overflow-y: auto; max-height: 400px; background: #FAFAFA; border: 1px solid #DADADA">
              <json-tree :data="variables" :level="1"></json-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12  col-mini">
        <hr>
      </div>
      <div class="col-12  col-mini d-flex align-items-center justify-content-between">
        <h4>{{ $t('macros.template_block') }}</h4>
      </div>
      <div class="col-12  col-mini"  >
        <div class="row"  >
          <div class="col-sm-12 col-md-12 col-lg-6  col-xl-6" style="">
            <div style="font-weight: bold"><br> </div>
            <pre><code contenteditable @input="changeTemp" class="language-twig">{{ item.template }}</code></pre>
          </div>
          <div class="col-sm-12  col-md-12 col-lg-6 col-xl-6" style="">
            <div style="font-weight: bold">{{ $t('macros.live_result') }}</div>
            <Preloader v-if="live_result.loading" />
            <div class="" v-else-if="live_result.error !== null" style="text-align: center">
              <br>
              <h3>{{ $t('macros.template_compile_problem') }}<br>
                <small>{{ live_result.error.response.data.error.description }}</small>
              </h3>
            </div>
            <pre v-if="!live_result.loading && live_result.error === null"><code class="language-twig">{{ live_result.result }}</code></pre>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px" class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12" v-html="$t('macros.twig_easy_doc')"></div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import ParametersForm from "@/components/Macros/ParametersForm.vue";
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";

export default {
  components: {FullscreenPreloader, ParametersForm, Preloader},
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    formOptions: {
      type: Object,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      loadingVariables: false,
      device: null,
      unregisteredOnts: [],
      selectedOnt: null,
      parameters: {},
      variables: null,
      parameter: null,
      live_result: {
        loading: false,
        result: '',
        error: null,
        controller: new AbortController(),
      },
      macro: null,
    }
  },
  watch: {
    parameters: {
      handler(n) {
        if (!!this.variables && typeof this.variables.params !== 'undefined') {
          this.variables.params = n
          this.renderTemplate(this.macro.template, true)
        }
      },
      deep: true,
    },
    'macro.template': {
      handler(n) {
        this.renderTemplate(this.macro.template)
      },
      deep: true,
    },
    device: {
      async handler(n) {
        this.unregisteredOnts = []
        this.variables = null
        this.selectedOnt = null
        if (n !== null) {
          await this.loadUnregisteredOnts()
        }
      },
      deep: true,
    },
    selectedOnt: {
      handler() {
        this.variables = null
      },
      deep: true,
    },
  },
  async mounted() {
    this.macro = this.item
    this.$nextTick(() => {
      document.querySelectorAll('pre code.language-twig').forEach((el) => {
        window.hljs.highlightElement(el);
      });
    })
  },

  methods: {
    changeTemp(el) {
      this.macro.template = el.target.innerText
    },
    renderTemplate(template, disableCheck = false) {
      if (this.variables === null) {
        return
      }
      this.live_result.loading = true
      setTimeout(() => {
        if(this.live_result.result && this.macro.template !== template && !disableCheck) {
          return
        }
        this.$api.post(this.apiComponentName + '/preview', {
          device: {
            id: this.device.id
          },
          ont: this.selectedOnt._ident,
          params: this.parameters,
          template: this.item.template,
        }, true,  ).then(r => {
          this.live_result.error = null
          this.live_result.result = r.data
        }).catch(e => {
          this.live_result.error = e
        }).finally(() => {
          this.live_result.loading = false
          this.$nextTick(() => {
            document.querySelectorAll('pre code.language-twig').forEach((el) => {
              if (!el.classList.contains('hljs')) {
                window.hljs.highlightElement(el);
              }
            });
          })
        })
      },200)

    },
    async loadUnregisteredOnts() {
      await this.$api.get( '/component/onts_registration/unregistered/'+this.device.id).then(r => {
        this.unregisteredOnts = []
        r.data.forEach(el => {
          el._display_name = `${el.interface.name} - ${el._ident}`
          this.unregisteredOnts.push(el)
        })
      }).catch(e => {
        console.log(e)
      })
    },
    async loadVariables() {
      console.log(this.selectedOnt)
      if(!this.selectedOnt) {
        this.$noty.warning(this.$t('onts_registration.please_choose_unregistered_ont'))
        return
      }
      this.loadingVariables = true
      const { data } =  await this.$api.post( '/component/onts_registration/variables', {
        device: {
          id: this.device.id
        },
        ont: this.selectedOnt._ident
      });
      this.variables = data

      this.loadingVariables = false
    },
  },
}
</script>


<style>
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  width: 100%;
  height: 450px;
}

.height-300 {
  height: 300px;
}


/* for block of numbers */
.hljs-ln-numbers {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  color: #ccc;
  border-right: 1px solid #CCC;
  vertical-align: top;
  padding-right: 5px !important;

  /* your custom style here */
}

/* for block of code */
.hljs-ln-code {
  padding-left: 10px !important;
}
</style>
