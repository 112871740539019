<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <Card :name="$t('filters')" :show="true">
          <div class="row">
            <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6" style="min-width: 330px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('time_range') }}</label>
                <date-picker v-model="filter.range" type="datetime" range :showSecond="false"
                             style="width: 100%"></date-picker>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-3 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('user') }}</label>
                <v-select class="style-chooser" multiple
                          v-model="filter.users" label="name" :options="usersList"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-3 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('device') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.device" label="displayName" :options="deviceList"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-8 col-xl-4 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('contain_text') }}</label>
                <input type="text" v-model="filter.query" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">{{ $t('modules') }}</label>
            </div>
            <div class="col-sm-6">
              <button style="margin: 3px" class="btn btn-sm btn-success"
                      @click="modulesList.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                {{ $t('select_all') }}
              </button>
              <button style="margin: 3px" class="btn btn-sm btn-secondary"
                      @click="modulesList.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                {{ $t('unselect_all') }}
              </button>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
              <div style="display: inline">
                <a v-for="action in modulesList.filter(e => {return e.name.indexOf(actionQuery) !== -1})"
                   :key="action.name"
                   :class="'badge badge-pill a-to-badge' + (action.selected ? ' badge-success':' badge-secondary')"
                   style="font-size: 15px; margin: 3px"
                   @click="action.selected = !action.selected"
                   href="javascript:void(0)"
                >
                  <i class="fa fa-plus" v-if="!action.selected"></i>
                  <i class="fa fa-minus" v-if="action.selected"></i>
                  {{ action.name }}
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-10 col-xl-10"></div>
            <div class="col-sm-6 col-lg-2 col-xl-2">
              <button @click="loadData(filter)" style="margin-top: 10px" class="btn btn-sm btn-block btn-outline-info">
                <i class="mdi mdi-refresh"></i> {{ $t('reload_info') }}
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-mini col-12 col-lg-12 col-md-12">
            <Preloader v-if="loading"/>
            <v-client-table v-model="data" :columns="table.columns" :options="table.options"
                            :style="'margin-top: 5px; position: relative;  z-index: 1; ' "
                            v-if="!loading">
              <template v-slot:child_row="{row}">
                <div class="row" v-for="(data, key) in row" :key="key" style="margin: 0; padding: 0">
                  <div class="col-sm-2" style="margin: 0; padding: 0">{{ key }}</div>
                  <div class="col-sm-10" style="margin: 0; padding: 0"
                       v-if="['telnet_output', 'error'].indexOf(key) === -1">
                    <json-tree :data="data" :level="1"></json-tree>
                  </div>
                  <div class="col-sm-10" style="margin: 0; padding: 0" v-if="key === 'telnet_output'">
                    <pre>{{ data }}</pre>
                  </div>
                  <div class="col-sm-10" style="margin: 0; padding: 0" v-if="key === 'error'">
                    Message: <b>{{ data.message }}</b><br>
                    File: <b>{{ data.file }}:{{ data.line }}</b><br>
                    Trace: <br>
                    <pre>{{ data.trace }}</pre>
                  </div>

                </div>
              </template>
              <template v-slot:user="{row}">
                <router-link :to="{name: 'management_user_edit', params: {id: row.user.id}}">{{ row.user.name }}
                </router-link>
              </template>
              <template v-slot:arguments="{row}">
                {{ row.arguments }}
              </template>
              <template v-slot:device="{row}">
                <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}">{{
                    row.device.name
                  }}<br><small>({{ row.device.ip }})</small>
                </router-link>
              </template>
              <template v-slot:status="{row}">
                <span
                    :style="row.status === 'FAILED' ? 'color: darkred; font-weight: bold' : ''">{{
                    $t("log_statuses." + row.status)
                  }}</span>
              </template>
              <template v-slot:module="{row}">
                <b>{{ row.module }}</b>
              </template>
            </v-client-table>
          </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"

export default {
  components: {
    Card,
    Preloader,
    DatePicker,
  },
  data() {
    return {
      data: [],
      filter: {
        range: [],
        modules: [],
        users: [],
        only_failed: false,
        query: '',
        device: {
          id: null,
          name: "",
        },
      },
      preloading: true,
      loading: false,
      actionQuery: '',
      modulesList: [],
      usersList: [],
      deviceList: [],
      table: {
        columns: ['id', 'time', 'device', 'module', 'arguments', 'user', 'status'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table table-sm',
          perPage: 50,
          perPageValues: [50, 100, 300, 500],
          headings: {
            id: 'Id',
            time: this.$t('time'),
            'module': this.$t('module'),
            device: this.$t('device'),
            user: this.$t('user'),
            arguments: this.$t('arguments'),
            status: this.$t('status'),
          },
          sortable: ['id', 'time', 'device', 'module', 'arguments', 'user', 'status'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  watch: {
    filter: {
      handler(n) {
        this.loadData(n)
      },
      deep: true,
    },
    modulesList: {
      handler(n) {
        var tagged = []
        n.forEach(e => {
          if (e.selected) {
            tagged.push(e.name)
          }
        })
        this.filter.modules = tagged
      },
      deep: true,
    }
  },
  created() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 1));
    this.filter.range = [
      priorDate,
      today,
    ]
  },
  async mounted() {
    this.$setRouteMeta(this.$t('switcher_core_logs'))
    this.$api.enableSupportWaiting()
    this.loadModules()
    this.loadUsersList()
    this.loadDevicesList()
    if (typeof this.$route.query.device_id !== 'undefined') {
      console.log("DeviceID setted")
      this.filter.device = await this.getDevice(this.$route.query.device_id)
    }
    await this.$api.waitResponses()

    this.preloading = false
    this.loadData(this.filter)
  },
  methods: {
    async loadData(filters) {
      if(this.preloading) return
      console.log("Load data calling...")
      var users = []
      filters.users.forEach(e => {
        users.push(e.id)
      })
      let status = ''
      if (filters.only_failed) {
        status = 'FAILED'
      }
      let query = {
        start: moment(filters.range[0]).format("YYYY-MM-DD HH:mm:ss"),
        stop: moment(filters.range[1]).format("YYYY-MM-DD HH:mm:ss"),
        query: filters.query,
        users: users,
        modules: filters.modules,
        status: status,
        device_id: filters.device ? filters.device.id : null,
      }
      this.loading = true
      await this.$api.post('/logs/switcher-core/actions', query).then(r => {
        this.data = []
        r.data.forEach(d => {
          d.telnet_output = ""
          if (typeof d.meta.telnet_output !== 'undefined' && d.meta.telnet_output) {
            d.telnet_output = d.meta.telnet_output
            delete d.meta.telnet_output
          }
          if (typeof d.meta.error !== 'undefined' && d.meta.error) {
            d.error = d.meta.error
            delete d.meta.error
          }

          this.data.push(d)
        })
        this.data = r.data
      }).catch(() => {
      })
      this.loading = false
    },
    async loadModules() {
      await this.$api.get('/logs/switcher-core/supported-modules').then(r => {
        this.modulesList = []
        r.data.forEach(e => {
          this.modulesList.push({
            name: e,
            selected: false,
          })
        })
      }).catch(() => {
      })
    },
    async loadUsersList() {
      await this.$api.get('/user-list').then(r => {
        this.usersList = r.data
      }).catch(() => {
      })

    },
    async loadDevicesList() {
      await this.$api.get('/device/options').then(r => {
        this.deviceList = []
        r.data.forEach(elem => {
            if (elem.name.trim() === '') {
              elem.name = this.$t('no_name')
            }
            elem.displayName = `${elem.ip} - ${elem.name}`
            this.deviceList.push(elem)
        })
      }).catch(() => {
      })
    },
    async getDevice(deviceId) {
      let data = {}
      await this.$api.get('/device/' + deviceId).then(r => {
        r.data.displayName = `${r.data.ip} - ${r.data.name}`
        data = r.data
      }).catch(() => {
      })
      return data
    }
  }
}
</script>
<style>

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

.json-tree {
  padding-left: 10px !important;
}

.json-tree-key {
  font-size: 14px;
}
</style>