<template>
  <div class="container-fluid">
  <div class="row">
    <div class="col-12 col-mini col-sm-12" style="">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs" role="tablist" style="margin-bottom: -1px; z-index: 20">
        <li class="nav-item" v-for="(_, tab) in tabs" :key="tab">
          <a @click=setTab(tab) :class="'nav-link ' + (choosedTab === tab ? 'active' : '')" data-toggle="tab" :href="'#' + tab"
             role="tab">
            <span class="hidden-sm-up"></span>
            <span class="hidden-xs-down">{{ $t(`events.tabs.${tab}`) }}</span>
          </a>
        </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content tabcontent-border card-body">
        <div v-for="(component, tab) in tabs" :key="tab"
             :class="'tab-pane ' +  (choosedTab === tab ? 'active' : '')" :id="tab" role="tabpanel">
          <component :is="component"></component>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tabs: {
        alertmanager: () => import('./Configuration/Alertmanager'),
      },
      choosedTab: 'alertmanager',
    }
  },
  methods: {
    setTab(tabname) {
      this.$setRouteMeta(this.$t('event_configuration'))
      this.choosedSource = tabname
      this.$router.push({name: 'config_events', query: {tab: tabname}})
    },
  },
  mounted() {
    this.$setRouteMeta(this.$t('event_configuration'))
    if(typeof this.$route.query.tab !== 'undefined') {
      this.choosedTab = this.$route.query.tab
    }
  }
}
</script>
<style scoped>
.tab-content {
  border: 1px solid #dee2e6;
  background: #FFFFFF;
}
</style>