<template>
  <div class="row">
    <div class="col-sm-12">
     <small>{{$t('status')}}</small>
      <div class="row">
        <div class="col-4" style="align-content: center; text-align: center">
          <small><label class="mb-0">{{$t('online')}}</label></small><br>
          <input type="radio" value="online" v-model="filter.status" style="height: 24px; width: 24px">
        </div>
        <div class="col-4" style="align-content: center; text-align: center">
          <small><label class="mb-0">{{$t('offline')}}</label></small><br>
          <input type="radio" value="offline" v-model="filter.status" style="height: 24px; width: 24px">
        </div>
        <div class="col-4" style="align-content: center; text-align: center">
          <small><label class="mb-0">{{$t('all')}}</label></small><br>
          <input type="radio" value="all" v-model="filter.status" style="height: 24px; width: 24px">
        </div>
      </div>
    </div>
    <div class="col-sm-12">
     <small>{{$t('search_query')}}</small>
      <input class="style-chooser form-control"
                v-model="filter.query" >
    </div>
    <div class="col-sm-12">
      <small>{{$t('device_groups')}}</small>
      <v-select multiple class="style-chooser "
                :close-on-select="false"
                :placeholder="$t('device_groups')"
                prepend-icon="mdi mdi-account-multiple"
                :limit="3"
                v-model="filter.groups" label="name" :options="getDeviceGroups"></v-select>
    </div>
    <div class="col-sm-12">
      <small>{{$t('device_models')}}</small>
      <v-select multiple class="style-chooser"
                :close-on-select="false"
                :placeholder="$t('device_models')"
                :limit="3"
                prepend-icon="mdi mdi-account-multiple"
                v-model="filter.models" label="name" :options="getDeviceModels"></v-select>
    </div>
    <div class="col-sm-12">
      <small>{{ $t('sort_by') }}</small>
        <v-select v-model="filter.sort_by" label="name" class="style-chooser"
                  :options="sorts" :hide-selected="true" :clearable="false" :close-on-select="true"
                  :reduce="item => item.key"
        ></v-select>
    </div>
    <div class="col-sm-12">
      <small>{{ $t('show_groups') }}</small>

      <div class="row">
        <div class="col-4" style="align-content: center; text-align: center">
          <small><label class="mb-0">{{$t('yes')}}</label></small><br>
          <input type="radio" :value="true" v-model="filter.show_groups" style="height: 24px; width: 24px">
        </div>
        <div class="col-4" style="align-content: center; text-align: center">
          <small><label class="mb-0">{{$t('no')}}</label></small><br>
          <input type="radio" :value="false" v-model="filter.show_groups" style="height: 24px; width: 24px">
        </div>
      </div>
    </div>
    <div class="col-sm-12">
        <br>
          <button @click="clearFilter" class="btn btn-sm btn-danger" style="margin-right: 5px; margin-bottom: 5px;"><i class="mdi mdi-format-clear"></i> {{$t('clear_filters')}}</button>
          <button @click="saveFilter" class="btn btn-sm btn-default"  style="margin-right: 5px; margin-bottom: 5px;"><i class="mdi mdi-content-save"></i> {{$t('save')}}</button>
      <br>
      <transition name="fade">
      <span v-if="textMessage !== ''">{{textMessage}}</span>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
    props: {
      models: {
        type: Array,
        default: () => [],
      },
      groups: {
        type: Array,
        default: () => [],
      },
       sorts: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
          filter: {
            show_groups: null,
            sort_by: '',
            query: '',
            groups: [],
            models: [],
            status: 'all',
          },
        textMessage: '',
      }
    },
    watch: {
      filter: {
        handler(n) {
          this.updated()
        },
        deep: true,
      }
    },
    computed: {
      getDeviceGroups() {
        let groups = []
        if(!this.filter.groups) return  groups
        this.groups.forEach(group => {
          if (this.filter.groups.filter(e => {return group.id === e.id}).length === 0) {
            groups.push(group)
          }
        })
        return groups
      },
      getDeviceModels() {
        let models = []
        if(!this.filter.models) return  models
        this.models.forEach(model => {
          if (this.filter.models.filter(e => {return model.id === e.id}).length === 0) {
            models.push(model)
          }
        })
        return models
      },
    },
    methods: {
      updated() {
        this.$emit('update-filter', this.filter)
      },
      saveFilter() {
        this.textMessage = this.$t('saved')
        setTimeout(() => {
          this.textMessage = ''
        }, 1500)
        this.$localCfg.set('device_dashboard.filter', this.filter)
      },
      clearFilter() {
        this.filter = {
          models: [],
          groups: [],
          query: '',
          status: 'all',
          sort_by: '',
          show_groups: null
        }
        this.textMessage = this.$t('cleared')
        setTimeout(() => {
          this.textMessage = ''
        }, 1500)
        this.$localCfg.set('device_dashboard.filter', this.filter)

        this.filter.sort_by = this.$auth.getSettings().device_dashboard.sort_by;
        this.filter.show_groups = this.$auth.getSettings().device_dashboard.show_groups;
      }
    },
    mounted() {
      let cfg = this.$localCfg.get('device_dashboard.filter')
      if(cfg !== null) {
        this.filter = cfg
      }
      if(typeof this.$route.query.status !== 'undefined') {
        this.filter.status = this.$route.query.status
      }

      if(!this.filter.sort_by) {
        this.filter.sort_by = this.$auth.getSettings().device_dashboard.sort_by;
      }

      if(this.filter.show_groups === null) {
        this.filter.show_groups = this.$auth.getSettings().device_dashboard.show_groups;
      }
    }
}
</script>
