import { render, staticRenderFns } from "./BoxesPage.vue?vue&type=template&id=739fcfd3&scoped=true"
import script from "./BoxesPage.vue?vue&type=script&lang=js"
export * from "./BoxesPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739fcfd3",
  null
  
)

export default component.exports