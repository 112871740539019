<template>
  <modal @before-open="beforeOpen"
         @before-close="beforeClose"
         :name="modalId"
         :height="'auto'"
         :adaptive="adaptive"
         :maxHeight="maxHeight"
         :width="modalWidth"
         :scrollable="scrollable"
         :shiftY="0.1"
  >
    <div class="box-container" >
      <div class="header">
        <slot name="header" >
          <div v-if="title !==''" style="padding: 10px; background: #FAFAFA; border-bottom: 1px solid #DADADA">
            <h4 style="margin: 0; padding: 0">{{ title }}</h4>
            <a href="javascript:void(0)" @click="$modal.hide(modalId); " style="position: absolute; right: 10px; top: 4px; font-weight: bold; color: darkred">
              <i class="mdi mdi-24px mdi-close"></i>
            </a>
          </div>
        </slot>
      </div>
      <div class="content">
        <div style="background-color: rgba(255, 255, 255, .5); top: 0; left: 0; position: absolute; z-index: 10; width: 100%; padding-top: 70px; height: 100%; overflow: hidden; text-align: center; vertical-align: center" v-if="loading">
            <Preloader size="64"></Preloader>
        </div>
        <slot name="content" >
          <h3 align="center">Modal CONTENT</h3>
        </slot>
      </div>
      <div class="footer" v-if="!hideFooter"  style="overflow: hidden">
        <slot name="footer">
          <button :disabled="disabledButtons" @click="$modal.hide(modalId)" class="button-close"><i class="fa fa-window-close"></i> {{ closeBtn }}</button>
          <button :disabled="disabledButtons" @click="$emit('save')" class="button-save"><i class="fa fa-save"></i> {{ actionBtn }}</button>
        </slot>
      </div>
    </div>
  </modal>
</template>

<script>
import Preloader from "@/components/Preloader";
export default {
  components: {Preloader},
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    disabledButtons: {
      type: Boolean,
      default: false,
    },
    actionBtnName: {
      type: String,
      default:  '',
    },
    closeBtnName: {
      type: String,
      default:  '',
    },
    modalWidth: {
      type: [Number, String],
      default: 600,
    },
    modalMinHeight: {
      type: [Number, String],
      default: 500,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    adaptive: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    closeBtn() {
      if(this.closeBtnName !== '') return  this.closeBtnName
      return this.$t('close')
    },
    actionBtn() {
      if(this.actionBtnName !== '') return  this.actionBtnName
      return this.$t('save')
    }
  },
  data() {
    return {
      displayBlock: false,
      modalId: '',
    }
  },
  mounted() {
    this.modalId = this.makeid(10)
  },
  methods: {
    beforeOpen(event) {
      this.$emit('before-open', event)
    },
    beforeClose(event) {
      this.$emit('before-close', event)
    },
    show() {
      console.log(`Try open modal with id=${this.modalId}`)
      this.$modal.show(this.modalId)
    },
    hide() {
      this.$modal.hide(this.modalId)
    },
    makeid(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  }

}
</script>
<style>
.vm--modal {
  overflow: visible !important;
  height: auto !important;
}

.button-close:disabled {
  border: 0;
  background-color: #EAEAEA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #5c727f;
  outline: 0;
}
.button-save:disabled {
  border: 0;
  background-color: #EAEAEA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #5c727f;
  outline: 0;
}
.button-save:disabled {
  border: 0;
  background-color: #EAEAEA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #5c727f;
  outline: 0;
}
.button-close {
  border: 0;
  background-color: #FAFAFA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
}
.button-full {
  border: 0;
  background-color: #FAFAFA;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
}
.button-save {
  border: 0;
  background-color: #FAFAFA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #3e5569;
}
.button-close:focus {
  border: 0;
  background-color: #DADADA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
  outline: 0;
}
.button-save:focus {
  border: 0;
  background-color: #DADADA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #3e5569;
  outline: 0;
}
.button-close:hover {
  border: 0;
  background-color: #DADADA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
  outline: 0;
}
.button-save:hover {
  border: 0;
  background-color: #DADADA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #3e5569;
  outline: 0;
}

</style>

<style scoped>
.dropdown-menu {
  background-color: black;
}

.box-container {
  display: flex;
  flex-direction: column;
}

.header {
  height: 44px;
}

.content {
  flex: 1 0 auto;/*Три*/
}

.footer {
  height: 40px;
  padding: 0;
  margin: 0;
}

</style>
