<template>
  <div>
    <transition name="fade">
      <div id="pagetop" style="z-index: 990;border-radius: 5px; width: 48px; height: 48px; position: fixed; right: 15px; bottom: 15px; background-color: rgba(127,127,127, 0.3)" v-show="scY > 300" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
             stroke="#4a5568"
             stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6"/>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data(){
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
}
</script>
