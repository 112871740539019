export default {
    agent_is_disabled: "Агент вимкнено.",
    agent_disabled: "Жодна активна підписка/агент не вимкнена.",
    welcome_message: `
        <h3 class="text-info">Ласкаво просимо  в веб-панель <b>NMS</b>!</h3>
            <div>
              <h5 class="text-info">Оцініть можливості діагностики і моніторингу обладнання</h5>
              <ol>
                <li>Додайте свій перший доступ до обладнання(community/login/password)
                  <a href="/management/device-access">тут</a>
                </li>
                <li>Додайте свій перший пристрій
                  <a href="/management/device">тут</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Налаштуйте надсилання повідомлень до Telegram або на електронну пошту</h5>
              <ol>
                <li>Налаштуйте способи надсилання повідомлень -
                  <a href="/config/notifications">Налаштування алертів</a>
                </li>
                <li>Додайте свої контакти для отримання повідомлень -
                  <a href="/account/settings">Налаштування акаунта</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Надайте доступ до NMS колегам</h5>
              <ol>
                <li>Створіть свою групу користувачів,надамо необхідні їм доступи  -
                  <a href="/management/user-group">Групи</a>
                </li>
                <li>Додайте нових користувачів -
                  <a href="/management/user">Користувачі</a>
                </li>
              </ol>
            </div>   
    `,
    notifications: {
        contact_telegram_message: ` 
        <div style="margin-top: 10px">
        Дізнатись Telegram chat ID можна через бот - <a href="https://t.me/myidbot" target="_blank">@IDbot</a>. Бот поверне ваш ID, введіть його тут<br>
        <div style="text-align: center; font-weight: bold">АБО</div>
        <div  style="">
            <li>Додайте контакт "номер телефону для телеграм"</li>
            <li>Перейдіть до свого бота - <a href="{url}" target="_blank">@{name}</a> і відправте команду /start. Ваш чат буде додано автоматично</li>
        </div>
        </div>
        `,
    }

}