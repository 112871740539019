<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini">
        <Preloader v-if="loading"/>
        <div v-else>
          <div class="row">
            <div class="col-12  col-md-10 col-sm-10 col-lg-10 col-xl-10" v-if="data.length > 0"
                 style="margin-bottom: 10px">
              <div :class="'group card-block' " v-for="(dt, id) in data" :key="id" style="margin-bottom: 5px;">
                <div class="group-header ">
                  <div class="row">
                    <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 ">
                      <br>
                      <button class="btn btn-danger" style="margin-top: 3px" @click="deleteElement(id)"><i
                          class="mdi mdi-trash-can"></i></button>
                    </div>
                    <div class="col-10 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <label class="label-net">{{ $t('network_cidr') }}</label>
                      <input class="form-control" v-model="dt.cidr" placeholder="10.0.10.0/24">
                    </div>
                    <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <label class="label-net">{{ $t('device_access') }}</label>
                      <v-select class="style-chooser"
                                :close-on-select="true"
                                :clearable="false"
                                v-model="dt.device_access" label="name" :options="deviceAccesses"></v-select>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <label class="label-net">{{ $t('add_to_device_group') }}</label>
                      <v-select class="style-chooser"
                                :close-on-select="true"
                                :clearable="false"
                                v-model="dt.device_group" label="name" :options="deviceGroups"></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-12  col-md-10 col-sm-10 col-lg-10 col-xl-10"><br>
              <h3 style="text-align: center" v-html="$t('autodiscovery_networks_not_found')"></h3></div>
            <div class="col-12  col-md-2 col-sm-2 col-lg-2 col-xl-2">
              <button class="btn btn-info btn-block" @click="addNetwork" style="margin-bottom: 10px;"><i
                  class="mdi  mdi-plus"></i> {{ $t('add_network') }}
              </button>
              <button class="btn btn-info btn-block" @click="update" style="margin-bottom: 10px;"><i
                  class="mdi mdi-content-save"></i> {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import Preloader from "@/components/Preloader";

export default {
  components: {Preloader},
  data() {
    return {
      deviceAccesses: [],
      deviceGroups: [],
      data: [],
      loading: true,
    }
  },
  methods: {
    deleteElement(idDel) {
      if (!confirm(this.$t('are_you_sure_to_delete_element'))) {
        return false
      }
      console.log("delete element " + idDel)
      let elements = []
      this.data.forEach((e, id) => {
        if (idDel === id) {
          return
        }
        console.log("add element" + id)
        elements.push(e)
      })
      this.data = elements
    },
    addNetwork() {
      this.data.unshift({
        cidr: '',
        device_access: null,
        device_group: null,
      })
    },
    async update() {
      this.loading = true
      let resp = this.updateDiscoveryNetworks(this.data)
      if (resp) {
        await resp.then(async r => {
          this.data = r.data
          this.$noty.info(this.$t("networks_success_saved"))
        }).catch(e => {
        })
      }
      this.loading = false
    },
    async getDeviceAccesses() {
      let data = []
      await this.$api.get('/device-access').then(r => {
        r.data.forEach(e => {
          data.push(e)
        })
      })
      return data
    },
    async getDevicesGroups() {
      let data = []
      await this.$api.get('/device-group').then(r => {
        r.data.forEach(e => {
          data.push(e)
        })
      })
      return data
    },
    async getDiscoveryNetworks() {
      let data = []
      await this.$api.get('/component/autodiscovery/all').then(r => {
        r.data.forEach(e => {
          data.push(e)
        })
      })
      return data
    },
    updateDiscoveryNetworks(networks) {
      let nets = []
      let error = false
      networks.forEach(el => {
        if (el.cidr.trim() === '') {
          return
        }
        if (el.device_group === null) {
          error = true
          this.$noty.error(this.$t('autodiscovery_device_group_is_required', {network: el.cidr}))
        }
        if (el.device_access === null) {
          error = true
          this.$noty.error(this.$t('autodiscovery_device_access_is_required', {network: el.cidr}))
        }
        nets.push({
          cidr: el.cidr,
          device_access: el.device_access,
          device_group: el.device_group,
        })
      })
      if (error) {
        return null
      }
      return this.$api.put('/component/autodiscovery/all', nets).then(r => {
        return r
      }).catch(e => {})
    },
  },
  async mounted() {
    this.$setRouteMeta(this.$t('config_autodiscovery'))
    this.deviceAccesses = await this.getDeviceAccesses()
    this.deviceGroups = await this.getDevicesGroups()
    this.data = await this.getDiscoveryNetworks()
    this.loading = false
  }
}
</script>
<style scoped>
.label-net {
  margin-top: 5px;
  margin-bottom: 0;
  padding-bottom: 0;
  color: black;
}

.group {
  width: 100%;
  border: 1px solid #DADADA;
  background: rgba(250, 250, 250, 1);
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.group-header {
  border: 0 !important;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.group-body {
  background: rgba(0, 0, 0, 0);
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.group-green .group-header {
  background: rgba(5, 100, 0, 0.8);
  color: #FAFAFA;
}

.group-unknown .group-header {
  background: rgba(200, 200, 200, 0.8);
  color: #0F0F0F;
}

.group-yellow .group-header {
  background: rgba(192, 154, 0, 1);
  color: #FFF;
}

.group-red .group-header {
  background: rgba(130, 0, 0, 0.9);
  color: #FFF;
}


</style>