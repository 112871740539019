import Vue from 'vue';

let auth = Vue.prototype.$auth;
let conf = Vue.prototype.$config;

export default {
    pinger_stat: {
        component: () => import("./Widgets/Pinger.vue"),
        minWidth: 4,
        minHeight: 10,
        is_allowed: () => {
            return conf.isComponentEnabled('pinger')
        }
    },
    ont_statuses_pie: {
        component: () => import("./Widgets/OntStatusesPie.vue"),
        minWidth: 4,
        minHeight: 10,
        is_allowed: () => {
            return conf.isComponentEnabled('olts')
        }
    },
    events_stat: {
        component: () => import("./Widgets/EventsCountStat.vue"),
        minWidth: 2,
        minHeight: 10,
        is_allowed: () => {
            return conf.isComponentEnabled('events') && auth.isPermitted('events_show')
        }
    },
    events_stat_by_name: {
        component: () => import("./Widgets/EventsByNameStat.vue"),
        minWidth: 3,
        minHeight: 10,
        is_allowed: () => {
            return conf.isComponentEnabled('events') && auth.isPermitted('events_show')
        }
    },
    last_user_activity: {
        component: () => import("./Widgets/LastUserActivity.vue"),
        minWidth: 8,
        minHeight: 12,
        is_allowed: () => {
            return auth.isPermitted('system_logs_actions')
        }
    },
    events_table: {
        component: () => import("./Widgets/EventsTable.vue"),
        minWidth: 8,
        minHeight: 12,
        is_allowed: () => {
            return conf.isComponentEnabled('events') && auth.isPermitted('events_show')
        }
    },
    unregistered_onts: {
        component: () => import("./Widgets/UnregisteredOnts.vue"),
        minWidth: 8,
        minHeight: 12,
        is_allowed: () => {
            return  auth.isPermitted('unregistered_onts')
        }
    },
    system_stat: {
        component: () => import("./Widgets/SystemStat.vue"),
        minWidth: 2,
        minHeight: 8,
        is_allowed: () => {
            return  auth.isPermitted('system_status')
        }
    },
    device_calling_errors_stat: {
        component: () => import("./Widgets/DeviceCallingErrorsStat.vue"),
        minWidth: 6,
        minHeight: 10,
        is_allowed: () => {
            return  auth.isPermitted('log_switcher_core_actions')
        }
    },
    device_status_chart: {
        component: () => import("./Widgets/DeviceStatusChart.vue"),
        minWidth: 8,
        minHeight: 12,
        is_allowed: () => {
            return  auth.isPermitted('analytics')
        }
    },
    online_onts_chart: {
        component: () => import("./Widgets/OnlineOntsChart.vue"),
        minWidth: 8,
        minHeight: 12,
        is_allowed: () => {
            return  auth.isPermitted('analytics')
        }
    },
    ont_signal_bar: {
        component: () => import("./Widgets/OntSignalBar.vue"),
        minWidth: 8,
        minHeight: 10,
        is_allowed: () => {
            return  auth.isPermitted('analytics')
        }
    },
    // up_ifaces_chart: {
    //     component: () => import("./Widgets/UpInterfacesChart.vue"),
    //     minWidth: 8,
    //     minHeight: 12,
    //     is_allowed: () => {
    //         return  auth.isPermitted('analytics_iface_status_history')
    //     }
    // },

}