import {defineStore} from "pinia";
import Vue from "vue";

let randomId = function (length = 6) {
    return Math.random().toString(36).substring(2, length + 2);
};
export default defineStore(randomId(16), {
    state: () => ({
        _api: Vue.prototype.$api,
        _config: Vue.prototype.$config,
        _auth: Vue.prototype.$auth,
        device: null,
        system: null,
        interfaces: [],
        deviceIsOffline: false,
        pollers: [],
        loadFrom: 'cache',
    }),
    getters: {
        isLoading: (state) => state.loading && (!state.device || !state.interfaces),
        isHaveActivePollers: (state) => state.pollers.filter(poller => {
            return !poller.stop_at
        }).length > 0,
    },
    actions: {
        setPollers(pollers) {
            this.pollers = pollers
        },
        updateStoredDevice(device) {
            this.device = device
        },
        async reloadStoredDevice() {
            await this._api.get('/device/' + this.device.id).then(r => {
                this.device = r.data
            })
        },
        getStoredDevice() {
            return this.device
        },
        getInterfaceByBindKey(bindKey) {
            let ifaces = this.interfaces.filter(iface => {
                return iface.bind_key == bindKey
            })
            if (ifaces.length >= 1) {
                return ifaces[0]
            } else {
                return null
            }
        },
        getInterfacesByParentBindKey(bindKey) {
            let ifaces = this.interfaces.filter(iface => {
                return iface.parent_bind_key == bindKey
            })
            return ifaces
        },
        async updateInterface(ifaceId, params = {}) {
            return await this._api.put(`/device-interface/${ifaceId}`, params).then(r => {
                this.interfaces.every((iface, id) => {
                    if (ifaceId == iface.id) {
                        Vue.set(this.interfaces, id, r.data)
                        return false
                    }
                    return true
                })
            })
        },
        isModuleSupported(moduleName) {
            return this.system && this.system.meta.modules.indexOf(moduleName) !== -1
        },
        async setDeviceByID(deviceID) {
            await this._api.get('/device/' + deviceID).then(r => {
                this.device = r.data
            })
            await this.reloadInfo()
            return this
        },
        async reloadInfo() {
            this.deviceIsOffline = false
            this.system = null
            this._api.enableSupportWaiting()
            if (
                this._config.isComponentEnabled('links') &&
                this._auth.isPermitted('links_view')
            ) {
                this.loadLinks()
            }
            if(this.device.model.type === 'UNKNOWN') {
                return
            }
            this.loadInterfacesByDevice()
            this._api.get('/switcher-core/device/system/' + this.device.id, {}, true).then(r => {
                this.system = r.data
            }).catch(async e => {
                this.deviceIsOffline = true
                await this._api.get('/switcher-core/store/system/' + this.device.id, {}, true).then(r => {
                    this.system = r.data
                }).catch(e => {
                })
            })
            await this._api.waitResponses().catch(e => {

            })
        },
        async loadLinks() {
            await this._api.get(`/component/links/by-device/${this.device.id}`).then(r => {
                this.links = r.data
            })
            return this
        },
        async loadInterfacesByDevice() {
            await this._api.get(`/device-interface/by-device/${this.device.id}`).then(r => {
                this.interfaces = r.data
            })
            return this
        }
    },
})