import Noty from 'noty';
import 'noty/lib/noty.css'
import 'noty/lib/themes/metroui.css'
import 'noty/lib/themes/mint.css'
import 'noty/lib/themes/bootstrap-v4.css'
import 'noty/lib/themes/relax.css'
import 'noty/lib/themes/sunset.css'
import 'noty/lib/themes/nest.css'
import 'noty/lib/themes/light.css'
import 'noty/lib/themes/semanticui.css'
import 'noty/lib/themes/bootstrap-v3.css'

export default class VueNoty {
    _props = {
        theme: 'metroui',
        timeout: 5000,
        layout: 'topRight',
    }
    constructor() {
        Noty.overrideDefaults(this._props)
    }
    setDefaults(props) {
        Noty.overrideDefaults(props);
    }

    updateLayout(layout) {
       this._props.layout  = layout
        console.log(this._props)
       Noty.overrideDefaults(this._props)
    }

    success(message, props = null) {
        return this._noty('success', message, props)
    }
    error(message, props = null) {
        return this._noty('error', message, props)
    }
    warning(message, props = null) {
        return this._noty('warning', message, props)
    }
    info(message, props = null) {
        return this._noty('info', message, props)
    }
    _noty(type, message, props) {
        console.log(message)
        if(props === null) {
            props = {}
        }
        props.text = message
        props.type = type
        let noty = new Noty(props)
        noty.show()
        return  noty;
    }
}